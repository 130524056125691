import { Client, DeleteConfig, GetConfig, PostConfig, UPLOAD_TIMEOUT } from 'client'
import { parseDate } from 'utils/date'
import { ListQuery } from 'utils/list'
import { UserFile } from '../user-file'

const PROCESSING_TIMEOUT = 1000 * 60 // 1 minute

export interface UserDocument extends Omit<UserFile, 'file_id'> {
  document_id: string
  ai_type_override?: UserDocument.AiTypeOverride
  ai_type?: UserDocument.AiType
  processing_ended_at?: string
  processing_started_at?: string
  type?: UserDocument.Type
}
export namespace UserDocument {
  export type IdField = 'document_id'
  export type Id = Pick<UserDocument, IdField>

  export type Sort = 'created_at' | 'filename' | 'mimetype' | 'size'
  export type Query = ListQuery<
    Sort,
    {
      document_id?: string[]
      filename?: string
      type?: Type[]
      user_id?: string[]
    }
  >
  export type Filter = Query['filter']

  export enum Type {
    Asset = 'asset',
    Voucher = 'voucher',
    Chat = 'chat',
  }
  export function isType(type?: string): type is Type {
    return !!type && [Type.Asset, Type.Voucher, Type.Chat].includes(type as Type)
  }

  export const enum AiType {
    PAYSTUB = 'paystub',
    BANK_STATEMENT = 'bank-statement',
    OTHER = 'other',
  }
  export type AiTypeOverride = AiType | '' | undefined
  export const AI_TYPE = {
    [AiType.PAYSTUB]: 'Pay Stub',
    [AiType.BANK_STATEMENT]: 'Bank Statement',
    [AiType.OTHER]: 'Other',
  } as const

  export const isOverridden = (doc?: UserDocument) => !!doc?.ai_type_override
  export const getAiType = (doc?: UserDocument) =>
    isOverridden(doc) ? doc?.ai_type_override : doc?.ai_type

  export const getAiTypeLabel = (doc?: UserDocument) => {
    const type = getAiType(doc)
    return type && Object.hasOwn(AI_TYPE, type)
      ? AI_TYPE[type as keyof typeof AI_TYPE] ?? undefined
      : undefined
  }
  export const isPayStub = (doc: UserDocument) => getAiType(doc) === AiType.PAYSTUB
  export const isBankStatement = (doc: UserDocument) => getAiType(doc) === AiType.BANK_STATEMENT

  export const isProcessing = (document: UserDocument) => {
    if (!document.processing_started_at || document.processing_ended_at) return false
    const start = parseDate(document.processing_started_at)
    if (!start) return false
    return Date.now() - start.getTime() < PROCESSING_TIMEOUT
  }
}

export class DocumentBackend extends Client {
  byId = async (id: string, config?: PostConfig): Promise<UserDocument | null> => {
    const { user_documents } = await this.post<
      UserDocument.Query,
      { status: 'success'; user_documents: UserDocument[] }
    >('/user/document/get', { filter: { document_id: [id] } }, config)
    return user_documents?.[0] ?? null
  }

  list = async (query?: UserDocument.Query, config?: PostConfig): Promise<UserDocument[]> => {
    const { user_documents } = await this.post<
      UserDocument.Query,
      { status: 'success'; user_documents: UserDocument[] }
    >('/user/document/get', query ?? {}, config)
    return user_documents
  }

  count = async ({ filter }: UserDocument.Query, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<UserDocument.Query, { status: 'success'; count: number }>(
      '/user/document/count',
      { filter },
      config,
    )
    return count
  }

  downloadBlobById = async (docid: string, config?: GetConfig): Promise<Blob> => {
    const blob = await this.get<Blob, { docid: string }>(
      '/user/document/download',
      { docid },
      { ...config, responseType: 'blob' },
    )
    if (blob.size === 0) throw new Error('Empty blob')
    return blob
  }

  downloadAsURLById = async (id: string, config?: GetConfig): Promise<string> => {
    const blob = await this.downloadBlobById(id, config)
    return URL.createObjectURL(blob)
  }

  /**
   * @returns document_id
   * @see https://api-dev.rello.co/swagger/index.html#/file/post_user_document_upload
   */
  upload = async (data: FormData, config?: PostConfig): Promise<string> => {
    const { document_id } = await this.post<FormData, { document_id: string; status: string }>(
      '/user/document/upload',
      data,
      {
        timeout: UPLOAD_TIMEOUT,
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return document_id
  }

  remove = async (id: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/user/document/delete', {
      ...config,
      params: { ...config?.params, docid: id },
    })
  }
}

export const document = new DocumentBackend()
