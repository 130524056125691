import { Client, DeleteConfig, GetConfig, PostConfig, UPLOAD_TIMEOUT } from 'client'
import { getFullName } from 'utils/full-name'
import { User } from '../user'

export namespace UserSignature {
  export const enum Type {
    SIGNATURE = 'signature',
    INITIALS = 'initials',
    WET = 'signature-wet',
  }
  export type PersistentType = Type.SIGNATURE | Type.INITIALS

  export const TYPE = {
    [Type.SIGNATURE]: 'Signature',
    [Type.INITIALS]: 'Initials',
    [Type.WET]: 'Wet Signature',
  } as const
  export const TYPE_SHORT = {
    [Type.SIGNATURE]: 'Sign',
    [Type.INITIALS]: 'Initials',
    [Type.WET]: 'Wet Sign',
  } as const
  export const PERSISTENT_TYPES = [Type.SIGNATURE, Type.INITIALS] as const
  export const TYPES = [...PERSISTENT_TYPES, Type.WET] as const

  export const OPTIONS = TYPES.map((type) => ({ value: type, label: TYPE[type] }))
  export const PERSISTENT_OPTIONS = PERSISTENT_TYPES.map((type) => ({
    value: type,
    label: TYPE[type],
  }))

  export const MSG = {
    ERR: {
      INVALID_TYPE: 'Invalid type.',
      EMPTY: 'Add a signature before uploading.',
      NO_FILE_SIGNATURE: 'Signature file not found.',
      NO_FILE_INITIALS: 'Initials file not fould.',
    },
    ACTION: {
      UPLOAD: 'Upload',
      ACCEPT: 'Accept',
      UPLOAD_SUCCESS: 'Signature uploaded successfully.',
    },
    NONE: {
      [Type.SIGNATURE]: 'No signature uploaded.',
      [Type.INITIALS]: 'No initials uploaded.',
    },
  } as const

  export function isValidType(type?: string): type is Type {
    return TYPES.includes(type as Type)
  }
  export function isValidPersistentType(type?: string): type is Type.INITIALS | Type.SIGNATURE {
    return PERSISTENT_TYPES.includes(type as PersistentType)
  }

  export const getDefaultValue = (type: PersistentType, user: User): string => {
    switch (type) {
      case Type.SIGNATURE:
        return getFullName(user) ?? ''
      case Type.INITIALS:
        return [user.first_name?.at(0)?.toUpperCase(), user.last_name?.at(0)?.toUpperCase()]
          .filter(Boolean)
          .map((x) => `${x}.`)
          .join('')
      default:
        throw new Error(MSG.ERR.INVALID_TYPE)
    }
  }
}

export class UserSignatureBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/signature/post_signature_upload_initials
   * @see https://api-dev.rello.co/swagger/index.html#/signature/post_signature_upload_signature
   * @see https://api-dev.rello.co/swagger/index.html#/signature/post_signature_upload_signature_wet
   */
  upload = async (
    { type, file }: { type: UserSignature.Type; file: Blob },
    config?: PostConfig,
  ): Promise<{ snapshot_id: string }> => {
    const data = new FormData()
    data.append(type, file)

    const { snapshot_id } = await this.post<FormData, { status: string; snapshot_id: string }>(
      `/signature/upload/${type}`,
      data,
      {
        timeout: UPLOAD_TIMEOUT,
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return { snapshot_id }
  }

  getSignaturesSafe = async (
    config?: GetConfig,
  ): Promise<Record<UserSignature.PersistentType, Blob | null>> => {
    try {
      const { initials, signature } = await this.getSignatureIds(config)
      return {
        [UserSignature.Type.SIGNATURE]: signature
          ? await this.getSignature(config).catch(() => null)
          : null,
        [UserSignature.Type.INITIALS]: initials
          ? await this.getInitials(config).catch(() => null)
          : null,
      }
    } catch (e) {
      return {
        [UserSignature.Type.SIGNATURE]: null,
        [UserSignature.Type.INITIALS]: null,
      }
    }
  }

  getSignatureBlobs = async (
    config?: GetConfig,
  ): Promise<Record<UserSignature.PersistentType, { blob: Blob; snapshot_id: string }>> => {
    const [signatureIds, signatureBlobs] = await Promise.all([
      this.getSignatureIds(config),
      this.getSignaturesSafe(config),
    ])
    return Object.fromEntries(
      UserSignature.PERSISTENT_TYPES.map((type) => {
        const blob = signatureBlobs[type]
        const snapshot_id = signatureIds[type]
        return [type, { blob, snapshot_id }] as const
      }),
    ) as Record<UserSignature.PersistentType, { blob: Blob; snapshot_id: string }>
  }

  byTypeSafe = async (
    type: UserSignature.PersistentType,
    config?: GetConfig,
  ): Promise<Blob | null> => {
    const signatures = await this.getSignatureIds(config)
    switch (type) {
      case UserSignature.Type.SIGNATURE:
        return signatures.signature ? this.getSignature(config) : null
      case UserSignature.Type.INITIALS:
        return signatures.initials ? this.getInitials(config) : null
      default:
        throw new Error(UserSignature.MSG.ERR.INVALID_TYPE)
    }
  }

  byType = async (type: UserSignature.PersistentType, config?: GetConfig): Promise<Blob> => {
    switch (type) {
      case UserSignature.Type.SIGNATURE:
        return this.getSignature(config)
      case UserSignature.Type.INITIALS:
        return this.getInitials(config)
      default:
        throw new Error(UserSignature.MSG.ERR.INVALID_TYPE)
    }
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/signature/get_signature_download_signature */
  private getSignature = async (config?: GetConfig): Promise<Blob> => {
    const blob = await this.get<Blob>('/signature/download/signature', undefined, {
      ...config,
      responseType: 'blob',
    })
    return blob
  }
  /** @see https://api-dev.rello.co/swagger/index.html#/signature/get_signature_download_initials */
  private getInitials = async (config?: GetConfig): Promise<Blob> => {
    const blob = await this.get<Blob>('/signature/download/initials', undefined, {
      ...config,
      responseType: 'blob',
    })
    return blob
  }
  /** @see https://api-dev.rello.co/swagger/index.html#/signature/get_signature_download_initials */
  getSignatureIds = async (
    config?: GetConfig,
  ): Promise<Record<UserSignature.PersistentType, string | null>> => {
    type Res = {
      initials_snapshot_id: string
      signature_snapshot_id: string
    }
    const data = await this.get<Res>('/signature/ready', undefined, config)
    return {
      [UserSignature.Type.INITIALS]: data.initials_snapshot_id || null,
      [UserSignature.Type.SIGNATURE]: data.signature_snapshot_id || null,
    }
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/signature/delete_signature_delete */
  clearAll = async (config?: DeleteConfig): Promise<void> => {
    await this.delete('/signature/delete', config)
  }
}

export const userSignature = new UserSignatureBackend()
