import { Token } from 'api/template'
import { createContext, FC, ReactNode, useContext, useMemo } from 'react'
import { useFormValues } from 'ui'

const ScopeContext = createContext<Record<string, any>>({})

interface Props {
  fields: Token[]
  vars?: Record<string, any>
  children: ReactNode
}

export const TemplateScope: FC<Props> = ({ fields, vars, children }) => {
  const formValuesTransformer = useMemo(() => {
    const namedEntries = fields.reduce(
      (mapIdName, field) =>
        field.name ? [...mapIdName, [field.name, field.token_id] as [string, string]] : mapIdName,
      [] as [string, string][],
    )
    return (values: Record<string, any>) => {
      return namedEntries.reduce((result, [name, id]) => {
        if (id in values) result[name] = values[id]
        return result
      }, (vars as Record<string, any>) ?? {})
    }
  }, [fields, vars])

  const values = formValuesTransformer(useFormValues())

  return (
    <ScopeContext.Provider
      value={{
        ...vars,
        ...values,
      }}
    >
      {children}
    </ScopeContext.Provider>
  )
}

export const useTemplateScope = () => {
  return useContext(ScopeContext)
}
