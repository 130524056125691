import { Client, PostConfig } from 'client'
import { ListQuery, Order, parseOrder, parsePagination } from 'utils/list'
import { Application } from './application'
import { Auction } from './auction'
import { AdminAuction } from './auction.admin'
import { Lease } from './lease'
import { AdminLease } from './lease.admin'
import { Owner } from './owner.admin'
import { Property } from './property'
import { AdminProperty } from './property.admin'
import { Unit } from './unit'
import { AdminUnit } from './unit.admin'
import { Template } from '../template/template.admin'
import { User } from '../user/user'
import { AdminUser, adminUser } from '../user/user.admin'

export namespace AuditLog {
  export const Singular = 'Audit Log'
  export type Sort = 'created_at' | 'entity_type' | 'operation'
  export type Query = ListQuery<
    Sort,
    {
      application_id?: string[]
      auction_id?: string[]
      entity_type?: string[]
      global?: string
      lease_id?: string[]
      operation?: string[]
      owner_id?: string[]
      property_id?: string[]
      template_id?: string[]
      transaction_id?: string[]
      unit_id?: string[]
      user_id?: string[]
    }
  > & {
    filter: {
      created_at?: {
        from?: string
        to?: string
      }
    }
  }
  export type Filter = Query['filter']

  export const enum EntityType {
    UNIT = 'unit',
    OWNER = 'owner',
    PROPERTY = 'property',
    TEMPLATE = 'template',
    AUCTION = 'auction',
    LEASE = 'lease',
    APPLICATION = 'application',
    USER = 'user',
  }

  type DataType = {
    [EntityType.UNIT]: AdminUnit.Update & Unit.Id
    [EntityType.OWNER]: Owner.Data & Owner.Id
    [EntityType.PROPERTY]: AdminProperty.Create & Property.Id
    [EntityType.TEMPLATE]: Template.Create & Template.Id
    [EntityType.AUCTION]: AdminAuction.Update & Auction.Id & Unit.Id
    [EntityType.LEASE]: AdminLease.CreateFromDraft & Lease.Id
    [EntityType.APPLICATION]: Application.Data & Application.Id
    [EntityType.USER]: AdminUser.Update & User.Id
  }

  export type Data = DataType[EntityType]

  export const enum Operation {
    NEW = 'new',
    UPDATE = 'update',
  }

  type AuditItem<T extends EntityType> = {
    created_at: string
    data: Partial<DataType[T]>
    operation: string
    transaction_id: string
    executed_by_id: string
  } & { entity_type: T }

  export type ItemUnit = AuditItem<EntityType.UNIT>
  export const isUnit = (item: AuditItem<any>): item is ItemUnit =>
    item.entity_type === EntityType.UNIT
  export type ItemUser = AuditItem<EntityType.USER>
  export const isUser = (item: AuditItem<any>): item is ItemUser =>
    item.entity_type === EntityType.USER
  export type ItemOwner = AuditItem<EntityType.OWNER>
  export const isOwner = (item: AuditItem<any>): item is ItemOwner =>
    item.entity_type === EntityType.OWNER
  export type ItemProperty = AuditItem<EntityType.PROPERTY>
  export const isProperty = (item: AuditItem<any>): item is ItemProperty =>
    item.entity_type === EntityType.PROPERTY
  export type ItemTemplate = AuditItem<EntityType.TEMPLATE>
  export const isTemplate = (item: AuditItem<any>): item is ItemTemplate =>
    item.entity_type === EntityType.TEMPLATE
  export type ItemAuction = AuditItem<EntityType.AUCTION>
  export const isAuction = (item: AuditItem<any>): item is ItemAuction =>
    item.entity_type === EntityType.AUCTION
  export type ItemLease = AuditItem<EntityType.LEASE>
  export const isLease = (item: AuditItem<any>): item is ItemLease =>
    item.entity_type === EntityType.LEASE
  export type ItemApplication = AuditItem<EntityType.APPLICATION>
  export const isApplication = (item: AuditItem<any>): item is ItemApplication =>
    item.entity_type === EntityType.APPLICATION

  export type Item =
    | ItemUnit
    | ItemOwner
    | ItemProperty
    | ItemTemplate
    | ItemAuction
    | ItemLease
    | ItemApplication

  export type ItemWithUser = Item & {
    user: AdminUser
  }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Filter,
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Filter = {}
    const global = searchParams.get('global') as string
    if (global) filter.global = global
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  const DIFF_EXCLUDE: { [Key in EntityType]?: (keyof DataType[Key])[] } = {
    [EntityType.UNIT]: ['unit_id'],
    [EntityType.AUCTION]: ['unit_id', 'auction_id'],
    [EntityType.USER]: ['user_id'],
  }

  export const getData = <T extends EntityType>({
    data,
    entity_type,
  }: AuditItem<T>): Partial<DataType[T]> => {
    const exclude = DIFF_EXCLUDE[entity_type]
    return filterEntries(data, (key, value) => !exclude?.includes(key) && value !== null)
  }

  export const prepare = <T extends EntityType>(item: AuditItem<T>) => {
    if (isUnit(item)) return AdminUnit.getFriendlyUpdate(getData(item))
    return getData(item)
  }

  const filterEntries = <T extends Record<any, any>>(
    object: T,
    filter: (key: keyof T, value: T[keyof T]) => boolean,
  ): Partial<T> => {
    return Object.fromEntries(
      Object.entries(object).filter(([key, value]) => filter(key as keyof T, value)),
    ) as T
  }
}

export class AuditLogBackend extends Client {
  list = async (query?: AuditLog.Query, config?: PostConfig): Promise<AuditLog.Item[]> => {
    const { users } = await this.post<AuditLog.Query, { status: string; users: AuditLog.Item[] }>(
      '/admin/audit/get',
      query,
      config,
    )
    return users
  }

  listWithUsers = async (
    query?: AuditLog.Query,
    config?: PostConfig,
  ): Promise<AuditLog.ItemWithUser[]> => {
    const items = await this.list(query, config)
    const user_id = items.map((item) => item.executed_by_id)
    const users = await adminUser.list({ filter: { user_id } }, config)
    return items.map((item) => ({ ...item, user: users.find(User.byId(item.executed_by_id))! }))
  }

  count = async (query?: AuditLog.Query, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<AuditLog.Query, { status: string; count: number }>(
      '/admin/audit/count',
      query,
      config,
    )
    return count
  }
}

export const auditLog = new AuditLogBackend()
