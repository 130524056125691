import { PostConfig } from 'client'

export const PAGE_SIZE = 15

export const enum ListURLParam {
  order = 'order',
  sort = 'sort',
  page = 'page',
  size = 'size',
}

export const enum Order {
  desc = 'desc',
  asc = 'asc',
}

export type ListFilters<F extends string = string> = Partial<
  Record<F, string | string[] | boolean | number>
> & {
  global?: string
}

export type ListPagination = {
  page: number
  page_size: number
}

export type ListSorting<Sorting extends string> = {
  data?: string
  desc?: true
  name: Sorting
}[]

export interface ListQuery<
  Sorting extends string,
  Filter extends ListFilters<Sorting> &
    Record<string, string | string[] | boolean | number | any> = ListFilters<Sorting>,
  Selector extends string = never,
> {
  filter?: ListFilters<Sorting> & Filter
  order?: ListSorting<Sorting>
  pagination?: ListPagination
  selector?: Selector
}

type PickSorting<T> = T extends ListQuery<infer Sorting> ? Sorting : string

export interface ListParams<Sorting extends string = string> {
  order: Order | null
  sort: Sorting | null
  page?: number
  size?: number
}

export function buildListQuery<Query extends ListQuery<any, any, any>>(
  { order, sort, page, size }: Required<ListParams<PickSorting<Query>>>,
  filters?: ListQuery<PickSorting<Query>, any, any>['filter'],
): Query {
  const result = {
    pagination: {
      page,
      page_size: size ?? PAGE_SIZE,
    },
  } as Query
  if (filters) {
    result.filter = filters
  }
  if (sort) {
    result.order = [
      {
        name: sort,
        ...(order === Order.desc && { desc: true }),
      },
    ]
  }
  return result
}

export function parseStringArraySearchParam(
  searchParams: URLSearchParams,
  name: string,
): string[] | undefined {
  const value = searchParams.get(name)
  const values = value ? value.split(',') : undefined
  return values?.length ? values : undefined
}
export function parseStringSearchParam(
  searchParams: URLSearchParams,
  name: string,
): string | undefined {
  const value = searchParams.get(name)
  return value ? value : undefined
}

export function parseListURLParams<Sorting extends string = string>(
  query: URLSearchParams,
  defaultParams: ListParams<Sorting>,
): Required<ListParams<Sorting>> {
  const _order = query.get(ListURLParam.order) as Order | null
  const _sort = query.get(ListURLParam.sort) as Sorting | null
  const _page = query.get(ListURLParam.page)
  const _size = query.get(ListURLParam.size)
  const page = (_page && parseInt(_page, 10)) || defaultParams.page || 1
  const size = (_size && parseInt(_size, 10)) || defaultParams.size || PAGE_SIZE
  return {
    page,
    size,
    ...defaultParams,
    ...(_order && { order: _order }),
    ...(_sort && { sort: _sort }),
  }
}

export function parsePagination(
  query: URLSearchParams,
  defaultParams?: Partial<ListPagination>,
): ListPagination {
  const _page = query.get(ListURLParam.page)
  const _size = query.get(ListURLParam.size)
  const page = (_page && parseInt(_page, 10)) || defaultParams?.page || 1
  const page_size = (_size && parseInt(_size, 10)) || defaultParams?.page_size || PAGE_SIZE
  return {
    page,
    page_size,
  }
}

export function parseOrder<Sorting extends string = string>(
  query: URLSearchParams,
  defaultParams?: { order?: Order; sort?: Sorting },
): ListSorting<Sorting> | undefined {
  const sort = (query.get(ListURLParam.sort) as Sorting | null) ?? defaultParams?.sort ?? null
  const order = (query.get(ListURLParam.order) as Order | null) ?? defaultParams?.order ?? null
  return sort
    ? [
        {
          name: sort,
          ...(order === Order.desc && { desc: true }),
        },
      ]
    : undefined
}

export function validatePagination(
  count: number,
  _url: string | URL,
  redirect: (url: string) => void,
) {
  const url = _url instanceof URL ? _url : new URL(_url)
  const _page = url.searchParams.get(ListURLParam.page)
  if (!_page) return
  const page = parseInt(_page, 10)
  const _size = url.searchParams.get(ListURLParam.size)
  const size = (_size && parseInt(_size, 10)) || PAGE_SIZE
  const pages = Math.ceil(count / size)
  if (page > pages) {
    url.searchParams.set(ListURLParam.page, pages.toString())
    throw redirect(url.pathname + '?' + url.searchParams.toString())
  }
}

export const getTotalPages = (count: number, page_size = PAGE_SIZE) => Math.ceil(count / page_size)

export function getSort<Sorting extends string = string, Filter extends ListFilters<Sorting> = {}>(
  query: ListQuery<Sorting, Filter, any>,
) {
  if (!query.order?.[0]) return null
  return query.order[0].name ?? null
}
export function getOrder<Sorting extends string = string, Filter extends ListFilters<Sorting> = {}>(
  query: ListQuery<Sorting, Filter, any>,
) {
  if (!query.order?.[0]) return null
  return query.order[0].desc ? Order.desc : Order.asc
}

export function createPaginatedList<
  Item extends any,
  Sorting extends string = any,
  Filter extends ListFilters<Sorting> = ListFilters<Sorting>,
  Selector extends string = never,
>(
  listFn: (query: ListQuery<Sorting, Filter, Selector>, config?: PostConfig) => Promise<Item[]>,
  countFn: (query: ListQuery<Sorting, Filter, Selector>, config?: PostConfig) => Promise<number>,
) {
  type Query = Parameters<typeof listFn>[0]

  return async function* (
    query: Query,
    config?: PostConfig,
  ): AsyncGenerator<{ items: Item[]; count: number; page: number }> {
    const { filter, order, selector, pagination } = query
    const page_size = pagination?.page_size ?? 5

    const [items, count] = await Promise.all([
      listFn({ filter, order, selector, pagination: { page: 1, page_size } }, config),
      countFn({ filter, selector }, config),
    ])

    yield { items, count, page: 1 }

    const pages = getTotalPages(count, page_size)
    if (!pages) return
    for (let page = 2; page <= pages; page++) {
      yield {
        items: await listFn({ filter, order, selector, pagination: { page, page_size } }, config),
        count,
        page,
      }
    }
  }
}
