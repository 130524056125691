import { CSSProperties } from 'react'

export function getPageItemStyle(
  item: {
    x: number
    y: number
    width: number
    height: number
  },
  zoom = 1,
) {
  return {
    '--zoom': zoom,
    left: item.x * zoom,
    top: item.y * zoom,
    height: item.height * zoom,
    width: item.width * zoom,
  } as CSSProperties
}
