import { Client, GetConfig, PostConfig } from 'client'
import { not, pickAll } from 'utils/compose'
import { equalEmails } from 'utils/email'
import { ListQuery } from 'utils/list'
import { user } from './current-user.hello'
import { Guarantee } from './guarantee'
import { User } from '../user/user'

export interface Cosigner {
  accepted_at?: string | null
  declined_at?: string | null
  created_at: string
  cosigners_id: string
  invited_by: null | Pick<User, 'first_name' | 'last_name' | 'email'>
  invited_at: string | null
  user: Pick<User, 'first_name' | 'last_name' | 'email'>
  user_id: string
}

export namespace Cosigner {
  export type Id = Pick<Cosigner, 'cosigners_id'>
  export type Sort = 'cosigners_id' | 'user_id'
  export type Query = ListQuery<Sort, { user_id?: string[]; cosigners_id?: string[] }>

  export const MSG = {
    ERR: {
      NOT_FOUND: 'Cosigner not found.',
      NO_IDS: 'Missing cosigners_id.',
      REF_SELF: 'You cannot add yourself as a cosigner.',
      REF_GUARANTOR: 'This user is already a guarantor.',
      REF_COSIGNER: 'This user is already added as a cosigner.',
    },
  } as const

  export interface Create {
    cosigner_email: string
  }
  export interface Delete {
    cosigners_id: string
    user_id: string
  }

  export interface WithStatus extends Cosigner {
    status: Status
  }

  export const enum Status {
    Declined = 'Declined',
    Confirmed = 'Confirmed',
    Pending = 'Pending',
  }

  export const isAccepted = (cosigner: Cosigner) => !!cosigner.accepted_at
  export const isDeclined = (cosigner: Cosigner) => !!cosigner.declined_at
  export const isPending = (cosigner: Cosigner) => !isAccepted(cosigner) && !isDeclined(cosigner)
  export const getStatus = (cosigner: Cosigner): Status =>
    isAccepted(cosigner)
      ? Status.Confirmed
      : isDeclined(cosigner)
      ? Status.Declined
      : Status.Pending

  export const statusToLabel = (status: Status) =>
    ({
      [Status.Confirmed]: 'Verified',
      [Status.Declined]: 'Declined',
      [Status.Pending]: 'Pending',
    }[status])

  export const userHasCosigners = (cosigners: Cosigner[] | undefined, { user_id }: User.Id) =>
    !!cosigners && cosigners.filter(not(User.byId(user_id))).some(not(isDeclined))

  export const isUserActionRequired = (cosigners: Cosigner[] | undefined, { user_id }: User.Id) =>
    !!cosigners && cosigners.filter(User.byId(user_id)).some(isPending)

  export const createNewCosignerValidator =
    ({
      user,
      guarantors,
      cosigners,
    }: {
      user: User
      cosigners?: Cosigner[]
      guarantors?: Guarantee.Request[]
    }) =>
    (email: string | null) => {
      if (equalEmails(email, user.email)) return MSG.ERR.REF_SELF
      if (guarantors?.some((guarantor) => equalEmails(email, guarantor?.guarantor?.email)))
        return MSG.ERR.REF_GUARANTOR
      if (cosigners?.some((cosigner) => equalEmails(cosigner.user.email, email)))
        return MSG.ERR.REF_COSIGNER
      return null
    }
}

export class CosignersBackend extends Client {
  create = async (data: Cosigner.Create, config?: PostConfig): Promise<void> => {
    await this.post<Cosigner.Create, { cosigners_id: 'string'; status: string }>(
      '/cosigner/new',
      data,
      config,
    )
    // update user (user.cosigner has been updated)
    await user.fetchUser()
  }

  accept = async (data: Cosigner.Id, config?: PostConfig): Promise<void> => {
    if (!data.cosigners_id) throw new Error(Cosigner.MSG.ERR.NO_IDS)
    await this.post<Cosigner.Id, { status: string }>('/cosigner/accept', data, config)
    // update user (user.cosigner has been updated)
    await user.fetchUser()
  }

  decline = async (data: Cosigner.Id, config?: PostConfig): Promise<void> => {
    if (!data.cosigners_id) throw new Error(Cosigner.MSG.ERR.NO_IDS)
    await this.post<Cosigner.Id, { status: string }>('/cosigner/decline', data, config)
    // update user (user.cosigner has been updated)
    await user.fetchUser()
  }

  list = async (query: Cosigner.Query = {}, config?: PostConfig): Promise<Cosigner[]> => {
    type Result = { cosigners: Cosigner[]; status: string }
    const { cosigners } = await this.post<Cosigner.Query, Result>(
      '/cosigner/get',
      query ?? null,
      config,
    )
    return cosigners
  }

  getMyCosigners = async (config?: GetConfig): Promise<Cosigner[]> => {
    type Result = { cosigners: Cosigner[] }
    const { cosigners } = await this.get<Result>('/cosigner/get/mine', undefined, config)
    return cosigners
  }

  getMyCosignerIds = async (config?: GetConfig): Promise<string[]> => {
    const cosigners = await this.getMyCosigners(config)
    return pickAll('cosigners_id', cosigners)
  }

  count = async (query: Cosigner.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Cosigner.Query, { count: number; status: 'success' }>(
      '/cosigner/count',
      query ?? null,
      config,
    )
    return count
  }

  removeCosigner = async (data: Cosigner.Delete, config?: PostConfig): Promise<void> => {
    await this.post<Cosigner.Delete, { status: string }>('/cosigner/delete', data, config)
    // update user (user.cosigner has been updated)
    await user.fetchUser()
  }

  byId = async (id: string, config?: GetConfig): Promise<Cosigner[]> => {
    const cosigners = await this.list({ filter: { cosigners_id: [id] } }, config)
    if (!cosigners.length) throw new Error(Cosigner.MSG.ERR.NOT_FOUND)
    return cosigners
  }
}

export const cosigners = new CosignersBackend()
