export const some =
  <T>(...fns: ((item: T) => boolean)[]) =>
  (item: T) =>
    fns.some((fn) => fn(item))

export const every =
  <T>(...fns: ((item: T) => boolean)[]) =>
  (item: T) =>
    fns.every((fn) => fn(item))

export const not =
  <T>(fn: (item: T) => boolean) =>
  (item: T) =>
    !fn(item)

export const pick =
  <T extends Record<string, any>>(prop: keyof T) =>
  (item: T) =>
    item[prop]

export const pickAll = <T extends Record<string, any>, K extends keyof T>(prop: K, items: T[]) => {
  const result = new Set<Exclude<T[K], null | undefined>>(
    items?.map(pick(prop)).filter(notNullOrUndefined),
  )
  return [...result]
}

export const groupBy =
  <T extends Record<string, any>>(prop: keyof T) =>
  (items: T[]) =>
    Object.fromEntries(items.map((item) => [item[prop], item]))

const notNullOrUndefined = <T>(item: T) => item !== null && item !== undefined
