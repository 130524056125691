import { Client, DeleteConfig, GetConfig, PostConfig } from 'client'
import { PaymentMethod } from './payment-method'

export interface AutoPaymentSettings {
  amount_settings: string
  amount: number
  approved_at?: string
  created_at: string
  day_of_month: number
  failed_at: string
  lease_id: string
  lease_user_autopayment_settings_id: string
  notify_before: boolean
  payment_method_status?: AutoPaymentSettings.Status
  payment_method_type: PaymentMethod
  stripe_payment_method_id?: string
  updated_at: string
  user_id: string
}

export namespace AutoPaymentSettings {
  export type Id = Pick<AutoPaymentSettings, 'lease_user_autopayment_settings_id'>

  export const Singular = 'Automatic Payment'
  export const Plural = 'Automatic Payments'
  export const plural = 'automatic payments'
  export const singular = 'automatic payment'

  export interface Create {
    amount: number
    day_of_month: number
    lease_id: string
    payment_method: PaymentMethod
  }
  export interface CreateResponse {
    lease_user_autopayment_settings_id: string
    status: 'created'
    url: string
  }

  export const enum Status {
    form = 'form',
    connecting = 'connecting',
    ready = 'ready',
  }

  const ceil = (value: number) => Math.ceil(value * 100) / 100

  export const getFee = (payment_method: PaymentMethod, amount: number) =>
    payment_method === PaymentMethod.bank ? 0 : ceil(0.029 * amount + 0.3)

  export const getEqual = (total: number, users: number) =>
    users === 1 ? total : ceil(total / users)

  export const getStatus = (autopayment?: AutoPaymentSettings | null) => {
    if (!autopayment) return 'not set up'
    if (
      autopayment.failed_at ||
      autopayment.payment_method_status !== AutoPaymentSettings.Status.ready
    )
      return 'not set up (failed)'
    if (autopayment.approved_at) return 'set up'
    return 'awaiting confirmation from bank'
  }

  export const isAbandoned = (autopayment?: AutoPaymentSettings | null) =>
    autopayment && autopayment.payment_method_status !== AutoPaymentSettings.Status.ready
}

class AutoPaymentSettingsBackend extends Client {
  create = async (
    data?: AutoPaymentSettings.Create,
    config?: PostConfig,
  ): Promise<
    Pick<AutoPaymentSettings.CreateResponse, 'lease_user_autopayment_settings_id' | 'url'>
  > => {
    if (!data) throw new Error('Missing data')
    const { url, lease_user_autopayment_settings_id } = await this.post<
      AutoPaymentSettings.Create,
      AutoPaymentSettings.CreateResponse
    >('/lease/user/autopayment/new', data, config)
    return { url, lease_user_autopayment_settings_id }
  }

  remove = async (id: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/lease/user/autopayment/delete', {
      ...config,
      params: { ...config?.params, id },
    })
  }

  byId = async (id: string, config?: GetConfig): Promise<AutoPaymentSettings> => {
    const { data } = await this.get<{ data: AutoPaymentSettings }, { id: string }>(
      '/lease/user/autopayment/get',
      { id },
      config,
    )
    if (!data) throw new Error(`Auto payment not found (${id})`)
    return data
  }
}

export const autoPayments = new AutoPaymentSettingsBackend()
