import { Client, PostConfig } from 'client'
import { ListQuery } from 'utils/list'
import { OwnerPaymentAccountType } from './owner-payment-account-type'
import { NumberQuery } from './unit'
import { User } from '../user/user'

export type LedgerItem = Ledger.ChargeItem | Ledger.FailedItem

export namespace Ledger {
  export type Id = Pick<LedgerItem, 'transaction_id'>
  export type Sort = 'created_at' | 'paid_at' | 'failed_at' | 'amount' | 'title' | 'deposited_at'
  export type Query = ListQuery<
    Sort,
    {
      agent_id?: string[]
      date?: {
        from?: string
        to?: string
      }
      is_failed?: boolean
      is_paid?: boolean
      lease_id?: string[]
      owner_id?: string[]
      owner_user_id?: string[]
      property_id?: string[]
      transaction_id?: string[]
      type_id?: string[]
      user_id?: string[]
      account_type?: OwnerPaymentAccountType[]
      amount?: NumberQuery[]
    }
  >
  export type Filter = Query['filter']

  export const enum Type {
    MISC = 'misc',
    RENT = 'rent',
    DEPOSIT = 'deposit',
    FAILED = 'failed',
    REFUND = 'refund',
  }

  interface BaseItem {
    amount: number
    account_type?: OwnerPaymentAccountType
    balance: number
    created_at: string
    deposited_at?: string
    lease_id: string
    notes?: string
    reference: string
    target_account: string
    title: string
    transaction_id: string
    user_id: string
    user: Pick<User, 'email' | 'first_name' | 'last_name' | 'created_at'>
  }

  export interface ChargeItem extends BaseItem {
    type: Ledger.Type.MISC | Ledger.Type.RENT | Ledger.Type.DEPOSIT
  }
  export interface FailedItem extends BaseItem {
    related_transaction?: ChargeItem
    related_transaction_id?: string
    type: Ledger.Type.FAILED
  }

  export const isFailed = (item: LedgerItem): item is FailedItem => item.type === Type.FAILED
  export const isPayment = (item: LedgerItem) => item.amount < 0
  export const isCharge = (item: LedgerItem) => !isFailed(item) && item.amount > 0
}

export class LedgerBackend extends Client {
  list = async (query?: Ledger.Query, config?: PostConfig) => {
    const { ledger } = await this.post<Ledger.Query, { ledger: LedgerItem[]; status: string }>(
      '/ledger/get',
      query,
      config,
    )
    return ledger
  }

  byId = async (transaction_id: string, config?: PostConfig) => {
    const [item] = await this.list({ filter: { transaction_id: [transaction_id] } }, config)
    if (!item) throw new Error('Transaction not found')
    return item
  }
}

export const ledger = new LedgerBackend()
