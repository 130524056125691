import { Client, GetConfig } from 'client'

export interface CreditCheck {
  applicant_id: string
  completed_at?: string
  created_at: string
  order_id: string
  pdf_url?: string
  status: CreditCheck.Status
  user_id: string
}

export namespace CreditCheck {
  export type Id = Pick<CreditCheck, 'order_id'>
  export interface Query {
    uid: string
  }

  export const enum Status {
    NONE = 'none',
    /** after they were sent an email */
    STARTED = 'started',
    /** after they fill the forms */
    PENDING = 'pending',
    COMPLETED = 'completed',
  }
  const _isStarted = (creditCheck: CreditCheck) => creditCheck.status === Status.STARTED
  const _isPending = (creditCheck: CreditCheck) => creditCheck.status === Status.PENDING
  const _isCompleted = (creditCheck: CreditCheck) => creditCheck.status === Status.COMPLETED

  /** Ruturns true when there is at least one started */
  export const isStarted = (creditCheck?: null | CreditCheck | CreditCheck[]): boolean => {
    if (!creditCheck) return false
    return Array.isArray(creditCheck) ? creditCheck.some(_isStarted) : _isStarted(creditCheck)
  }

  /** Ruturns true when there is at least one pending */
  export const isPending = (creditCheck?: null | CreditCheck | CreditCheck[]): boolean => {
    if (!creditCheck) return false
    return Array.isArray(creditCheck) ? creditCheck.some(_isPending) : _isPending(creditCheck)
  }

  /** Ruturns true when (a) all items are completed and (b) there is at least one item */
  export const isCompleted = (creditCheck?: null | CreditCheck | CreditCheck[]): boolean => {
    if (!creditCheck) return false
    if (Array.isArray(creditCheck)) {
      return creditCheck.length ? creditCheck.every(_isCompleted) : false
    }
    return _isCompleted(creditCheck)
  }

  export const getStatus = (creditCheck?: null | CreditCheck | CreditCheck[]): Status =>
    isCompleted(creditCheck)
      ? Status.COMPLETED
      : isStarted(creditCheck)
      ? Status.STARTED
      : isPending(creditCheck)
      ? Status.PENDING
      : Status.NONE

  export const getStatusLabel = (status: Status) =>
    ({
      [Status.NONE]: 'Not started',
      [Status.STARTED]: 'Started',
      [Status.PENDING]: 'Pending',
      [Status.COMPLETED]: 'Completed',
    }[status])
}

class CreditCheckBackend extends Client {
  /** Fetch user credit checks (pending and completed) (use 'uid' query param) */
  list = async (query: CreditCheck.Query, config?: GetConfig): Promise<CreditCheck[]> => {
    type Result = { checks: CreditCheck[]; status: string }
    const { checks: checklist } = await this.get<Result, CreditCheck.Query>(
      '/admin/user/credit/check/get',
      query,
      config,
    )
    return checklist
  }

  getLast = async (query: CreditCheck.Query, config?: GetConfig): Promise<CreditCheck> => {
    type Result = { last_check: CreditCheck; status: string }
    const { last_check } = await this.get<Result, CreditCheck.Query>(
      '/admin/user/credit/check/get/last',
      query,
      config,
    )
    return last_check
  }

  /** Submit credit check process with Taz */
  submit = async (query: { uid: string }, config?: GetConfig): Promise<void> => {
    await this.get<{ status: string }, { uid: string }>(
      '/admin/user/credit/check/submit',
      query,
      config,
    )
  }
  /** Cancel credit check process started with Taz */
  cancel = async (query: { uid: string }, config?: GetConfig): Promise<void> => {
    await this.delete(`/admin/user/credit/check/cancel?uid=${query.uid}`, config)
  }
}

/** admin */
export const creditCheck = new CreditCheckBackend()
