import { Token } from 'api/template'
import { FC } from 'react'
import { RadioField, useField } from 'ui'
import { cn } from 'utils'
import styles from './page-field.module.scss'
import { getPageItemStyle } from '../util'

interface Props {
  token: Token
  props?: Token.ConditionalProps
  zoom: number
  labelClassName?: string
  errorClassName?: string
  hasUserData?: boolean
}

export const PageFieldRadios: FC<Props> = ({
  token,
  props,
  zoom,
  labelClassName,
  errorClassName,
  hasUserData,
}) => {
  const state = useField(token.token_id)
  if (props?.hidden) return null
  if (!Token.isRadioToken(token)) return null

  const defaultValue = hasUserData
    ? state?.defaultValue
    : typeof props?.value === 'string'
    ? props.value
    : undefined

  const shouldUseRadioDefault = !hasUserData && typeof defaultValue !== 'string'
  return (
    <RadioField
      name={token.token_id}
      label={token.label}
      required={token.required}
      className={styles.radio}
      inputClassName={styles.options}
      labelClassName={styles.label}
      validationClassName={cn(styles.error, errorClassName)}
    >
      {token.radio.map((radio) => {
        const checked = shouldUseRadioDefault ? radio.checked === '1' : radio.value === defaultValue
        return (
          <label
            key={radio.radio_id}
            style={getPageItemStyle(radio, zoom)}
            className={cn(styles.option, labelClassName)}
            data-role={token.role.toLowerCase()}
            aria-required={token.required}
          >
            <input
              type="radio"
              name={token.token_id}
              value={radio.value}
              defaultChecked={checked || undefined}
              className={styles.radioinput}
              id={radio.radio_id}
              required={token.required}
            />
            <span>{radio.value}</span>
          </label>
        )
      })}
    </RadioField>
  )
}
