import { AxiosRequestConfig } from 'axios'
import { GetConfig } from 'client'
import { not, pickAll } from 'utils/compose'
import { Bid, bid } from './bid'
import { Cosigner, cosigners } from './co-signers'
import { CreditCheck } from './credit-check'
import { CurrentUserBackend } from './current-user'
import { Guarantee, guarantee } from './guarantee'
import { INVITATION_HASH_PARAM } from './invitation'
import { Lease, lease } from './lease'
import { QualificationScore } from './qualification-score'
import { User, UserFinance } from '../user/user'

export namespace CurrentUser {
  export const parseInvite = (search: string | URLSearchParams) =>
    (search instanceof URLSearchParams ? search : new URLSearchParams(search)).get(
      INVITATION_HASH_PARAM,
    )
  export const saveAndTrackInvite = (invite_hash?: string | null) => {
    if (!invite_hash) return
    sessionStorage.setItem(INVITATION_HASH_PARAM, invite_hash)
    user.trackInvite(invite_hash).catch(() => {
      // ignore
    })
  }
  export const getInvite = () => sessionStorage.getItem(INVITATION_HASH_PARAM)
  export const clearInvite = () => sessionStorage.removeItem(INVITATION_HASH_PARAM)
}

export interface CurrentUser extends User {
  leases: Lease[]
  bid: Bid | null
}

export class HelloUserBackend extends CurrentUserBackend<CurrentUser> {
  async requestUserRecord(config?: Omit<AxiosRequestConfig, 'method'>): Promise<User | null> {
    const { user } = await this.get<{
      user: Omit<User, 'context'>
    }>('/user/get', config?.params, config)
    return user
  }

  async init(user: User, config?: GetConfig): Promise<CurrentUser> {
    const [leases, myBid] = await Promise.all([
      lease.listByUserId(user.user_id, config),
      bid.getLatest({ user_id: [user.user_id] }, config),
    ])
    return Object.assign(user, { leases, bid: myBid })
  }

  /** @deprecated */
  getMyScore = async (config?: GetConfig): Promise<number> => {
    const { score } = await this.get<{ score: number }>('/user/score/total', undefined, config)
    return Math.max(score ?? 0, 0)
  }

  /**
   * This service will fetch user qualification scores (including cosigners and guarantors)
   * @see https://api-dev.rello.co/swagger/index.html#/user/get_user_qualification_scores
   */
  getQualificationScore = async (config?: GetConfig): Promise<QualificationScore> => {
    const { scores } = await this.get<{ scores: QualificationScore }>(
      '/user/qualification/scores',
      undefined,
      config,
    )
    return scores
  }

  getMyCosignersGuarantors = async (
    config?: GetConfig,
  ): Promise<{ cosigners?: Cosigner[]; guaranteeRequests?: Guarantee.Request[] }> => {
    const user = await this.currentUser()
    if (!user) throw new Error('User not found')
    const allCosigners = await cosigners.getMyCosigners(config)
    const guarantee_id = pickAll('user_id', allCosigners)
    if (!guarantee_id.includes(user.user_id)) {
      guarantee_id.push(user.user_id)
    }
    const guaranteeRequests = guarantee_id?.length
      ? await guarantee.list({ filter: { guarantee_id } }, config)
      : []
    return {
      cosigners: allCosigners.filter(not(User.byId(user.user_id))).filter(not(Cosigner.isDeclined)),
      guaranteeRequests: guaranteeRequests.filter(not(Guarantee.isDeclined)),
    }
  }

  getFinances = async (config?: GetConfig): Promise<UserFinance> => {
    const { user } = await this.get<{ user: UserFinance }>('/user/finance/get', undefined, config)
    return user
  }

  startBackgroundCheck = async (config?: GetConfig): Promise<void> => {
    await this.get<{ status: string }>('/user/background-check/send', undefined, config)
  }

  startCreditCheck = async (config?: GetConfig): Promise<void> => {
    await this.get<{ status: string }>('/user/credit/check/submit', undefined, config)
  }

  getCreditCheck = async (config?: GetConfig): Promise<CreditCheck[]> => {
    const user = await this.currentUser()
    if (!user) throw new Error('User not found')
    const { checks } = await this.get<{ checks: CreditCheck[] }, { uid: string }>(
      '/user/credit/check/get',
      { uid: user.user_id },
      config,
    )
    return checks
  }
}

export const user = new HelloUserBackend()
