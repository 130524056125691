import { GetConfig, PostConfig, convertToServerData } from 'client'
import { Order, createPaginatedList, parseOrder, parsePagination } from 'utils/list'
import { OwnerBackend, Owner as _Owner } from './owner'
import { OwnerPaymentAccount } from './owner-payment-account.admin'
import { PropertyManager, propertyManager } from './property-manager.admin'
import { AdminUser } from '../user/user.admin'

export interface Owner extends _Owner {
  bank_accounts?: OwnerPaymentAccount[]
}

export namespace Owner {
  export type Id = _Owner.Id
  export type IdField = _Owner.IdField
  export type Query = _Owner.Query
  export type Sort = _Owner.Sort
  export type Filter = _Owner.Filter

  export const Singular = _Owner.Singular
  export const Plural = _Owner.Plural

  export const MSG = {
    ERR: {
      NO_ID: 'Missing owner_id.',
      NOT_FOUND: 'Owner not found.',
      NO_SIGNER: 'Owner lease signer is undefined.',
    },
  } as const

  export interface UserRelation {
    owner_id: string
    user_id: string
  }

  export type Data = Pick<
    Owner,
    | 'address'
    | 'allow_nonqualified_applications'
    | 'ein'
    | 'email'
    | 'finix_enabled'
    | 'lease_signer_user_id'
    | 'name'
    | 'phone_number'
    | 'property_manager_id'
  >

  export function parseSearchParams(
    _searchParams: string | URLSearchParams,
    _filter?: Filter,
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Filter = {}
    const searchParams =
      _searchParams instanceof URLSearchParams ? _searchParams : new URL(_searchParams).searchParams
    const global = searchParams.get('global') as string
    if (global) filter.global = global
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export function getFilterFor(user: AdminUser): Filter {
    if (AdminUser.hasRoleOwner(user)) return { user_id: [user.user_id] }
    if (AdminUser.isAgentNotOwnerNotAdmin(user)) return { agent_id: [user.user_id] }
    return {}
  }

  export type WithPropertyManager = Owner &
    (
      | { property_manager_id: string; property_manager: PropertyManager }
      | { property_manager_id?: null; property_manager?: null }
    )

  export const byId = (owner_id: string) => (item: Id) => item.owner_id === owner_id
  export const pickId = ({ owner_id }: Id) => owner_id
}

export class AdminOwnerBackend extends OwnerBackend<Owner> {
  create = async (data: Owner.Data, config?: PostConfig): Promise<Owner> => {
    const { owner } = await this.post<Owner.Data, { owner: Owner; status: string }>(
      '/owner/new',
      data,
      config,
    )
    return owner
  }

  update = async (
    owner_id: string,
    updates: Partial<Owner.Data>,
    config?: PostConfig,
  ): Promise<Owner> => {
    const { owner } = await this.post<
      { updates: Partial<Owner.Data>; owner_id: string },
      { owner: Owner; status: string }
    >(
      '/owner/update',
      {
        owner_id,
        updates: toServerData(updates),
      },
      config,
    )
    return owner
  }

  list = async (query: Owner.Query = {}, config?: PostConfig): Promise<Owner[]> => {
    const { owners } = await this.post<Owner.Query, { owners: Owner[]; status: string }>(
      '/owner/get',
      query,
      config,
    )
    return owners
  }

  listWithPropertyManager = async (
    query: Owner.Query = {},
    config?: PostConfig,
  ): Promise<Owner.WithPropertyManager[]> => {
    const owners = await this.list(query, config)
    const property_manager_id = owners
      .map((owner) => owner.property_manager_id)
      .filter(Boolean) as string[]
    const propertyManagers = property_manager_id.length
      ? await propertyManager.list({ filter: { property_manager_id } }, config)
      : []
    return owners.map(({ property_manager_id, ...owner }) =>
      property_manager_id
        ? {
            ...owner,
            property_manager_id,
            property_manager: propertyManagers.find(PropertyManager.byId(property_manager_id))!,
          }
        : owner,
    )
  }

  count = async (query: Owner.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Owner.Query, { count: number; status: string }>(
      '/owner/count',
      query,
      config,
    )
    return count
  }

  paginatedList = createPaginatedList<Owner>(this.list, this.count)

  usersById = async (id: string, config?: GetConfig): Promise<AdminUser.Brief[]> => {
    const { users } = await this.get<{ users: AdminUser.Brief[]; status: string }, { id: string }>(
      '/owner/user/get',
      { id },
      config,
    )
    return users
  }

  addUser = async (data: Owner.UserRelation, config?: PostConfig): Promise<void> => {
    await this.post<Owner.UserRelation, { status: string }>('/owner/user/add', data, config)
  }

  removeUser = async (data: Owner.UserRelation, config?: PostConfig): Promise<void> => {
    await this.post<Owner.UserRelation, { status: string }>('/owner/user/remove', data, config)
  }
}

export const owner = new AdminOwnerBackend()

function toServerData({ address, ...updates }: Partial<Owner.Data>): Partial<Owner.Data> {
  return {
    ...convertToServerData(updates, {
      string: [
        'ein',
        'email',
        'name',
        'phone_number',
        'lease_signer_user_id',
        'property_manager_id',
      ],
    }),
    ...(address && {
      address: convertToServerData(address, {
        string: ['city', 'state', 'street', 'zip'],
      }),
    }),
  }
}
