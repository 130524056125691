import { Client, PostConfig } from 'client'

export namespace Maintenance {
  export interface Create {
    attachment: File
    subject: string
    description: string
  }
}

export class MaintenanceBackend extends Client {
  create = async (data: Maintenance.Create, config?: PostConfig): Promise<void> => {
    await this.post<Maintenance.Create, { status: string }>('/issue/new', data, {
      ...config,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export const maintenance = new MaintenanceBackend()
