export type Listener<T> = (value: T | null, previousValue?: T | null) => void

export class Observable<T> {
  private _listeners: Listener<T>[] = []
  private _value: T | null = null

  constructor(value?: T | null) {
    this._value = value ?? null
  }

  addListener(listener: Listener<T>, run = true): () => void {
    if (!this._listeners.includes(listener)) this._listeners.push(listener)
    run && listener(this._value)
    return this.removeUserListener.bind(this, listener)
  }

  removeUserListener(listener: Listener<T>) {
    const index = this._listeners.indexOf(listener)
    if (index > -1) this._listeners.splice(index, 1)
  }

  get(): T | null {
    return this._value
  }

  set(value: T | null) {
    const previousValue = this._value
    if (previousValue === value) return
    this._value = value
    this._listeners.forEach((listener) => listener(value, previousValue))
  }
}
