import { PdfViewer } from '@rello/pdf'
import { Token } from 'api/template'
import { UserSignature } from 'api/user-signature'
import { convertToServerData } from 'client'
import { ReactNode, useCallback, useRef, useState } from 'react'
import { Form } from 'ui'
import { cn } from 'utils'
import { alertErrorMessage } from 'utils/toast'
import { LeaseBuilderActions } from './lease-builder-actions'
import styles from './lease-builder.module.scss'
import { PageField } from './page-field'
import { TemplateScope } from './scope'
import { SignatureContextProvider } from './signature-context'
import templateStyles from '../template.module.scss'

type Data = Record<string, any>

interface Props<T> {
  pdf: Blob
  footerClassName?: string
  className?: string
  defaultValues?: Partial<Data>
  onSubmit: (values: Data) => Promise<T>
  onSave?: (values: Data) => Promise<any>
  redirect?: (result: T) => any
  fields: Token[]
  submitLabel: string
  signatures?: Record<UserSignature.PersistentType, { blob: Blob; snapshot_id: string }>
  loaderElement?: ReactNode
  vars?: Record<string, any>
  pageClassName?: string
}

export function LeaseBuilder<T>({
  pdf,
  className,
  footerClassName,
  defaultValues,
  submitLabel,
  redirect,
  onSubmit,
  onSave,
  fields,
  signatures,
  loaderElement,
  pageClassName,
  vars = {},
}: Props<T>) {
  const [submitting, setSubmitting] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const submit = useCallback(
    async ({ action, ...values }: Data & { action?: 'save' }) => {
      const dateFieldIds = fields.filter(Token.isDate).map(Token.pickId)
      const checkboxFieldIds = fields.filter(Token.isCheckbox).map(Token.pickId)
      const data = convertToServerData(values, { date: dateFieldIds, noFalse: checkboxFieldIds })
      if (onSave && action === 'save') {
        await onSave(data).catch((e) => {
          alertErrorMessage(e)
          throw e
        })
      } else {
        setSubmitting(true)
        return await onSubmit(data).catch((e) => {
          setSubmitting(false)
          alertErrorMessage(e)
          throw e
        })
      }
    },
    [fields, onSave, onSubmit],
  )

  return (
    <>
      <SignatureContextProvider value={signatures}>
        <Form
          defaultValues={defaultValues}
          onSubmit={submit}
          className={cn(styles.form, submitting && styles.submitting, className)}
          redirect={redirect}
        >
          <TemplateScope fields={fields} vars={vars}>
            <div className={cn(templateStyles.template, styles.workspace)} ref={contentRef}>
              <PdfViewer
                pdf={pdf}
                className={styles.document}
                pageClassName={pageClassName}
                onError={alertErrorMessage}
                renderPageContent={({ zoom, id, width, height }) => {
                  if (!zoom) return <></>
                  return (
                    <div className={styles.page} style={{ width, height }}>
                      {fields.filter(Token.byPageNumber(id)).map((token) => (
                        <PageField
                          token={token}
                          zoom={zoom}
                          key={token.token_id}
                          hasUserData={!!defaultValues}
                        />
                      ))}
                    </div>
                  )
                }}
              />
            </div>
            <footer className={cn(styles.footer, footerClassName)}>
              <LeaseBuilderActions submitLabel={submitLabel} fields={fields} showSave={!!onSave} />
            </footer>
          </TemplateScope>
        </Form>
      </SignatureContextProvider>
      {submitting && loaderElement}
    </>
  )
}
