const nbsp = '\xa0'

export const pluralizeUnit = (
  count: number | null | undefined,
  singular: string,
  plural?: string,
) => {
  plural ??= singular + 's'
  return count === 1 ? singular : plural
}

export const pluralize = (count: number | null | undefined, singular: string, plural?: string) => {
  return [count || 'No', pluralizeUnit(count, singular, plural)].join(nbsp)
}
