import { Owner } from './owner'

export interface QualificationScore {
  income: number
  income_asset: number
  rello: number
  /**
   * When {true} users will be able to submit applications regardless of their individual score.
   */
  voucher?: boolean
}

export namespace QualificationScore {
  export const enum Type {
    rello = 'rello',
    income = 'income',
    income_asset = 'income_asset',
  }

  // keyof QualificationScore
  export const TYPE: Record<Type, string> = {
    [Type.rello]: 'Bank Data Indicator',
    [Type.income]: 'Income Verification (x40)',
    [Type.income_asset]: 'Assets + Income',
  } as const

  export const TYPES = Object.keys(TYPE) as Type[]

  export const getLabel = (type: Type) => TYPE[type]

  export const JOINT_HINT = '(excluding joint accounts)'
  export const JOINT_DESCRIPTION =
    "Please note that in the total score displayed, joint accounts are counted only once. However, for each individual's score, a joint account may be included in their calculation. This ensures accuracy while preventing duplication in the overall scoring."

  /** @returns {number | undefined} score based on owner's preferences */
  export const getOwnerQualificationScore = (
    score: QualificationScore,
    owner?: Owner,
    options?: { forceQualification?: boolean },
  ) => {
    if (!options?.forceQualification) {
      if (owner?.allow_nonqualified_applications) return undefined
    }
    return Math.max(score.income ?? 0, score.income_asset ?? 0, score.rello ?? 0)
  }
}
