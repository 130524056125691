import { Token } from 'api/template'
import { FC } from 'react'
import { MoneyField } from 'ui'
import { cn } from 'utils'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  props?: Token.ConditionalProps
  hasUserData?: boolean
}

export const PageFieldNumber: FC<Props> = ({ token, props, hasUserData }) => {
  if (props?.hidden) return null
  if (!Token.isNumber(token)) return null

  const readOnly = props?.read_only ?? Token.isReadOnly(token)
  const format = readOnly ? Token.createNumberFormatter(token.format) : undefined
  const value = hasUserData && !readOnly ? undefined : Number(props?.value)
  const valid = typeof value === 'number' && !Number.isNaN(value)

  const min = readOnly
    ? undefined
    : props && 'min' in props && typeof props.min === 'number'
    ? props.min
    : undefined
  const max = readOnly
    ? undefined
    : props && 'max' in props && typeof props.max === 'number'
    ? props.max
    : undefined
  return (
    <>
      <MoneyField
        autoComplete="off"
        className={cn(styles.pagefield, styles.field, readOnly && styles.hidden)}
        id={token.token_id}
        inputClassName={styles.input}
        label={token.label}
        labelClassName={styles.label}
        name={token.token_id}
        readOnly={readOnly}
        required={!readOnly && token.required}
        validationClassName={styles.error}
        placeholder=""
        min={min}
        max={max}
        {...(valid && { defaultValue: value })}
      />
      {readOnly && <span className={cn(styles.pagefield, styles.static)}>{format?.(value)}</span>}
    </>
  )
}
