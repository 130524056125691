import { Parser, type Expression } from 'expr-eval'
import { addDays, addMonths, addYears, formatISO, parseDate, serverToISODate } from 'utils/date'

export { type Expression }
export namespace FormulaParser {
  const assign = (parser: Parser, api: any, target: keyof Parser = 'functions') => {
    Object.assign(parser[target], api)
    return parser
  }

  let parser: Parser

  const getParser = () => {
    if (!parser) parser = extendParser(new Parser())
    return parser
  }

  const extendParser = (parser: Parser) => {
    return assign(assign(parser, constants, 'consts'), api, 'functions')
  }

  export const parse = (expression: string): Expression => {
    const parser = getParser()
    return parser.parse(expression)
  }

  export const getFunctions = () => {
    return Object.keys(getParser().functions)
  }

  export const getConstants = () => {
    return Object.keys(getParser().consts)
  }

  export const isFunctionName = (name: string | null) => !!name && getFunctions().includes(name)
  export const isConstName = (name: string | null) => !!name && getConstants().includes(name)

  const constants = {
    year_inc_oct24_sep25: 0.0275,
    two_year_inc_oct24_sep25: 0.0525,
  }

  const api = {
    addDays(serverdate: string, n: number) {
      if (!serverdate) return null
      const date = parseDate(serverToISODate(serverdate))
      if (!date) return null
      return formatISO(addDays(date, n))
    },
    addMonths(serverdate: string, n: number) {
      if (!serverdate) return null
      const date = parseDate(serverToISODate(serverdate))
      if (!date) return null
      return formatISO(addMonths(date, n))
    },
    addYears(serverdate: string, n: number) {
      if (!serverdate) return null
      const date = parseDate(serverToISODate(serverdate))
      if (!date) return null
      return formatISO(addYears(date, n))
    },
    before(serverdate: string, serverdate2: string) {
      if (!serverdate || !serverdate2) return false
      const date1 = parseDate(serverToISODate(serverdate))
      if (!date1) return null
      const date2 = parseDate(serverToISODate(serverdate))
      if (!date2) return null
      return date1.getTime() < date2.getTime()
    },
  }
}
