import { Client, GetConfig, PostConfig } from 'client'
import { ListQuery } from 'utils/list'
import { Owner } from './owner'

export namespace Property {
  export type Id = Pick<Property, 'property_id'>
  export const singular = 'property'
  export const Singular = 'Property'
  export const plural = 'properties'
  export const Plural = 'Properties'
  export type Sort =
    | 'city'
    | 'country'
    | 'created_at'
    | 'district'
    | 'name'
    | 'state'
    | 'street'
    | 'zip'
  export type Query = ListQuery<
    Sort,
    {
      property_id?: string[]
      owner_id?: string[]
      owner_user_id?: string[]
      agent_id?: string[]
    }
  >
  export type Filter = Query['filter']

  export const hasLocation = (property: Property) => !!(property.latitude && property.longitude)
}

export interface Property {
  city: string
  country: string
  created_at: string
  description?: string
  district?: string
  latitude?: number
  longitude?: number
  name: string
  owner?: Owner
  owner_id?: string
  property_id: string
  state: string
  street: string
  updated_at?: string
  zip: string
}

export class PropertyBackend extends Client {
  list = async (query: Property.Query = {}, config?: PostConfig): Promise<Property[]> => {
    const { properties } = await this.post<
      Property.Query,
      { properties: Property[]; status: string }
    >('/property/get/all', query, config)
    return properties
  }

  count = async ({ filter }: Property.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Property.Query, { count: number; status: 'success' }>(
      '/property/count',
      { filter },
      config,
    )
    return count
  }

  byId = async (id: string, config?: GetConfig): Promise<Property> => {
    const { property } = await this.get<{ property: Property; status: string }, { pid: string }>(
      '/property/get',
      { pid: id },
      config,
    )
    return property
  }

  byIds = async (
    property_id: string[],
    query?: Property.Query,
    config?: PostConfig,
  ): Promise<Property[]> =>
    !property_id.length
      ? []
      : await this.list({ ...query, filter: { ...query?.filter, property_id } }, config)
}

export const property = new PropertyBackend()
