export const enum OwnerPaymentAccountType {
  OPERATIONAL = 'ops',
  DEPOSIT = 'deposit',
}

export const OWNER_PAYMENT_ACCOUNT_TYPE = {
  [OwnerPaymentAccountType.OPERATIONAL]: 'Ops',
  [OwnerPaymentAccountType.DEPOSIT]: 'Deposit',
}

export const OWNER_PAYMENT_ACCOUNT_TYPES = [
  OwnerPaymentAccountType.OPERATIONAL,
  OwnerPaymentAccountType.DEPOSIT,
].map((type) => ({ value: type, label: OWNER_PAYMENT_ACCOUNT_TYPE[type] }))
