import { Client, GetConfig } from 'client'

export class SubscriptionBackend extends Client {
  getJWT = async (config?: GetConfig): Promise<string | null> => {
    const { token } = await this.get<{ status: string; token: string }>(
      '/ably/jwt',
      undefined,
      config,
    )
    return token
  }
}

export const subscription = new SubscriptionBackend()
