import { parseDate } from 'utils/date'

export interface Auction {
  archived_at_at?: string
  auction_id: string
  bid_range_high?: number
  bid_range_low?: number
  closed_at?: string
  created_at: string
  disable_promotion?: boolean
  end_at: string
  increments: number
  lease_end_at?: string
  lease_id?: string
  lease_start_at: string
  promotion?: string
  rent_now_expiration?: number
  rent_now_offered_at?: string
  rent_now_price?: number
  start_at: string
  start_price: number
  unit_id: string
}

export namespace Auction {
  export type Id = Pick<Auction, 'auction_id'>

  export const RENT_NOW = 'Instant Rent'

  export const getHighestBid = ({ bid_range_high, bid_range_low }: Auction) =>
    bid_range_high ?? bid_range_low ?? null

  export const getBidRange = (auction: Auction): [number, number] | [number] | [] =>
    hasBidRange(auction)
      ? [auction.bid_range_low!, auction.bid_range_high!]
      : auction.bid_range_low
      ? [auction.bid_range_low]
      : []

  export const isArchived = (auction: Auction) => !!auction.archived_at_at
  export const isClosed = (auction: Auction) => !!auction.closed_at
  export const isEnded = (auction: Auction) => {
    const end = parseDate(auction.end_at)
    return end ? end.getTime() < Date.now() : false
  }
  export const isStarted = (auction: Auction) =>
    (parseDate(auction.start_at)?.getTime() ?? 0) < Date.now()
  /** started, not ended, not closed */
  export const isRunning = (auction: Auction) =>
    !isEnded(auction) && !isClosed(auction) && isStarted(auction)
  export const getStatus = (auction: Auction) =>
    isArchived(auction)
      ? 'Archived'
      : isClosed(auction)
      ? 'Closed'
      : isEnded(auction)
      ? 'Ended'
      : isRunning(auction)
      ? 'Running'
      : 'Not started'

  export const isRentNowVisible = (auction: Auction) =>
    isRunning(auction) &&
    !isClosed(auction) &&
    !isRentNowExpired(auction) &&
    !!auction.rent_now_price

  export const isRentNowPrequalified = (auction: Auction, score: number) =>
    isRentNowVisible(auction) && auction.rent_now_price! <= score

  export const hasOffers = (auction: Auction) => Boolean(auction.bid_range_low)

  export const isRentNowTaken = (auction: Auction) => !!auction.rent_now_offered_at

  export const isRentNowExpired = (auction: Auction) => {
    return Date.now() >= (getRentNowExpirationDate(auction)?.getTime() ?? 0)
  }

  export const getRentNowExpirationInMinutes = (auction: Auction) =>
    auction.rent_now_expiration ? Math.floor(auction.rent_now_expiration / 60) : 30

  export const getRentNowExpirationDate = (auction: Auction) => {
    const { rent_now_expiration } = auction
    if (!rent_now_expiration) return null
    const end = parseDate(auction.end_at)
    if (!end) return null
    return new Date(end.getTime() - rent_now_expiration * 1000)
  }

  export const getRentNowOfferExpirationDate = (auction: Auction) => {
    const { rent_now_offered_at, rent_now_expiration } = auction
    if (!rent_now_expiration || !rent_now_offered_at) return null
    const expires = parseDate(rent_now_offered_at)
    if (!expires) return null
    return new Date(expires.getTime() + rent_now_expiration * 1000)
  }

  export const hasBidRange = ({ bid_range_high, bid_range_low }: Auction) =>
    typeof bid_range_low === 'number' &&
    typeof bid_range_high === 'number' &&
    bid_range_low !== bid_range_high

  export const isBidRangeBelowListPrice = ({
    bid_range_high,
    bid_range_low,
    start_price,
  }: Auction) => {
    if (bid_range_high) return bid_range_high < start_price
    if (bid_range_low) return bid_range_low < start_price
    return false
  }

  export const hasPromotion = (auction: Auction) =>
    !!auction.promotion && !auction.disable_promotion
}
