import { pickAll } from 'utils/compose'
import { Optional } from 'utils/type-utils'
import { Token } from './token.admin'
import { UserSignature } from '../user-signature'

export interface TemplateCustomToken {
  default_value: string
  description?: string
  input_type: Token.Type.TEXT
  key: string
  mandatory?: boolean
  name: string
  template_id: string
}

export namespace TemplateCustomToken {
  export type Id = Pick<TemplateCustomToken, 'key'>
  export const INPUT_TYPES = [{ value: Token.Type.TEXT, label: 'Text' }]
  export const pickKey = (template: TemplateCustomToken) => template.key
  export const byKey = (id: string) => (token: TemplateCustomToken) => pickKey(token) === id

  export interface Field {
    label: string
    name?: string
    description?: string
    default_value?: string
    type: Token.Type
    config: Pick<Token, 'role' | 'required' | 'lock_to_sign_date'> &
      Pick<Token.Signature, 'subtype'> &
      Optional<Pick<Token.TokenRadio, 'radio'>, 'radio'>
  }

  export interface Group {
    name: string
    fields: Field[]
  }

  export function toField({
    name,
    required = true,
    type = Token.Type.TEXT,
    label,
    role = Token.Role.API,
    description,
    default_value,
    ...config
  }: {
    name: string
    type?: Token.Type
    label: string
    description?: string
    default_value?: string
  } & Partial<Field['config']>): Field {
    return {
      name,
      type,
      label,
      description,
      default_value,
      config: {
        lock_to_sign_date: false,
        ...config,
        required,
        role,
      },
    }
  }

  export const findFieldByName = (groups: Group[], name: string) => {
    const baseName = name.replace(/-.*/, '')
    for (let group of groups) {
      const field = group.fields.find((field) => field.name === baseName)
      if (field) return field
    }
    return undefined
  }

  export const getCoreName = (name: string) => {
    let baseName = name.replace(/-.*/, '')
    return baseName.replace(/(owner_)|(tenant\d_)|(guarantor\d_)/, '')
  }

  export const createBaseName = (coreName: string, role?: Token.Role) =>
    !role || role === Token.Role.API ? coreName : `${role.toLowerCase()}_${coreName}`

  export const PERSON_ROLES = [
    Token.Role.OWNER,
    Token.Role.T1,
    Token.Role.T2,
    Token.Role.T3,
    Token.Role.T4,
    Token.Role.T5,
    Token.Role.T6,
    Token.Role.G1,
    Token.Role.G2,
    Token.Role.G3,
    Token.Role.G4,
    Token.Role.G5,
    Token.Role.G6,
  ] as const

  export const roleToLabel = (role: Token.Role) =>
    role === Token.Role.FIRST_SIGNER ? 'First Signer' : String(role).replace(/(\d)$/, ' $1')

  const roleToPosessive = (role: Token.Role) =>
    role === Token.Role.ADMIN
      ? ''
      : role === Token.Role.OWNER
      ? "Owner's "
      : role === Token.Role.FIRST_SIGNER
      ? "First Signer's "
      : role.replace(/^(\w)\w*(\d)$/, "$1$2's ")

  export const ROLE_OPTIONS = [Token.Role.ADMIN, Token.Role.FIRST_SIGNER, ...PERSON_ROLES].map(
    (role) => ({
      label: roleToLabel(role),
      value: role,
    }),
  )

  export const getCustomFields = (role: Token.Role) => [
    {
      label: 'Text',
      type: Token.Type.TEXT,
      config: {
        role,
        required: true,
      },
    },
    {
      label: 'Number',
      type: Token.Type.NUMBER,
      config: {
        role,
        required: false,
      },
    },
    {
      label: 'Date',
      type: Token.Type.DATE,
      config: {
        role,
        required: true,
        lock_to_sign_date: false,
      },
    },
    {
      label: 'Radio',
      type: Token.Type.RADIO,
      config: {
        role,
        required: true,
      },
    },
    {
      label: 'Check',
      type: Token.Type.CHECKBOX,
      config: {
        role,
        required: false,
      },
    },
  ]

  export const getPersonalFields = (role: Token.Role) => [
    {
      name: createBaseName('name', role),
      label: roleToPosessive(role) + 'Name',
      type: Token.Type.TEXT,
      config: {
        role: Token.Role.API,
      },
    },
    {
      name: createBaseName('address', role),
      label: roleToPosessive(role) + 'Address',
      type: Token.Type.TEXT,
      config: {
        role: Token.Role.API,
      },
    },
  ]

  export const getSignatureFields = (role: Token.Role) => [
    {
      label: 'Signature',
      type: Token.Type.SIGNATURE,
      config: {
        role,
        required: true,
        subtype: UserSignature.Type.SIGNATURE,
        ...Token.SIGNATURE_DIMENSION,
      },
    },
    {
      label: 'Initials',
      type: Token.Type.SIGNATURE,
      config: {
        role,
        required: true,
        subtype: UserSignature.Type.INITIALS,
        ...Token.INITIALS_DIMENSION,
      },
    },
    {
      label: 'Sign Date',
      type: Token.Type.DATE,
      config: {
        role,
        required: true,
        lock_to_sign_date: true,
      },
    },
  ]

  export const DEFAULT_GROUPS: Group[] = [
    {
      name: roleToLabel(Token.Role.ADMIN),
      fields: getCustomFields(Token.Role.ADMIN),
    },
    {
      name: roleToLabel(Token.Role.FIRST_SIGNER),
      fields: [
        ...getSignatureFields(Token.Role.FIRST_SIGNER),
        ...getCustomFields(Token.Role.FIRST_SIGNER),
      ],
    },
    ...PERSON_ROLES.map((role) => ({
      name: roleToLabel(role),
      fields: [...getPersonalFields(role), ...getSignatureFields(role), ...getCustomFields(role)],
    })),
  ] as const

  export const ALL_PERSONAL_FIELDS = PERSON_ROLES.flatMap(getPersonalFields)
  export const PERSONAL_TOKEN_NAMES = pickAll('name', ALL_PERSONAL_FIELDS)
  export const PERSONAL_TOKEN_OPTIONS = ALL_PERSONAL_FIELDS.map((field) => ({
    value: field.name,
    label: field.label,
  }))
}
