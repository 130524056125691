import { Client, DeleteConfig, PostConfig } from 'client'

export interface PaymentCharge {
  owed?: number
  description?: string
  created_at: string
  lease_payment_id: string
  lease_payment_charge_id: string
  title: string
}

export namespace PaymentCharge {
  export type Id = Pick<PaymentCharge, 'lease_payment_charge_id'>

  export interface Create {
    description?: string
    lease_payment_id: string
    owed: number
    title: string
  }
  export interface CreateResponse {
    debug: string
    lease_payment_charge_id: string
    message: string
    status: string
  }
}

class PaymentChargeBackend extends Client {
  create = async (
    data: PaymentCharge.Create,
    config?: PostConfig,
  ): Promise<PaymentCharge.CreateResponse> => {
    return await this.post<PaymentCharge.Create, PaymentCharge.CreateResponse>(
      '/lease/payment/charge/new',
      data,
      config,
    )
  }

  remove = async (id: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/lease/payment/charge/delete', { ...config, params: { id } })
  }
}

export const paymentCharges = new PaymentChargeBackend()
