import { UserSignature } from 'api/user-signature'
import { ComponentProps, FC } from 'react'
import { useFieldControl } from 'ui'
import { SignaturePersistentField } from './signature-persistent-field'
import { SignatureWetField } from './signature-wet-field'

type SubProps = ComponentProps<typeof SignaturePersistentField> &
  ComponentProps<typeof SignatureWetField>

type Props = Pick<SubProps, 'className' | 'token'>

export const SignatureField: FC<Props> = ({ token, ...props }) => {
  const subtype = token.subtype || UserSignature.Type.SIGNATURE

  const [{ disabled }, onUpdate] = useFieldControl<string | null>({
    name: token.token_id,
    id: token.token_id,
    validationMessage: token.required ? 'Required' : null,
    defaultValue: null,
    touched: false,
  })

  if (!UserSignature.isValidType(subtype)) throw new Error(`Invalid signature type: ${subtype}.`)

  if (UserSignature.isValidPersistentType(subtype)) {
    return (
      <SignaturePersistentField
        {...props}
        token={{ ...token, subtype }}
        onUpdate={onUpdate}
        id={token.token_id}
        name={token.token_id}
        disabled={disabled}
        aria-required={token.required}
      />
    )
  }

  return (
    <SignatureWetField
      {...props}
      token={token}
      onUpdate={onUpdate}
      id={token.token_id}
      name={token.token_id}
      disabled={disabled}
      aria-required={token.required}
    />
  )
}
