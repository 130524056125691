import { Client, DeleteConfig, PostConfig } from 'client'
import { OwnerPaymentAccountType } from './owner-payment-account-type'

export interface OwnerPaymentAccount {
  owner_bank_account_id: string
  created_at: string
  name: string
  owner_id: string
  stripe_account_id: string
  account_type: OwnerPaymentAccountType
  account_status: string
  finix_merchant_id: string
}

export namespace OwnerPaymentAccount {
  export type Id = Pick<OwnerPaymentAccount, 'owner_bank_account_id'>

  export const enum AccountStatus {
    COMPLETED = 'Completed',
    RESTRICTED = 'Restricted',
    ENABLED = 'Enabled',
    APPROVED = 'Approved',
    PROVISIONING = 'Provisioning',
    DECLINED = 'Declined',
  }
  export const enum AccountType {
    DEPOSIT = 'deposit',
    OPS = 'ops',
  }

  export interface Create {
    owner_id: string
    name: string
  }
  export interface Update {
    account_id: string
    name: string
    owner_id: string
  }
  export interface CreateResponse {
    owner_id: string
    status: string
    url: string
  }

  export interface Delete {
    account_id: string
    owner_id: string
  }
  export interface DeleteResponse {
    status: string
  }
  export interface FinixCreate {
    account_type: AccountType
    finix_token: string
    name: string
    owner_id: string
  }
  export interface FinixUpdate {
    account_type: OwnerPaymentAccountType
    name: string
    owner_bank_account_id: string
    owner_id: string
  }
  export interface FinixCreateResponse {
    owner_bank_account_id: string
    status: string
  }
  export interface FinixUpdateResponse {
    status: string
  }

  export const byId = (id: string) => (account?: OwnerPaymentAccount) =>
    account?.owner_bank_account_id === id

  export const enum Type {
    OPERATIONAL = 'ops',
  }
}

export class OwnerPaymentAccountsBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/payments/post_payments_account_new
   */
  create = async (data: OwnerPaymentAccount.Create, config?: PostConfig): Promise<string> => {
    const { url } = await this.post<OwnerPaymentAccount.Create, OwnerPaymentAccount.CreateResponse>(
      '/payments/account/new',
      data,
      config,
    )
    return url
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/payments/post_payments_account_edit
   */
  update = async (data: OwnerPaymentAccount.Update, config?: PostConfig): Promise<string> => {
    const { url } = await this.post<OwnerPaymentAccount.Update, { status: string; url: string }>(
      '/payments/account/edit',
      data,
      config,
    )
    return url
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/payments/post_payments_account_delete
   */
  remove = async (data: OwnerPaymentAccount.Delete, config?: DeleteConfig): Promise<void> => {
    await this.post<OwnerPaymentAccount.Delete, OwnerPaymentAccount.DeleteResponse>(
      '/payments/account/delete',
      data,
      config,
    )
  }
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/finix/post_fnx_owner_bank_create
   */
  createFinixBankAccount = async (
    data: OwnerPaymentAccount.FinixCreate,
    config?: PostConfig,
  ): Promise<string> => {
    const { status } = await this.post<
      OwnerPaymentAccount.FinixCreate,
      OwnerPaymentAccount.FinixCreateResponse
    >('/fnx/owner/bank/create', data, config)
    return status
  }

  updateFinixBankAccount = async (
    data: OwnerPaymentAccount.FinixUpdate,
    config?: PostConfig,
  ): Promise<string> => {
    const { status } = await this.post<
      OwnerPaymentAccount.FinixUpdate,
      OwnerPaymentAccount.FinixUpdateResponse
    >('/fnx/owner/bank/update', data, config)
    return status
  }
}

export const ownerPaymentAccount = new OwnerPaymentAccountsBackend()
