import { Token } from 'api/template'
import { FC } from 'react'
import { TextField } from 'ui'
import { cn } from 'utils'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  props?: Token.ConditionalProps
  hasUserData?: boolean
}

export const PageFieldText: FC<Props> = ({ token, props, hasUserData }) => {
  if (props?.hidden) return null
  if (!Token.isText(token)) return null

  const readOnly = props?.read_only ?? Token.isReadOnly(token)
  const value =
    hasUserData && !readOnly
      ? undefined
      : props?.value
      ? String(props.value)
      : token.prefilled_text ?? undefined

  return (
    <>
      <TextField
        autoComplete="off"
        className={cn(styles.pagefield, styles.field, readOnly && styles.hidden)}
        id={token.token_id}
        inputClassName={styles.input}
        label={token.label}
        labelClassName={styles.label}
        name={token.token_id}
        readOnly={readOnly}
        required={token.required}
        validationClassName={styles.error}
        {...(typeof value !== 'undefined' ? { defaultValue: value } : {})}
      />
      {readOnly && <span className={cn(styles.pagefield, styles.static)}>{value ?? ''}</span>}
    </>
  )
}
