import { Client, DeleteConfig, PostConfig, convertToServerData } from 'client'
import { createDiff } from 'utils/diff'
import { PickType } from 'utils/type-utils'
import { Auction } from './auction'
import { Unit } from './unit'

export namespace AdminAuction {
  export type Create = Pick<
    Auction,
    | 'disable_promotion'
    | 'end_at'
    | 'lease_start_at'
    | 'lease_end_at'
    | 'promotion'
    | 'rent_now_price'
    | 'rent_now_expiration'
    | 'start_at'
    | 'start_price'
    | 'unit_id'
    | 'increments'
  >

  export type Update = Partial<Omit<Create, Unit.IdField>>

  export const STRING_FIELDS: (keyof PickType<Update, string>)[] = ['promotion']
  export const BOOLEAN_FIELDS: (keyof PickType<Update, boolean>)[] = ['disable_promotion']
  export const NUMBER_FIELDS: (keyof PickType<Update, number>)[] = [
    'start_price',
    'rent_now_price',
    'rent_now_expiration',
    'increments',
  ]
  const DATE_FIELDS: (keyof PickType<Update, string>)[] = [
    'lease_start_at',
    'lease_end_at',
    'start_at',
    'end_at',
  ]

  export const getDiff = createDiff<Auction, Update>({
    date: DATE_FIELDS,
    boolean: BOOLEAN_FIELDS,
    number: NUMBER_FIELDS,
    string: STRING_FIELDS,
  })
  export function toServerCreate(data: AdminAuction.Create) {
    return convertToServerData(data, {
      number: NUMBER_FIELDS,
      date: DATE_FIELDS,
    })
  }
  export function toServerUpdate(data: AdminAuction.Update) {
    return convertToServerData(data, {
      number: NUMBER_FIELDS,
      date: DATE_FIELDS,
      string: STRING_FIELDS,
    })
  }
}

class AuctionBackend extends Client {
  create = async (data?: AdminAuction.Create, config?: PostConfig): Promise<string> => {
    if (!data) throw new Error('Missing data')
    type Req = AdminAuction.Create
    type Res = { auction_id: string; status: string }

    const { auction_id } = await this.post<Req, Res>(
      '/auction/new',
      AdminAuction.toServerCreate(data),
      config,
    )
    return auction_id
  }

  update = async (
    { auction_id, ...data }: AdminAuction.Update & Auction.Id,
    config?: PostConfig,
  ): Promise<void> => {
    if (!data) throw new Error('Missing data')
    type Req = AdminAuction.Update & Auction.Id
    type Res = { auction_id: string; status: string }

    await this.post<Req, Res>(
      '/auction/update',
      { auction_id, ...AdminAuction.toServerUpdate(data) },
      config,
    )
  }

  archive = async (data: Auction.Id, config?: PostConfig): Promise<void> => {
    await this.post('/auction/archive', data, config)
  }

  remove = async (aid: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/auction/delete', {
      ...config,
      params: { ...config?.params, aid },
    })
  }

  reopen = async ({ auction_id }: Auction.Id, config?: DeleteConfig): Promise<void> => {
    await this.post<Auction.Id, { status: string }>('/auction/reopen', { auction_id }, config)
  }
}

export const auction = new AuctionBackend()
