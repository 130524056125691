import { Client, GetConfig } from 'client'
import { ApplicationChecklist } from './application-checklist'

export namespace AdminApplicationChecklist {
  export interface Query extends ApplicationChecklist.Query {
    uid: string
  }
}

class AdminApplicationChecklistBackend extends Client {
  list = async (
    query: AdminApplicationChecklist.Query,
    config?: GetConfig,
  ): Promise<ApplicationChecklist[]> => {
    const { checklist } = await this.get<
      { checklist: ApplicationChecklist[]; status: string },
      AdminApplicationChecklist.Query
    >('/admin/application/checklist/get', query, config)

    // exceptional scenario:
    // when 2 cosigners have a common guarantor, we may have not-uninique checklist items
    const dedupedChecklist = checklist.reduce(
      (checklist, item) =>
        checklist.find((i) => i.item_id === item.item_id) ? checklist : [...checklist, item],
      [] as ApplicationChecklist[],
    )
    if (dedupedChecklist.length !== checklist.length) {
      // eslint-disable-next-line no-console
      console.warn('DUPLICATE application checklist items', checklist, dedupedChecklist)
    }
    return dedupedChecklist
  }
}

export const adminApplicationChecklist = new AdminApplicationChecklistBackend()
