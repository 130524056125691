import { Client, GetConfig, PostConfig } from 'client'
import { groupBy, pickAll } from 'utils/compose'
import { DateValue, addDays, addMonths, addYears, isDateFuture, parseDate } from 'utils/date'
import { ListQuery } from 'utils/list'
import { Application } from './application'
import { Auction } from './auction'
import { Owner, owner } from './owner'
import { UnitPhoto } from './photo'
import { property, Property } from './property'
import { QualificationScore } from './qualification-score'
import { User } from '../user/user'

export interface Unit {
  agents?: User[]
  application_count?: number
  application_fee_amount?: number
  available_at?: string
  auction?: Auction
  baths?: number
  beds?: number
  city: string
  country: string
  created_at: string
  credit_check_required?: boolean
  /** @default false */
  deposit?: boolean
  description?: string
  /**
   * @deprecated use `hidden` / `vacant` instead
   */
  disabled_at?: string
  dishwasher?: boolean
  district?: string
  enable_monthly_payments?: boolean
  extend?: Unit.Extend
  floor?: number
  /**
   * used to hide units on the map and search (for all sorts of reasons)
   * @default {false}
   */
  hidden?: boolean
  imported_at?: string
  imported_data?: Unit.ExternalSource
  imported_from?: 'rentmanager' | 'url'
  is_commercial?: boolean
  last_application_at?: string
  latitude?: number
  longitude?: number
  monthly_rent?: number
  name: string
  owner_id?: string
  pets_allowed?: boolean
  photos?: UnitPhoto[]
  preferred_lease_term?: Unit.PreferredTerm
  property_id?: string
  rooms?: number
  /** SteerEasy unit id */
  se_unit_id?: string
  sqft?: number
  state?: string
  street: string
  unit_id: string
  unit_number: string
  updated_at: string
  /**
   * Once lease is signed will turn to false
   * @default {true}
   */
  vacant?: boolean
  virtual_doorman?: boolean
  wd_in_unit?: boolean
  zip: string
  rent_control?: Unit.RentControlValues
}

export namespace Unit {
  export type IdField = 'unit_id'
  export type Id = Pick<Unit, IdField>
  export const singular = 'unit'
  export const Singular = 'Unit'
  export const plural = 'units'
  export const Plural = 'Units'

  export const MSG = {
    ERR: {
      NO_ID: 'Missing unit_id.',
      NOT_FOUND: `${Singular} not found.`,
      SE_NOT_FOUND: `StreetEasy ${singular} not found.`,
    },
  } as const

  export interface WithPrequalification extends WithOwnerAndProperty {
    isPrequalified: boolean
  }

  export interface ExternalSource {
    corp_id: string
    unit_id: string
  }
  export interface ImportRM {
    corp_id: string
    unit_id?: string
    rm_unit_id: string
    with_leases?: boolean
    owner_id?: string
  }
  export type ImportURL = {
    url: string
  } & ((Property.Id & Owner.Id) | Unit.Id)

  export interface WithProperty extends Unit {
    property?: Property
  }
  export interface WithOwner extends Unit {
    owner?: Owner
  }
  export interface WithOwnerAndProperty extends Unit {
    owner?: Owner
    property?: Property
  }

  export interface StreetEasyLanding {
    baths: number
    beds: number
    photo: string
    district?: string
    rent: number
    sqft: number
    unit_id: string
    unit_name: string
  }

  export type Sort =
    | 'district'
    | 'city'
    | 'country'
    | 'created_at'
    | 'name'
    | 'state'
    | 'street'
    | 'zip'
    | 'application_count'
    | 'last_application_at'

  export const enum Selector {
    id = 'id',
  }

  export type Query = ListQuery<
    Sort,
    {
      agent_id?: string[]
      baths?: NumberQuery[]
      beds?: NumberQuery[]
      dishwasher?: boolean
      /** @deprecated */
      exclude_disabled?: boolean
      hidden?: boolean
      invited_users?: string[]
      is_commercial?: boolean
      monthly_rent?: NumberQuery[]
      owner_id?: string[]
      owner_user_id?: string[]
      pets_allowed?: boolean
      property_id?: string[]
      rooms?: NumberQuery[]
      sqft?: NumberQuery[]
      vacant?: boolean
      virtual_doorman?: boolean
      wd_in_unit?: boolean
      unit_id?: string[]
    },
    Selector
  >
  export type Filter = Query['filter']

  export const enum TermPeriod {
    YEARS = 'years',
    MONTHS = 'months',
    WEEKS = 'weeks',
    DAYS = 'days',
  }

  export const enum RentControlValues {
    FAIR_MARKET = 'fair-market',
    RENT_STABILIZE = 'rent-stabilize',
    // RENT_CONTROL = 'rent-control', Rare case, will be implemented later.
  }
  export const RentControlLabels: { [key in RentControlValues]: string } = {
    [RentControlValues.FAIR_MARKET]: 'Fair Market',
    [RentControlValues.RENT_STABILIZE]: 'Rent Stabilize',
    // [RentControlValues.RENT_CONTROL]: 'Rent Control', // Add this when implementing
  }

  export const RentControlOptions = Object.entries(RentControlLabels).map(([value, label]) => ({
    value,
    label,
  }))

  export function getRentControlValue(unit: Unit) {
    return RentControlLabels[unit.rent_control ?? RentControlValues.FAIR_MARKET]
  }
  export type PreferredTerm = { [k in Unit.TermPeriod]?: number }

  export type WithApplication = Unit & {
    application: Application | null
  }

  export function getType(unit: Unit) {
    return unit.is_commercial === true ? 'Commercial' : 'Residential'
  }
  export function getShortType(unit: Unit) {
    return unit.is_commercial === true ? 'Comm.' : unit.is_commercial === false ? 'Res.' : undefined
  }
  export const requiresCreditCheck = (unit: Unit) => !!unit.credit_check_required
  export const hasApplicationFee = (unit: Unit) => (unit.application_fee_amount ?? 0) > 0
  export const hasServiceFee = (unit: Unit) => false
  /** auction start price or monthly rent */
  export const getListPrice = (unit: Unit) => unit.auction?.start_price ?? unit.monthly_rent ?? 0
  export function getFee(unit: Unit) {
    return 0
  }

  /** monthly rent + fee */
  export function getTotalPrice(unit: Unit) {
    return getListPrice(unit) + getFee(unit)
  }

  export const getPreferredLeaseEndDate = (unit: Unit, start?: DateValue) => {
    if (!start) return null
    let end = parseDate(start)
    const period = unit.preferred_lease_term
    if (!end || !period) return addDays(addYears(start, 1), -1)
    if (period.days) end = addDays(end, period.days)
    if (period.weeks) end = addDays(end, period.weeks * 7)
    if (period.months) end = addMonths(end, period.months)
    if (period.years) end = addYears(end, period.years)
    return addDays(end, -1)
  }

  export function isPrequalified({
    unit,
    scores,
    owner,
  }: {
    unit: Unit
    scores?: QualificationScore
    owner?: Owner
  }) {
    if (!scores) return false
    if (scores.voucher) return true
    const score = QualificationScore.getOwnerQualificationScore(scores, owner)
    if (score === undefined) return true
    return score >= getTotalPrice(unit)
  }

  export function prequalify(
    units: Unit.WithOwnerAndProperty[],
    { scores, owner }: { scores?: QualificationScore; owner?: Owner },
  ): Unit.WithPrequalification[] {
    return units.map((unit) => ({
      ...unit,
      isPrequalified: isPrequalified({ unit, scores, owner: owner ?? unit.owner }),
    }))
  }

  export interface Extend {
    ical?: string
    airbnb_id?: string
    nightly_rent?: number
  }

  export interface Group {
    latitude: number
    longitude: number
    units: Unit.WithPrequalification[]
    key: string
    isPrequalified: boolean
  }

  /**
   * "Group units by property, if there's no property, group by unit."
   *
   * The function takes an array of units and returns an array of groups. Each group has a key, latitude,
   * longitude, and an array of units
   * @param {Unit.WithProperty[] | null} units - Unit.WithProperty[] | null
   * @returns An array of objects with the following properties:
   * units: an array of units
   * latitude: a number
   * longitude: a number
   * key: a string
   */
  export const groupByProperty = (
    units: (Unit.WithProperty & Unit.WithPrequalification)[] | null,
  ): Group[] => {
    const map = new Map<
      string,
      {
        units: (Unit.WithProperty & Unit.WithPrequalification)[]
        latitude: number
        longitude: number
        key: string
      }
    >()

    units?.forEach((unit) => {
      if (unit.property) {
        const { property_id } = unit.property
        const group = map.get(property_id)
        if (group) {
          return group.units.push(unit)
        }
        const { latitude, longitude } = unit.property
        if (latitude && longitude) {
          return map.set(property_id, {
            units: [unit],
            latitude,
            longitude,
            key: property_id,
          })
        }
      }
      // if there's no property OR missing property lat/long
      // group by unit_id
      const { unit_id, latitude, longitude } = unit

      // no lat/long, skip
      if (!latitude || !longitude) return

      map.set(unit_id, {
        units: [unit],
        latitude,
        longitude,
        key: unit_id,
      })
    })

    return Array.from(map.values()).map((group) => ({
      ...group,
      isPrequalified: group.units.some((unit) => unit.isPrequalified),
    }))
  }

  export const hasAuction = (unit: Unit) => !!unit.auction
  export const hasLocation = (unit: Pick<Unit, 'latitude' | 'longitude'>) =>
    !!(unit.latitude && unit.longitude)

  export const hasAvailableDate = (unit: Pick<Unit, 'available_at'>) =>
    !!unit.available_at && isDateFuture(unit.available_at)

  export const getLocation = (unit: Unit | null, property?: Property | null) =>
    property && hasLocation(property)
      ? {
          latitude: property.latitude!,
          longitude: property.longitude!,
        }
      : unit && hasLocation(unit)
      ? {
          latitude: unit.latitude!,
          longitude: unit.longitude!,
        }
      : undefined

  export const pickId = ({ unit_id }: Id) => unit_id
  export const byId = (id: string) => (unit: Id) => unit.unit_id === id
}

type NumberOperator = '>' | '>=' | '<' | '<=' | '='
export type NumberQuery = `${NumberOperator}${number}`

export class UnitBackend extends Client {
  list = async (query: Unit.Query = {}, config?: PostConfig): Promise<Unit[]> => {
    const { units } = await this.post<Unit.Query, { units: Unit[]; status: string }>(
      '/unit/get/all',
      { ...query, filter: { exclude_disabled: true, ...query.filter } },
      config,
    )
    return units
  }
  listIds = async (query: Unit.Query = {}, config?: PostConfig): Promise<string[]> => {
    const units = (await this.list(
      { ...query, filter: { exclude_disabled: true, ...query.filter }, selector: Unit.Selector.id },
      config,
    )) as Unit.Id[]
    return pickAll('unit_id', units)
  }
  listWithProperty = async (
    query: Unit.Query = {},
    config?: PostConfig,
  ): Promise<Unit.WithOwnerAndProperty[]> => {
    const units = await this.list(query, config)
    const owner_id = query.filter?.owner_id
    const propertiesMap = await property
      .byIds(pickAll('property_id', units), owner_id ? { filter: { owner_id } } : {}, config)
      .then(groupBy('property_id'))
    return units.map((unit) => ({
      ...unit,
      ...(unit.property_id && { property: propertiesMap[unit.property_id] }),
    }))
  }

  listWithOwnerAndProperty = async (
    query: Unit.Query = {},
    config?: PostConfig,
  ): Promise<Unit.WithOwnerAndProperty[]> => {
    const units = await this.list(query, config)
    const [propertiesMap, ownersMap] = await Promise.all([
      property.byIds(pickAll('property_id', units), {}, config).then(groupBy('property_id')),
      owner.byIds(pickAll('owner_id', units), config).then(groupBy('owner_id')),
    ])
    return units.map((unit) => ({
      ...unit,
      ...(unit.owner_id && { owner: ownersMap[unit.owner_id] }),
      ...(unit.property_id && { property: propertiesMap[unit.property_id] }),
    }))
  }
  listWithOwner = async (
    query: Unit.Query = {},
    config?: PostConfig,
  ): Promise<Unit.WithOwner[]> => {
    const units = await this.list(query, config)
    const ownersMap = await owner
      .byIds(pickAll('owner_id', units), config)
      .then(groupBy('owner_id'))
    return units.map((unit) => ({
      ...unit,
      ...(unit.owner_id && { owner: ownersMap[unit.owner_id] }),
    }))
  }

  count = async (query: Unit.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Unit.Query, { count: number; status: 'success' }>(
      '/unit/count',
      query ?? null,
      config,
    )
    return count
  }

  byId = async (id: string, config?: GetConfig): Promise<Unit> => {
    const { unit } = await this.get<{ unit: Unit; status: string }, { pid: string }>(
      '/unit/get',
      { pid: id },
      config,
    )
    return unit
  }

  getStreetEasyLanding = async (hash: string, config?: GetConfig) => {
    const item = await this.get<Unit.StreetEasyLanding, { _rlh: string }>(
      '/streeteasy/landing',
      { _rlh: hash },
      config,
    )
    if (!item?.unit_id) throw new Error(Unit.MSG.ERR.SE_NOT_FOUND)
    return item
  }
}

export const unit = new UnitBackend()
