import { Client, PostConfig } from 'client'
import { isoToServerDate } from 'utils/date'
import { parseStringArraySearchParam, parseStringSearchParam } from 'utils/list'

export namespace Dashboard {
  export type Query = {
    agent_id?: string[]
    owner_user_id?: string
    property_id?: string[]
    from?: string
    to?: string
  }

  export type InvitationStat = {
    logged_in: number
    registered: number
    sent: number
    signed_lease: number
    submitted_offer: number
  }

  export type OfferStatItem = {
    active_listings: number
    avg_close: number
    closed_leases: number
    date: string
  }

  export interface Filter {
    property_id?: string[]
    agent_id?: string[]
    from?: string
    to?: string
  }

  export function parseFilter(searchParams: URLSearchParams): Filter {
    const property_id = parseStringArraySearchParam(searchParams, 'property_id')
    const agent_id = parseStringArraySearchParam(searchParams, 'agent_id')
    const from = parseStringSearchParam(searchParams, 'from')
    const to = parseStringSearchParam(searchParams, 'to')
    return {
      property_id,
      agent_id,
      from,
      to,
    }
  }
}

class DasboardBackend extends Client {
  invitationData = async ({ from, to, ...query }: Dashboard.Query = {}, config?: PostConfig) => {
    const { stats } = await this.post<
      Dashboard.Query,
      { stats: Dashboard.InvitationStat; status: string }
    >(
      '/owner/dashboard/funnel',
      {
        ...query,
        ...(to && { to: isoToServerDate(to) }),
        ...(from && { from: isoToServerDate(from) }),
      },
      config,
    )
    return stats
  }

  offerData = async ({ from, to, ...query }: Dashboard.Query = {}, config?: PostConfig) => {
    const { stats } = await this.post<
      Dashboard.Query,
      { stats: Dashboard.OfferStatItem[]; status: string }
    >(
      '/owner/dashboard/offers',
      {
        ...query,
        ...(to && { to: isoToServerDate(to) }),
        ...(from && { from: isoToServerDate(from) }),
      },

      config,
    )
    return stats
  }
}

export const dashboard = new DasboardBackend()
