import { dateToServerDate } from 'client'
import { parseDate } from './date'
import { PickType } from './type-utils'

type OtherHandlers<T, K> = {
  [Key in keyof T as Exclude<Key, keyof PickType<T, K>>]?: (value: T[Key]) => string
}

type DiffConfig<T> = {
  boolean: (keyof PickType<T, boolean>)[]
  number: (keyof PickType<T, number>)[]
  date: (keyof Partial<PickType<T, string>>)[]
  string: (keyof PickType<T, string>)[]
} & OtherHandlers<T, boolean | number | string>

export function createDiff<T extends object, Update extends Partial<T>>({
  date,
  boolean,
  number,
  string,
  ...custom
}: DiffConfig<Update>) {
  type BooleanUpdateField = keyof PickType<Update, boolean>
  type NumberUpdateField = keyof PickType<Update, number>
  type DateUpdateField = keyof PickType<Update, string>
  type StringUpdateField = Exclude<keyof PickType<Update, string>, DateUpdateField>

  return (record: T, update: Update) => {
    const result: Partial<Update> = {}
    for (let entry of Object.entries(update)) {
      const field = entry[0] as keyof T & keyof Update & string
      const value = entry[1] as Update[typeof field]
      const currentValue = record[field] as T[typeof field]

      if (field in custom) {
        const func = custom[field as keyof typeof custom] as (
          value?: T[typeof field] | Update[typeof field],
        ) => string
        if (typeof func !== 'function')
          throw new Error(`Custom handler for ${field} is not a function`)
        if (
          Boolean(currentValue) !== Boolean(value) ||
          (Boolean(currentValue) && Boolean(value) && func(value) !== func(currentValue))
        ) {
          result[field] = value as Update[typeof field]
        }
      } else if (boolean.includes(field as BooleanUpdateField)) {
        if (Boolean(value) !== Boolean(record[field])) {
          result[field] = Boolean(value) as Update[typeof field]
        }
      } else if (number.includes(field as NumberUpdateField)) {
        if ((value ?? 0) !== (record[field] ?? 0)) {
          result[field] = (value ?? 0) as Update[typeof field]
        }
      } else if (date.includes(field as DateUpdateField)) {
        const valueAsNumber = value ? parseDate(value as string)?.getTime() : null
        const currentAsNumber = record[field] ? parseDate(record[field] as string)?.getTime() : null
        if (valueAsNumber !== currentAsNumber) {
          result[field] = dateToServerDate(value as string) as Update[typeof field]
        }
      } else if (string.includes(field as StringUpdateField)) {
        if ((value ?? '') !== (record[field] ?? '')) {
          result[field] = (value ?? '') as Update[typeof field]
        }
      } else {
        throw new Error(`Unhandled field: ${field}`)
      }
    }

    return result
  }
}
