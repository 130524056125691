import { UserSignature } from 'api/user-signature'
import { FC, ComponentProps, useState } from 'react'
import { Button, Navbar, PureDialog } from 'ui'
import { cn } from 'utils'
import styles from './persistent-dialog.module.scss'
import { SignatureDraw } from '../draw'
import { SignatureLoad } from '../load'
import { SignatureType } from '../type'

interface Props extends Omit<ComponentProps<typeof PureDialog>, 'children'> {
  type: UserSignature.PersistentType
  onSuccess: (data: { snapshot_id: string; blob: Blob }) => void
}
export const SignaturePersistentDialog: FC<Props> = ({
  className,
  type = UserSignature.Type.SIGNATURE,
  onSuccess,
  onClose,
}) => {
  const [dialogTab, setDialogTab] = useState<'draw' | 'type' | 'load'>('draw')

  return (
    <PureDialog className={cn(styles.dialog, className)} onClose={onClose} open={!!dialogTab}>
      <header>
        <h1 className={styles.title}>Add your {type}</h1>
      </header>
      <Navbar className={styles.menu}>
        <li>
          <Button onClick={() => setDialogTab('draw')} aria-current={dialogTab === 'draw'}>
            Draw
          </Button>
        </li>
        <li>
          <Button onClick={() => setDialogTab('type')} aria-current={dialogTab === 'type'}>
            Type
          </Button>
        </li>
        <li>
          <Button onClick={() => setDialogTab('load')} aria-current={dialogTab === 'load'}>
            Load
          </Button>
        </li>
      </Navbar>
      {dialogTab === 'draw' && <SignatureDraw type={type} onSuccess={onSuccess} />}
      {dialogTab === 'type' && <SignatureType type={type} onSuccess={onSuccess} />}
      {dialogTab === 'load' && <SignatureLoad type={type} onSuccess={onSuccess} />}
    </PureDialog>
  )
}
