import { Application } from './application'
import { Auction } from './auction'
import { Unit } from './unit'

export interface UnitApplicationSummary extends Unit {
  type: 'regular' | 'dynamic pricing'
  currentOffers: [number, number] | [number] | []
  status: string
  listPrice: number
  offer: number
  lift: number | null
}

export namespace UnitApplicationSummary {
  export const fromUnit = (unit: Unit, applications: Application[]): UnitApplicationSummary => {
    const hasAuction = Unit.hasAuction(unit)
    const currentOffers = unit.auction
      ? Auction.getBidRange(unit.auction)
      : Application.getBidRange(applications)

    const listPrice = Unit.getListPrice(unit)
    const offer = currentOffers.at(-1) ?? 0

    return {
      ...unit,
      type: hasAuction ? 'dynamic pricing' : 'regular',
      currentOffers,
      listPrice: listPrice,
      offer,
      lift: offer && listPrice ? Math.max(offer / listPrice - 1, 0) || null : null,
      status: !unit.auction
        ? 'Accepting applications'
        : unit.auction.lease_id
        ? 'Offer Accepted'
        : Auction.isRunning(unit.auction)
        ? 'Accepting offers'
        : Auction.isEnded(unit.auction)
        ? 'Ended'
        : Auction.isClosed(unit.auction)
        ? 'Closed'
        : '?',
    }
  }
  export const fromUnitsAndApplications = (units: Unit[], applications: Application[]) =>
    units.map((unit) =>
      fromUnit(
        unit,
        applications.filter((application) => application.unit?.unit_id === unit.unit_id),
      ),
    )
}
