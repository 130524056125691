import { Client, DeleteConfig, PostConfig } from 'client'

export interface UnitPhoto {
  created_at: string
  name?: string
  ordinal: number
  photo_id: string
  unit_id: string
  url: string
}

export namespace UnitPhoto {
  export type Id = Pick<UnitPhoto, 'photo_id'>

  export interface Create {
    name: string
    ordinal: number
    unit_id: string
    url: string
  }
}

export class PhotoBackend extends Client {
  upload = async (data: UnitPhoto.Create, config?: PostConfig): Promise<UnitPhoto> => {
    return await this.post<{ values: UnitPhoto.Create }, UnitPhoto>(
      '/unit/photo/new',
      { values: data },
      config,
    )
  }

  update = async (
    id: string,
    data: Partial<UnitPhoto>,
    config?: PostConfig,
  ): Promise<UnitPhoto> => {
    return await this.post<Partial<{ values: Partial<UnitPhoto> & UnitPhoto.Id }>, UnitPhoto>(
      '/unit/photo/update',
      {
        values: {
          ...data,
          photo_id: id,
        },
      },
      {
        ...config,
        params: { pid: id },
      },
    )
  }

  remove = async (id: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/unit/photo/delete', {
      ...config,
      params: { ...config?.params, pid: id },
    })
  }
}

export const photo = new PhotoBackend()
