import { Client, convertToServerData, PostConfig } from 'client'
import { addDays, toInputDatetime, toISODate } from 'utils/date'
import { Application } from './application'
import { AdminApplication, adminApplication } from './application.admin'
import { Lease } from './lease'
import { AdminLease, lease as leaseApi } from './lease.admin'
import { owner as ownerAPI, Owner } from './owner.admin'
import { Unit, unit as unitAPI } from './unit'
import { Template, template as templateApi } from '../template/template.admin'

export interface LeaseDraft {
  action: LeaseDraft.Action
  admin_values?: Record<string, string>
  api_values?: Record<string, string>
  auction_id?: string
  based_on_application_id?: string
  based_on_lease_id?: string
  created_at: string
  deposit: number
  /** if true, guarantors will be not included in contract & lease checklist */
  disable_guarantors?: boolean
  /** if true, payment will be not included lease checklist */
  disable_payments?: boolean
  draft_id: string
  end_at: string
  guarantors?: string[]
  offer_expire_at?: string
  override_tenants?: string[]
  owner_id: string
  owner_signer_id?: string
  rent: number
  start_at: string
  templates: string[]
  tenants: string[]
  updated_at?: string
  waive_deposit?: boolean
}

export namespace LeaseDraft {
  export type IdField = 'draft_id'
  export type Id = Pick<LeaseDraft, IdField>
  export type Pseudo = Omit<LeaseDraft, IdField | 'created_at'>

  export const enum Action {
    APPLICATION = 'application',
    REGENERATE = 'regenerate',
    RENEW = 'renew',
  }

  export const MSG = {
    ACTION: {
      [Action.REGENERATE]: AdminLease.MSG.ACTION.CONTRACT_REGENERATE,
      [Action.RENEW]: AdminLease.MSG.ACTION.RENEW,
      [Action.APPLICATION]: AdminLease.MSG.ACTION.CREATE,
      SAVE: 'Save',
      SAVE_SUCCESS: 'Draft Saved.',
    },
    ERR: {
      NO_ID: 'Missing draft_id.',
      INVALID: 'Invalid query.',
      NOT_FOUND: 'Lease draft not found.',
      DOWNDLOAD_FAILED: 'Failed to load PDF.',
    },
    LIST_EMPTY: 'No lease drafts found.',
  } as const

  export type Create = Pick<
    LeaseDraft,
    | 'action'
    | 'auction_id'
    | 'based_on_application_id'
    | 'based_on_lease_id'
    | 'deposit'
    | 'disable_guarantors'
    | 'end_at'
    | 'offer_expire_at'
    | 'rent'
    | 'start_at'
    | 'templates'
    | 'waive_deposit'
  > & {
    override_tenants?: string[]
    owner_signer_id: string
  }

  export const getDefaultValuesFromApplication = ({
    application,
    unit,
    owner,
  }: {
    application: AdminApplication
    unit: Unit
    owner: Owner
  }): LeaseDraft.Pseudo => {
    if (!application) throw new Error(AdminApplication.MSG.ERR.NOT_FOUND)
    if (!unit) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    if (!owner) throw new Error(Owner.MSG.ERR.NOT_FOUND)

    const { start_at, end_at } = Application.getTerms(unit, application, unit.auction)
    if (!application.users?.length) throw new Error(AdminApplication.MSG.ERR.NO_TENANTS)

    const tenants = AdminApplication.pickCosignerIds([application])
    const guarantors = AdminApplication.pickGuarantorIds([application])

    return {
      action: Action.APPLICATION,
      auction_id: unit.auction?.auction_id,
      based_on_application_id: application.application_id,
      deposit: unit.deposit ? application.bid ?? unit.monthly_rent : 0,
      end_at,
      templates: [],
      rent: application.bid ?? unit.monthly_rent ?? 0,
      start_at,
      override_tenants: tenants,
      tenants,
      guarantors,
      owner_id: owner.owner_id,
      owner_signer_id: owner.lease_signer_user_id,
    }
  }

  export const getDefaultValuesFromLease = ({
    lease,
    unit,
    renew,
    owner,
  }: {
    lease: Lease
    unit: Unit
    renew?: boolean
    owner: Owner
  }): LeaseDraft.Pseudo => {
    if (!lease) throw new Error(AdminLease.MSG.ERR.NOT_FOUND)
    if (!lease.lease_users?.length) throw new Error(AdminLease.MSG.ERR.NO_TENANTS)
    if (!owner) throw new Error(Owner.MSG.ERR.NOT_FOUND)

    const { start_at, end_at } = renew ? AdminLease.getDefaultRenewTerms(lease) : lease
    if (!start_at) throw new Error('missing start_at')
    if (!end_at) throw new Error('missing end_at')

    const tenants = AdminLease.pickCosignerIds(lease)
    const data: LeaseDraft.Pseudo = {
      action: renew ? Action.RENEW : Action.REGENERATE,
      based_on_lease_id: lease.lease_id,
      deposit: lease.deposit ?? (unit.deposit ? lease.monthly_rent ?? 0 : 0),
      end_at,
      templates: lease.templates ?? [],
      rent: lease.monthly_rent ?? 0,
      start_at,
      owner_id: owner.owner_id,
      owner_signer_id: owner.lease_signer_user_id,
      override_tenants: tenants,
      tenants,
      guarantors: AdminLease.pickGuarantorIds(lease),
    }
    if (renew) {
      const expires_at = addDays(new Date(), 1)
      expires_at.setSeconds(0)
      expires_at.setMilliseconds(0)
      data.offer_expire_at = toInputDatetime(expires_at)
      data.disable_guarantors = true
    }
    return data
  }

  export const createTemplateFilter = (draft: Pick<LeaseDraft, 'tenants' | 'guarantors'>) => {
    const cosigners = draft.tenants?.length ?? 0
    const guarantors = draft.guarantors?.length ?? 0
    return (item: Template) =>
      !(item.max_tenants === 0 && item.max_guarantors === 0) &&
      // disable when max_guarantors defined and guarantors > max_guarantors
      ((typeof item.max_guarantors === 'number' ? guarantors > item.max_guarantors : false) ||
        // disable when max_tenants defined and cosigners > max_tenants
        (typeof item.max_tenants === 'number' ? cosigners > item.max_tenants : false))
  }
}

export class LeaseDraftBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/application/post_lease_draft_new
   */
  create = async (data: LeaseDraft.Create, config?: PostConfig): Promise<string> => {
    const { draft_id } = await this.post<LeaseDraft.Create, { draft_id: string; status: string }>(
      '/lease/draft/new',
      convertToServerData(data, {
        date: ['start_at', 'end_at', 'offer_expire_at'],
      }),
      config,
    )
    return draft_id
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_draft_get */
  byId = async (
    query: Application.Id | (Lease.Id & Pick<LeaseDraft, 'action'>) | LeaseDraft.Id,
    config?: PostConfig,
  ): Promise<LeaseDraft> => {
    type Params = { aid: string } | { lid: string; action: LeaseDraft.Action } | { did: string }
    let params: Params
    if ((query as Application.Id).application_id) {
      params = { aid: (query as Application.Id).application_id }
    } else if ((query as Lease.Id).lease_id && (query as Pick<LeaseDraft, 'action'>).action) {
      params = {
        lid: (query as Lease.Id).lease_id,
        action: (query as Pick<LeaseDraft, 'action'>).action,
      }
    } else if ((query as LeaseDraft.Id).draft_id) {
      params = { did: (query as LeaseDraft.Id).draft_id }
    } else {
      throw new Error(LeaseDraft.MSG.ERR.INVALID)
    }
    const { draft } = await this.get<{ draft: LeaseDraft }, Params>(
      '/lease/draft/get',
      params,
      config,
    )
    return draft
  }

  byApplicationId = async (
    application_id: string,
    config?: PostConfig,
  ): Promise<{
    draft: LeaseDraft | LeaseDraft.Pseudo
    application: AdminApplication
    unit: Unit
    templates: Template[]
  }> => {
    let [draft, application] = await Promise.all([
      this.byId({ application_id }, config),
      adminApplication.byId(application_id, config),
    ])

    const unit_id = application.unit?.unit_id
    if (!unit_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    if (!application.unit?.owner_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT_OWNER)

    const [unit, owner] = await Promise.all([
      unitAPI.byId(unit_id, config),
      ownerAPI.byId(application.unit.owner_id, config),
    ])
    if (!unit) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    if (!owner) throw new Error(AdminLease.MSG.ERR.NO_UNIT_OWNER)

    if (
      draft &&
      draft.owner_signer_id &&
      draft.owner_id &&
      (draft.auction_id ?? '') === (unit.auction?.auction_id ?? '') &&
      draft.templates?.length
    ) {
      const templates = await templateApi.listByIds(draft.templates, config)
      if (templates?.length) {
        // get rid of invalid templates
        draft.templates = draft.templates?.filter((template_id) =>
          templates.some(Template.byId(template_id)),
        )
        return {
          draft,
          application,
          unit,
          templates,
        }
      }
    }
    const draftCreate = LeaseDraft.getDefaultValuesFromApplication({ application, unit, owner })

    return {
      templates: [],
      draft: draftCreate,
      unit,
      application,
    }
  }

  byLease = async (
    { lease_id, renew }: Lease.Id & { renew?: boolean },
    config?: PostConfig,
  ): Promise<{
    draft: LeaseDraft | LeaseDraft.Pseudo
    minStart?: string
  }> => {
    const action = renew ? LeaseDraft.Action.RENEW : LeaseDraft.Action.REGENERATE

    let [draft, lease] = await Promise.all([
      this.byId({ lease_id, action }, config),
      leaseApi.byId(lease_id, config),
    ])
    if (!lease.unit_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    if (!lease?.unit?.owner_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT_OWNER)

    const [unit, owner] = await Promise.all([
      unitAPI.byId(lease.unit_id, config),
      ownerAPI.byId(lease.unit.owner_id, config),
    ])
    if (!unit) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    if (!owner) throw new Error(AdminLease.MSG.ERR.NO_UNIT_OWNER)

    if (
      draft &&
      draft.templates?.length &&
      draft.owner_signer_id &&
      draft.owner_id &&
      draft.action === action
    ) {
      const templates = await templateApi.listByIds(draft.templates, config)
      if (templates?.length) {
        // get rid of invalid templates
        draft.templates = draft.templates?.filter((template_id) =>
          templates.some(Template.byId(template_id)),
        )
        return {
          draft,
          minStart: renew ? toISODate(addDays(lease.end_at, 1)) ?? undefined : undefined,
        }
      }
    }
    const draftCreate = LeaseDraft.getDefaultValuesFromLease({ lease, unit, renew, owner })
    return {
      draft: draftCreate,
      minStart: renew ? toISODate(addDays(lease.end_at, 1)) ?? undefined : undefined,
    }
  }
}

export const leaseDraft = new LeaseDraftBackend()
