import { Client, GetConfig } from 'client'
import { every } from 'utils/compose'
import { User } from '../user/user'

export interface ApplicationChecklist {
  completed_at: string | null
  item_id: string
  email: string
  first_name?: string
  last_name?: string
  type: ApplicationChecklist.Type
  user_id: string
}

export namespace ApplicationChecklist {
  export type Id = Pick<ApplicationChecklist, 'item_id'>

  export const MSG = {
    LIST_EMPTY: 'No application checklist items.',
  } as const

  export const enum Type {
    cosigner = 'cosigner',
    guarantor = 'guarantor',
    credit = 'credit',
    guarantorCredit = 'guarantor_credit',
  }

  export const enum Status {
    COMPLETED = 'Completed',
    PENDING = 'Pending',
  }

  export interface Query {
    credit: boolean
  }

  const completed = (checklist: ApplicationChecklist) => !!checklist.completed_at

  export const isCompleted = (checklist: ApplicationChecklist | ApplicationChecklist[]) =>
    Array.isArray(checklist) ? checklist.every(completed) : completed(checklist)

  export const isCosigner = (checklist: ApplicationChecklist) => checklist.type === Type.cosigner

  export const isCreditCheck = (checklist: ApplicationChecklist) =>
    [Type.credit, Type.guarantorCredit].includes(checklist.type)

  export const isCreditCheckCompletedFor = (checklist: ApplicationChecklist[], user: User) =>
    checklist.some(every(User.byId(user.user_id), isCreditCheck, isCompleted))

  export const getStatus = (checklist: ApplicationChecklist) =>
    completed(checklist) ? Status.COMPLETED : Status.PENDING
}

class ApplicationChecklistBackend extends Client {
  list = async (
    query: ApplicationChecklist.Query,
    config?: GetConfig,
  ): Promise<ApplicationChecklist[]> => {
    type Result = { checklist: ApplicationChecklist[]; status: string }
    const { checklist } = await this.get<Result, ApplicationChecklist.Query>(
      '/application/checklist/get',
      query,
      config,
    )
    return checklist
  }
}

export const applicationChecklist = new ApplicationChecklistBackend()
