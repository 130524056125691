import { DeleteConfig, GetConfig, PostConfig, convertToServerData } from 'client'
import { createDiff } from 'utils/diff'
import { Order, createPaginatedList, parseOrder, parsePagination } from 'utils/list'
import { PickType } from 'utils/type-utils'
import { Property, PropertyBackend } from './property'
import { AdminUser } from '../user/user.admin'

export namespace AdminProperty {
  export type Create = Pick<
    Property,
    | 'city'
    | 'country'
    | 'description'
    | 'district'
    | 'latitude'
    | 'longitude'
    | 'name'
    | 'owner_id'
    | 'state'
    | 'street'
    | 'zip'
  >
  export type Update = Partial<Create>

  export const NUMBER_FIELDS: (keyof PickType<Create, number>)[] = ['latitude', 'longitude']
  export const STRING_FIELDS: (keyof PickType<Create, string>)[] = [
    'city',
    'country',
    'description',
    'district',
    'name',
    'owner_id',
    'state',
    'street',
    'zip',
  ]

  export const getDiff = createDiff<Property, Update>({
    date: [],
    boolean: [],
    number: NUMBER_FIELDS,
    string: STRING_FIELDS,
  })

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Property.Filter,
  ): { query: Property.Query; sort: Property.Sort | null; order: Order | null } {
    const filter: Property.Filter = {}
    const global = searchParams.get('global') as string
    if (global) filter.global = global
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Property.Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export function getFilterFor(user: AdminUser): Property.Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    if (AdminUser.isAgentNotOwnerNotAdmin(user)) return { agent_id: [user.user_id] }
    return {}
  }
}

export class AdminPropertyBackend extends PropertyBackend {
  paginatedList = createPaginatedList(this.list, this.count)

  create = async (values: AdminProperty.Create, config?: PostConfig): Promise<string> => {
    const { property_id } = await this.post<
      { values: AdminProperty.Create },
      { status: string; property_id: string }
    >('/property/new', { values }, config)
    return property_id
  }

  count = async ({ filter }: Property.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Property.Query, { count: number; status: 'success' }>(
      '/property/count',
      { filter },
      config,
    )
    return count
  }

  remove = async (pid?: string, config?: DeleteConfig): Promise<void> => {
    if (!pid) throw new Error(`Missing ${Property.singular} id`)
    await this.delete('/property/delete', {
      ...config,
      params: { ...config?.params, pid },
    })
  }

  getOne = async (
    query: Omit<Property.Query, 'order'> = {},
    config?: GetConfig,
  ): Promise<Property> => {
    const [property] = await this.list({ ...query, pagination: { page_size: 1, page: 1 } }, config)
    if (!property) throw new Error(`${Property.Singular} not found`)
    return { ...property, owner_id: property.owner?.owner_id }
  }

  update = async (
    property_id: string,
    data: Partial<AdminProperty.Create>,
    config?: PostConfig,
  ): Promise<Property> => {
    return await this.post<
      Partial<{ values: Partial<AdminProperty.Create> & Property.Id }>,
      Property
    >(
      '/property/update',
      {
        values: {
          ...convertToServerData(data, {
            string: AdminProperty.STRING_FIELDS,
            number: AdminProperty.NUMBER_FIELDS,
          }),
          property_id,
        },
      },
      config,
    )
  }

  findLocation = async ({ property_id }: Property.Id, config?: PostConfig): Promise<void> => {
    await this.post<Property.Id, { status: string }>(
      '/property/find/location',
      { property_id },
      config,
    )
  }
}

export const property = new AdminPropertyBackend()
