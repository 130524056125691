import { Client, DeleteConfig, PostConfig } from 'client'
import { isSameDayLocal } from 'utils/date'
import { getFullNameOrEmail } from 'utils/full-name'
import { ListQuery, createPaginatedList } from 'utils/list'
import { NumberQuery } from '../src/unit'
import { User, User as UserType } from '../user/user'
import { DOC, IMG, MimeType, UserFile, userFile as userFileApi } from '../user-file'

export interface Chat {
  archived_at?: string
  chat_id: string
  created_at: string
  description: string
  name: string
  users?: Chat.User[]
  last_message_id?: string
  last_message_text?: string
  last_message_by_id?: string
  last_message_at?: string
  context?: Chat.Context[]
}

export namespace Chat {
  export type Id = Pick<Chat, 'chat_id'>

  export type Sort = 'created_at' | 'name' | 'last_message_at'
  export type Query = ListQuery<
    Sort,
    {
      global?: string
      name?: string
      chat_id?: string[]
      user_id?: string[]
      context?: string[]
      context_type?: ContextType[]
      is_archived?: boolean
      unread_count?: NumberQuery
      user_count?: NumberQuery
    }
  >
  export type Filter = Query['filter']
  export type SupportCreate = {
    user_id: string
    description?: string
  }

  export interface Create {
    name: string
    description?: string
    seed?: string
    users: string[]
  }

  export interface Context {
    chat_id: string
    context: string
    context_type: ContextType
    created_at: string
  }

  export type ContextType = string | 'support'

  export type AddContext = Id & {
    context: string
    context_type?: ContextType
  }

  /** Represents chat user */
  export interface User {
    chat_id: string
    created_at: string
    disabled_at?: string
    user_id: string
    last_message_read?: string
    unread_count?: number
    user: Omit<UserType.Brief, 'user_id'>
  }

  export type MessageReaction = MessageId &
    UserType.Id & {
      created_at: string
      icon: Reaction
    }
  /** Represents chat message */
  export interface Message {
    chat_id: string
    created_at: string
    deleted_at?: string
    file_id?: string
    file?: UserFile
    edited_at?: string
    message_id: string
    message: string
    user_id: string | null
    reply_to?: Pick<
      Message,
      'chat_id' | 'created_at' | 'message' | 'message_id' | 'user_id' | 'deleted_at'
    >
    reactions?: MessageReaction[]
  }
  export type MessageId = Pick<Message, 'message_id'>
  export type MessageQuery = Query & {
    filter?: {
      message_id?: string[]
    }
  }
  export type MessageFilter = MessageQuery['filter']
  export type MessageCreate = Chat.Id & {
    message: string
    file?: File | FileList
    reply_to_id?: string
  }
  export type MessageUpdate = MessageCreate & MessageId

  export const pickMessageId = ({ message_id }: MessageId) => message_id
  export const byMessageId = (id: string) => (msg: MessageId) => pickMessageId(msg) === id

  export const TYPING_TIMEOUT = 5000
  export const NULL_USER_NAME = 'Rello Bot'

  export const enum Reaction {
    THUMBS_UP = '👍',
    CHEERS = '🥂',
    HEART = '❤️',
    LAUGH = '😂',
    SAD = '😢',
    ANGRY = '😡',
    COMPLETE = '✅',
    WAVE = '👋',
  }
  export const ReactionLabels = {
    [Reaction.THUMBS_UP]: 'Thumbs Up',
    [Reaction.CHEERS]: 'Cheers',
    [Reaction.HEART]: 'Heart',
    [Reaction.LAUGH]: 'Laugh',
    [Reaction.SAD]: 'Sad',
    [Reaction.ANGRY]: 'Angry',
    [Reaction.COMPLETE]: 'Complete',
    [Reaction.WAVE]: 'Wave',
  }
  export const ReactionsList = [
    Reaction.THUMBS_UP,
    Reaction.CHEERS,
    Reaction.HEART,
    Reaction.LAUGH,
    Reaction.SAD,
    Reaction.ANGRY,
    Reaction.COMPLETE,
    Reaction.WAVE,
  ]

  export const enum MessageActionType {
    Edit = 'edit',
    Delete = 'delete',
    Reply = 'reply',
    React = 'react',
    ReactDelete = 'delete-reaction',
    ClearContext = 'clear-context',
    OpenAttachment = 'attachment-open',
    AttachFile = 'attach-file',
    ClearAttachment = 'clear-attachment',
    Send = 'sent',
    Update = 'update',
  }

  export interface MessageActionAttachFile {
    type: MessageActionType.AttachFile
    attachment?: File
    message?: Message
  }
  interface MessageActionClearAttachment {
    type: MessageActionType.ClearAttachment
    attachment?: never
    message?: never
  }
  export interface MessageActionEdit {
    type: MessageActionType.Edit
    attachment?: File
    message: Message
  }
  interface MessageActionDelete {
    type: MessageActionType.Delete
    attachment?: never
    message: Message
  }
  export interface MessageActionReply {
    type: MessageActionType.Reply
    attachment?: File
    message: Message
  }
  interface MessageActionReact {
    type: MessageActionType.React
    attachment?: never
    message: Message
    reaction: Reaction
  }
  interface MessageActionReactDelete {
    type: MessageActionType.ReactDelete
    attachment?: never
    message: Message
    reaction: Reaction
  }
  interface MessageActionClearContext {
    type: MessageActionType.ClearContext
    attachment?: never
    message?: never
  }

  export interface MessageActionOpenAttachment {
    type: MessageActionType.OpenAttachment
    userFile: UserFile
    attachment?: never
    blob?: Blob | null
    message?: never
  }
  interface MessageActionSend {
    type: MessageActionType.Send
    data: Chat.MessageCreate
    attachment?: File
    message?: never
  }
  interface MessageActionUpdate {
    type: MessageActionType.Update
    data: Chat.MessageUpdate
    attachment?: File
    message: Message
  }

  export type MessageAction =
    | MessageActionAttachFile
    | MessageActionClearAttachment
    | MessageActionClearContext
    | MessageActionDelete
    | MessageActionEdit
    | MessageActionOpenAttachment
    | MessageActionReact
    | MessageActionReactDelete
    | MessageActionReply
    | MessageActionSend
    | MessageActionUpdate

  export const isMessageActionEdit = (action?: MessageAction): action is MessageActionEdit =>
    action?.type === MessageActionType.Edit
  export const isMessageActionDelete = (action?: MessageAction): action is MessageActionDelete =>
    action?.type === MessageActionType.Delete
  export const isMessageActionReply = (action?: MessageAction): action is MessageActionReply =>
    action?.type === MessageActionType.Reply
  export const isMessageActionReact = (action?: MessageAction): action is MessageActionReact =>
    action?.type === MessageActionType.React

  export const hasMessageReaction = (msg?: Message) => !!msg?.reactions?.length

  export const isSameDay = (msg?: Message, prev?: Message): boolean => {
    return !!msg && !!prev && isSameDayLocal(msg.created_at, prev.created_at)
  }

  export const shouldGroupWithPrev = (msg?: Message, prev?: Message): boolean => {
    if (!msg || !prev || msg.user_id !== prev.user_id) return false
    if (hasMessageReaction(prev)) return false
    return isSameDayLocal(msg.created_at, prev.created_at)
  }

  export const shouldGroupWithNext = (msg?: Message, next?: Message): boolean => {
    if (!msg || !next || msg.user_id !== next.user_id) return false
    return isSameDayLocal(msg.created_at, next.created_at)
  }

  export const isMessageVisible = (msg?: Message) =>
    !(!(msg?.message || msg?.file) && !msg?.deleted_at)
  export const isMessageReply = (msg?: Message) => !!msg?.reply_to
  export const isMessageDeleted = (msg?: Message) => !!msg?.deleted_at
  export const isMessageEdited = (msg?: Message) => !isMessageDeleted(msg) && !!msg?.deleted_at
  export const hasMessageAttachment = (msg?: Message) => !!msg?.file

  export const getLastReadMessageForUser = (chat: Chat, user: UserType.Id) =>
    chat.users?.find(UserType.byId(user.user_id))?.last_message_read

  export const hasMissingReference = (msg?: Message[], extraIds?: string[]): boolean => {
    const mentionedIds = new Set<string>(extraIds)
    const loadedIds = new Set<string>()
    msg?.forEach((message) => {
      loadedIds.add(message.message_id)
      message.reply_to && mentionedIds.add(message.reply_to.message_id)
    })
    return Array.from(mentionedIds).some((id) => !loadedIds.has(id))
  }

  export const groupUserIdsByReaction = (message: Message) => {
    const result: Partial<Record<Chat.Reaction, string[]>> = {}
    if (!message.reactions) return result
    for (const reaction of message.reactions) {
      if (!(reaction.icon in result)) result[reaction.icon] = []
      result[reaction.icon]?.push(reaction.user_id)
    }
    return result
  }

  export const isChatUserEnabled = (chatUser?: Chat.User) => !!chatUser && !chatUser.disabled_at

  export const getUser = (chat: Chat, user?: User.Id) =>
    user ? chat.users?.find(User.byId(user.user_id)) : undefined

  export const isUserEnabled = (chat: Chat, user?: User.Id) =>
    isChatUserEnabled(getUser(chat, user))

  export const hasUserReaction = (message: Message, user: UserType.Id, type: Reaction) =>
    !!message?.reactions?.some(({ icon, user_id }) => icon === type && user_id === user.user_id)

  export const pickId = ({ chat_id }: Chat) => chat_id

  export const isFileTypeAccepted = (type: string | MimeType) =>
    [...DOC, ...IMG].includes(type as MimeType)
}

export class ChatBackend<T = Chat> extends Client {
  create = async (data: Chat.Create, config?: PostConfig): Promise<string> => {
    type Res = { chat_id: string; status: string }
    const { chat_id } = await this.post<Chat.Create, Res>('/chat/new', data, config)
    return chat_id
  }
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/support/post_support_new
   * @returns chat_id
   */
  getSupportChat = async (user: User.Brief, config?: PostConfig): Promise<T | undefined> => {
    const chat_id = await this.create(
      {
        users: [user.user_id],
        name: getFullNameOrEmail(user) ?? 'Unknow User',
        description: 'Support Chat',
        seed: `${user.user_id}-support`,
      },
      config,
    )
    const chat = await this.byId(chat_id)
    return chat
  }
  /**
   * Add a chat to context
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_context_new
   */
  addContext = async (data: Chat.AddContext, config?: PostConfig): Promise<void> => {
    await this.post<Chat.AddContext, { status: string }>('/chat/context/new', data, config)
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_get
   */
  list = async (query?: Chat.Query, config?: PostConfig): Promise<T[]> => {
    const { chats } = await this.post<Chat.Query, { status: string; chats: T[] }>(
      '/chat/get',
      query,
      config,
    )
    return chats
  }
  count = async ({ filter }: Chat.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Chat.Query, { status: string; count: number }>(
      '/chat/count',
      { filter },
      config,
    )
    return count
  }
  byId = async (chat_id: string, config?: PostConfig): Promise<T | undefined> => {
    const [chat] = await this.list({ filter: { chat_id: [chat_id] } }, config)
    return chat
  }
  existById = async (chat_id: string, config?: PostConfig): Promise<boolean> => {
    const count = await this.count({ filter: { chat_id: [chat_id] } }, config)
    return count === 1
  }
  paginatedList = createPaginatedList(this.list, this.count)

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_message_send
   */
  messageCreate = async (
    { file, ..._data }: Chat.MessageCreate,
    config?: PostConfig,
  ): Promise<string> => {
    type Req = Chat.Id & { file_id?: string; message: string }
    type Res = Chat.MessageId & { status: string }
    const data = { ..._data } as Req
    if (file) {
      const formData = new FormData()
      formData.append('file', file instanceof File ? file : file[0])
      data.file_id = await userFileApi.upload(formData, config)
    }
    const { message_id } = await this.post<Req, Res>('/chat/message/send', data, config)
    return message_id
  }
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_message_edit
   */
  messageUpdate = async (
    { file, ..._data }: Chat.MessageUpdate,
    config?: PostConfig,
  ): Promise<void> => {
    type Req = Chat.Id & Chat.MessageId & { file_id?: string; message: string }
    type Res = { status: string }
    const data = { ..._data } as Req
    if (file) {
      const formData = new FormData()
      formData.append('file', file instanceof File ? file : file[0])
      data.file_id = await userFileApi.upload(formData, config)
    }
    await this.post<Chat.MessageUpdate, Res>('/chat/message/edit', data, config)
  }

  /**
   * Mark message as read
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_message_read_update
   */
  messageRead = async (data: Chat.MessageId & Chat.Id, config?: PostConfig): Promise<void> => {
    type Res = { status: string }
    await this.post<Chat.MessageId & Chat.Id, Res>('/chat/message/read/update', data, config)
  }

  messageList = async (query?: Chat.MessageQuery, config?: PostConfig): Promise<Chat.Message[]> => {
    type Res = { status: string; messages: Chat.Message[] }
    const { messages } = await this.post<Chat.MessageQuery, Res>('/chat/message/get', query, config)
    return messages
  }

  messageCount = async (
    { filter }: Chat.MessageQuery = {},
    config?: PostConfig,
  ): Promise<number> => {
    type Res = { status: string; count: number }
    const { count } = await this.post<Chat.MessageQuery, Res>(
      '/chat/message/count',
      { filter },
      config,
    )
    return count
  }
  messageById = async (message_id: string, config?: PostConfig): Promise<Chat.Message> => {
    const [message] = await this.messageList({ filter: { message_id: [message_id] } }, config)
    return message
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_message_react_new
   */
  messageReact = async (
    { message_id, reaction }: Chat.MessageId & Chat.Id & { reaction: Chat.Reaction },
    config?: DeleteConfig,
  ): Promise<void> => {
    type Req = { icon: string; message: string }
    type Res = { status: string }
    await this.post<Req, Res>(
      '/chat/message/react/new',
      { message: message_id, icon: reaction },
      config,
    )
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_message_react_delete
   */
  messageDeleteReaction = async (
    { message_id, reaction }: Chat.MessageId & Chat.Id & { reaction: Chat.Reaction },
    config?: DeleteConfig,
  ): Promise<void> => {
    type Req = { icon: string; message: string }
    type Res = { status: string }
    await this.post<Req, Res>(
      '/chat/message/react/delete',
      { message: message_id, icon: reaction },
      config,
    )
  }

  messageDelete = async (
    { chat_id: chatid, message_id: msgid }: Chat.MessageId & Chat.Id,
    config?: DeleteConfig,
  ): Promise<void> => {
    await this.delete('/chat/message/delete', { ...config, params: { msgid, chatid } })
  }

  messagePaginatedList = createPaginatedList<Chat.Message>(this.messageList, this.messageCount)

  typing = async (chat_id: string, config?: PostConfig): Promise<void> => {
    await this.put<never, { status: string }>('/chat/typing', undefined, {
      ...config,
      params: { chatid: chat_id },
    })
  }
}

export const chat = new ChatBackend()
