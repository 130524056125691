import { PostConfig, DeleteConfig } from 'client'
import { endOfDayUTC, parseDate, startOfDayUTC } from 'utils/date'
import { Order, buildListQuery, parseListURLParams } from 'utils/list'
import { Ledger as PublicLedger, LedgerBackend as PublicLedgerBackend, LedgerItem } from './ledger'
import { AdminUser } from '../user/user.admin'

export { type LedgerItem }

export namespace Ledger {
  export type Id = PublicLedger.Id
  export type Sort = PublicLedger.Sort
  export type Query = PublicLedger.Query
  export type Filter = PublicLedger.Query['filter']
  export const enum Type {
    MISC = PublicLedger.Type.MISC,
    RENT = PublicLedger.Type.RENT,
    DEPOSIT = PublicLedger.Type.DEPOSIT,
    FAILED = PublicLedger.Type.FAILED,
    REFUND = PublicLedger.Type.REFUND,
  }
  export const isFailed = PublicLedger.isFailed
  export const isPayment = PublicLedger.isPayment

  export const parseQuery = ({ searchParams }: URL, filter?: Filter): Query => {
    const params = parseListURLParams<Ledger.Sort>(searchParams, {
      sort: 'created_at',
      order: Order.desc,
    })
    const property_id = searchParams.get('property_id')?.split(',')
    const owner_id = searchParams.get('owner_id')?.split(',')
    const type_id = searchParams.get('type_id')?.split(',')
    const account_type = searchParams.get('account_type')?.split(',')
    const status = searchParams.get('status')
    const from = parseDate(searchParams.get('from'))
    const to = parseDate(searchParams.get('to'))
    const date =
      from || to
        ? {
            ...(from && { from: startOfDayUTC(from) }),
            ...(to && { to: endOfDayUTC(to) }),
          }
        : undefined

    return buildListQuery<Query>(params, {
      ...(date && { date }),
      ...(account_type?.length && { account_type }),
      ...(type_id?.length && { type_id }),
      ...(property_id?.length && { property_id }),
      ...(owner_id?.length && { owner_id }),
      ...(status === 'is_failed' && { is_failed: true }),
      ...(status === 'is_paid' && { is_paid: true }),
      ...filter,
    })
  }

  export function getFilterFor(user: AdminUser): Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    if (AdminUser.isAgentNotOwnerNotAdmin(user)) return { agent_id: [user.user_id] }
    return {}
  }

  export interface ChargeData {
    amount: number
    lease_id: string
    notes: string
    title: string
    type: Type
  }
  export interface PaymentData {
    amount: number
    lease_id: string
    notes: string
    title: string
    type: Type
    user_id: string
  }

  export interface Update {}

  export const TYPE_OPTIONS = [
    { value: Type.MISC, label: 'Misc.' },
    { value: Type.RENT, label: 'Rent' },
    { value: Type.DEPOSIT, label: 'Deposit' },
    { value: Type.REFUND, label: 'Refund' },
  ]
}

export class LedgerBackend extends PublicLedgerBackend {
  count = async ({ filter, selector }: Ledger.Query = {}, config?: PostConfig) => {
    const { count } = await this.post<Ledger.Query, { count: number; status: string }>(
      '/ledger/count',
      { filter, selector },
      config,
    )
    return count
  }

  createCharge = async (data: Ledger.ChargeData, config?: PostConfig) => {
    const { transaction_id } = await this.post<
      Ledger.ChargeData,
      { status: string; transaction_id: string }
    >('/ledger/charge/new', data, config)
    return transaction_id
  }

  createPayment = async (data: Ledger.PaymentData, config?: PostConfig) => {
    const { transaction_id } = await this.post<
      Ledger.ChargeData,
      { status: string; transaction_id: string }
    >('/ledger/payment/new', data, config)
    return transaction_id
  }

  update = async (transaction_id: string, data: Ledger.Update, config?: PostConfig) => {
    await this.post<Ledger.Update & Ledger.Id, any>(
      '/ledger/update',
      { ...data, transaction_id },
      config,
    )
  }

  remove = async ({ transaction_id }: Ledger.Id, config?: DeleteConfig) => {
    await this.delete('/ledger/delete', { ...config, params: { t: transaction_id } })
  }
}

export const ledger = new LedgerBackend()
