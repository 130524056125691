import { LeaseDraft } from 'api/src/lease-draft.admin'
import { Token } from 'api/template'
import { IconBack } from 'icons'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button, SubmitButton } from 'ui'
import { cn } from 'utils'
import { pluralize } from 'utils/pluralize'
import styles from './lease-builder.module.scss'
import { useTemplateScope } from './scope'

interface Props {
  submitLabel: string
  className?: string
  fields: Token[]
  showSave?: boolean
}
export const LeaseBuilderActions: FC<Props> = ({ className, submitLabel, fields, showSave }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [activeTokenId, setActiveTokenId] = useState<string>()
  const scope = useTemplateScope()
  const enabledFields = fields.filter((token) => {
    if (Token.isReadOnly(token)) return false
    const conditions = Token.getConditions(token)
    if (!conditions) return true
    const props = Token.evaluateConditions(conditions, scope)
    if (!props) return false
    return !props.hidden
  })

  const isLast = !!activeTokenId && enabledFields.at(-1)?.token_id === activeTokenId
  const isFirst = !activeTokenId || enabledFields[0]?.token_id === activeTokenId

  useEffect(() => {
    const element = ref.current?.closest('form')
    if (!element) return
    const listener = (event: FocusEvent) => {
      const name = getTokenId(event.target as HTMLElement)
      if (name) setActiveTokenId(name)
    }
    element.addEventListener('focusin', listener)
    return () => element.removeEventListener('focusin', listener)
  }, [])

  const next = useCallback(
    (inc = 1) => {
      const element = ref.current?.closest('form')
      if (!element) return

      let index = activeTokenId ? enabledFields.findIndex(Token.byId(activeTokenId)) : -1
      let next: HTMLElement | undefined
      do {
        index += inc
        if (index < 0 || index >= enabledFields.length) return
        let token_id = enabledFields[index]?.token_id
        if (!token_id) return
        next = findElementByTokenId(token_id, element)
      } while (!next)
      next.scrollIntoView({ behavior: 'smooth', block: 'center' })
      next.focus({ preventScroll: true })
    },
    [activeTokenId, enabledFields],
  )

  return (
    <div className={cn(styles.actions, className)} ref={ref}>
      {enabledFields.length > 0 && (
        <>
          <p className={styles.info}>Please fill out {pluralize(enabledFields.length, 'field')}</p>
          <Button
            disabled={!!isFirst}
            onClick={() => next(-1)}
            theme="secondary"
            className={styles.prev}
          >
            <IconBack />
          </Button>
          <Button disabled={!!isLast} onClick={() => next(1)} theme="secondary">
            Next
          </Button>
        </>
      )}
      {showSave && (
        <SubmitButton
          disableInvalid
          className={styles.save}
          theme="secondary"
          name="action"
          value="save"
        >
          {LeaseDraft.MSG.ACTION.SAVE}
        </SubmitButton>
      )}
      <SubmitButton disableInvalid className={styles.submit}>
        {submitLabel}
      </SubmitButton>
    </div>
  )
}

const getTokenId = (element: HTMLElement) =>
  element && ['INPUT', 'SELECT', 'BUTTON'].includes(element.tagName) && element.hasAttribute('name')
    ? element.getAttribute('name')
    : undefined

const findElementByTokenId = (next?: string, container?: HTMLElement | null) => {
  if (!next || !container) return undefined
  return container.querySelector(`[name="${next}"]:is(input,select,button):not([readonly])`) as
    | HTMLSelectElement
    | HTMLInputElement
    | HTMLButtonElement
    | undefined
}
