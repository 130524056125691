export interface AccountScore {
  account_id: string
  user_id: string
  balance?: number
  created_at: string
  months_count: number
  days_count: number
  score?: number
  liquid_average?: number
  liquid_current?: number
  net_income?: number
  past_rent?: number
  data: {
    behavioral: AccountScore.Behavior
    monthly: AccountScore.MonthData[]
  }
  models_scores: object
  transactions: object
}

export namespace AccountScore {
  export type Id = Pick<AccountScore, 'account_id'>

  export interface MonthData {
    balance_post: number
    credit: number
    date_id: string // '202309'
    date: string
    debit: number
    free: number
    income: number
    rent_ai: number
    rent: number
    score: number
    weight: number
  }

  export interface MonthChartData extends MonthData {
    finalRent: number
    time: number
  }

  export interface Behavior {
    balance_management_score: number
    balances: number[]
    current_balance: number
    income: number[]
    income_avg: number
    income_median: number
    income_score: number
    offset: number
    overdraft_months: number
    overdraft_score: number
    r2: number
    rent_avg: number
    rent_median: number
    rent_payments: number[]
    rent_score_by_avg: number
    rent_score_by_median: number
    saving_score: number
    slope: number
    total_behavioral_score: number
    weights: number[]
  }

  const toChart = (item: MonthData) => {
    const year = parseInt(item.date_id.slice(0, 4), 10)
    const month = parseInt(item.date_id.slice(4), 10)
    const date = new Date(year, month - 1, 1, 0, 0, 0, 0)
    return {
      ...item,
      finalRent: Math.abs(Math.min(item.rent, item.rent_ai)),
      date: date.toISOString(),
      time: date.getTime() ?? 0,
    }
  }

  export const toChartData = (data?: MonthData[]) =>
    data?.map(toChart).sort((a, b) => a.time - b.time) ?? []
}
