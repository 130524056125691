export interface ScoreWarnings {
  large_transfers?: boolean
  no_income_detected?: boolean
  no_score?: boolean
  not_enough_history?: boolean
  identity_match?: boolean
}

export interface UserScoreWarning {
  code: keyof ScoreWarnings
  title: string
  description: string
}

export namespace ScoreWarnings {
  export type Type = keyof ScoreWarnings & string

  export const TYPES = [
    'large_transfers',
    'no_income_detected',
    'no_score',
    'not_enough_history',
    'identity_match',
  ] as const

  export const codeToUserWarning = (code: string): UserScoreWarning | undefined => {
    switch (code) {
      case 'identity_match':
        return {
          code,
          title: 'Identity Match Failed',
          description: `We couldn't match the name on your profile with the owner's name on one of the bank 
        accounts you connected. Please try removing and adding a different account.`,
        }
      case 'no_income_detected':
        return {
          code,
          title: 'No Income Source Detected',
          description: `We couldn't detect any active source of income in the accounts you connected. 
            To improve your score, please connect an account showing active income or upload 
            documents as proof of income.`,
        }
      case 'large_transfers':
        return {
          code,
          title: 'Large Fund Transfers Identified',
          description: `Large fund transfers from your account have impacted your score. This is 
            common with transfers to savings or investment accounts. Connecting these accounts can help 
            mitigate any negative effects on your score.`,
        }
      case 'not_enough_history':
        return {
          code,
          title: 'Insufficient Account History',
          description: `The history in your oldest account is less than
            three months old, which is insufficient for auto pre-qualification. Please connect
            another account with a longer history, upload documents showing proof of income, or
            consider adding a guarantor.`,
        }
      case 'no_score':
        return {
          code,
          title: 'No Pre-Qualification Score',
          description: `We couldn't generate a pre-qualification score based on the accounts you connected.`,
        }
    }
  }

  export const toUserWarnings = (warnings: ScoreWarnings): UserScoreWarning[] => {
    return Object.keys(warnings)
      .map((type) => codeToUserWarning(type as Type))
      .filter(Boolean) as UserScoreWarning[]
  }
}
