import { Client } from 'client'

export interface NewMessage {
  email: string
  name: string
  message: string
  type?: string
}

export class ContactBackend extends Client {
  create = (data: NewMessage) => {
    return this.post<NewMessage, any>('/contactus/new', data)
  }
}

export const contact = new ContactBackend()
