import { UserSignature } from 'api/user-signature'
import vars from './const.module.scss'

const signature = {
  height: Number(vars.signatureHeight),
  width: Number(vars.signatureWidth),
} as const
const initials = {
  height: Number(vars.initialsHeight),
  width: Number(vars.initialsWidth),
} as const
export const SIGNATURE_SIZE = {
  [UserSignature.Type.SIGNATURE]: signature,
  [UserSignature.Type.WET]: signature,
  [UserSignature.Type.INITIALS]: initials,
}
