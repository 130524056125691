import { equalEmails } from 'utils/email'
import { Cosigner } from './co-signers'
import { User } from '../user/user'

export interface Guarantor {
  guarantee_id: string
  email: string
  accepted_at?: string | null
  declined_at?: string | null
  created_at: string
  guarantor_id: string
  first_name?: string
  last_name?: string
}

export namespace Guarantor {
  export type IdField = 'guarantor_id'
  export interface Id {
    guarantor_id: string
  }
  export const Singular = 'Guarantor'
  export const Plural = 'Guarantors'
  export const MSG = {
    ACTION: {
      EDIT: 'Edit Guarantor',
      REMOVE: 'Remove Guarantor',
    },
    ERR: {
      NO_ID: 'Missing guarantor_id.',
      NOT_FOUND: 'Guarantor not found.',
      REF_SELF: 'You cannot add yourself as a guarantor.',
      REF_COSIGNER: 'This user is already a cosigner.',
      REF_GUARANTOR: 'This user is already a guarantor.',
    },
    LIST_EMPTY: 'No guarantors found.',
  } as const

  export interface Data {
    guarantor_email: string
    guarantor_phone_number: string
  }
  export function createNewGuarantorValidator({
    user,
    cosigners,
  }: {
    user: User
    cosigners?: Cosigner[]
  }) {
    return (email: string | null) => {
      if (!email) return null
      if (equalEmails(email, user.email)) return MSG.ERR.REF_SELF
      if (cosigners?.some((cosigner) => equalEmails(cosigner.user.email, email)))
        return MSG.ERR.REF_COSIGNER
      if (user.guarantors?.some(({ guarantor }) => equalEmails(guarantor?.email, email)))
        return MSG.ERR.REF_GUARANTOR
      return null
    }
  }
  export const byId = (id: string) => (guarantor: Pick<Guarantor, IdField>) =>
    guarantor.guarantor_id === id
}
