import { Client, PostConfig } from 'client'
import { Lease } from './lease'
import { LeaseDraft } from './lease-draft.admin'
import { Token } from '../template/token.admin'

export interface LeasePreview {
  draft_id: string
  snapshot_id: string
  fields: Token[]
  vars: Record<string, any>
  admin_values: Record<string, any>
}

export namespace LeasePreview {
  export const MSG = {
    ERR: {
      NO_ID: 'Missing lease_id/draft_id.',
      DOWNDLOAD_FAILED: 'Failed to load PDF.',
    },
  }

  export type SaveData = LeaseDraft.Id & { values: Record<string, string> }
}

export class LeasePreviewBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/lease/post_lease_preview
   */
  preview = async (data: Lease.Id | { draft_id: string }, config?: PostConfig) => {
    if (!(data as { draft_id: string }).draft_id && !(data as Lease.Id).lease_id)
      throw new Error(LeasePreview.MSG.ERR.NO_ID)
    type Req = Lease.Id | { draft_id: string }
    type Res = { preview: LeasePreview; status: string }
    const { preview } = await this.post<Req, Res>('/lease/preview', data, config)
    const fields = preview.fields.sort(Token.byPosition)
    return { ...preview, fields }
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_snapshot_download */
  downloadSnapshotById = async (sid: string, config?: PostConfig) => {
    const blob = await this.get<Blob, { sid: string }>(
      '/lease/snapshot/download',
      { sid },
      { responseType: 'blob', ...config },
    )
    if (!blob) throw new Error(LeasePreview.MSG.ERR.DOWNDLOAD_FAILED)
    return blob
  }

  previewWithSnapshot = async (data: Lease.Id | { draft_id: string }, config?: PostConfig) => {
    const preview = await this.preview(data, config)
    const pdf = await this.downloadSnapshotById(preview.snapshot_id, config)
    return { pdf, preview }
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/post_lease_preview_exit */
  exit = async (data: Lease.Id, config?: PostConfig) => {
    await this.post<Lease.Id, { status: string }>('/lease/preview/exit', data, config)
  }

  save = async (data: LeasePreview.SaveData, config?: PostConfig): Promise<void> => {
    await this.post<LeasePreview.SaveData, { status: string }>('/lease/preview/save', data, config)
  }
}

export const leasePreview = new LeasePreviewBackend()
