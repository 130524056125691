import { Client, GetConfig, PostConfig } from 'client'
import { parseDate } from 'utils/date'
import { Lease } from './lease'

export interface Transaction {
  created_at: string
  lease_id: string
  lease_payment_id: string
  paid: number
  paid_at?: string
  failed_at?: string
  period_id: string
  transaction_id: string
  user_id: string
}

export namespace Transaction {
  export type Id = Pick<Transaction, 'transaction_id'>

  export type WithLease = Transaction & { lease: Lease }

  export type Cancel = Id & { lease_payment_id: string }

  export const enum PaymentMethod {
    card = 'card',
    bank = 'bank',
    check = 'check',
  }

  export type Create = Pick<Transaction, 'lease_payment_id' | 'paid' | 'user_id'> & {
    payment_method: PaymentMethod
    reference?: string
  }

  export const isSuccess = (transaction: Transaction) => !!transaction.paid_at

  const pickTransactionDateValue = (transaction: Transaction) =>
    parseDate(transaction.paid_at ?? transaction.failed_at ?? transaction.created_at)?.valueOf() ??
    0

  export const byDate = (a: Transaction, b: Transaction) =>
    pickTransactionDateValue(b) - pickTransactionDateValue(a)

  export const sum = (transactions: Transaction[]) =>
    transactions.reduce((sum, transaction) => sum + (transaction.paid ?? 0), 0)
}

class TransactionsBackend extends Client {
  byId = async (id: string, config?: GetConfig): Promise<Transaction> => {
    const { transaction } = await this.get<
      { transaction: Transaction; status: string },
      { tid: string }
    >('/lease/payment/transaction/get', { tid: id }, config)
    if (!transaction) throw new Error(`Transaction not found (${id})`)
    return transaction
  }

  cancel = async (data: Transaction.Cancel, config?: PostConfig): Promise<void> => {
    await this.post<Transaction.Cancel, any>(
      '/lease/payments/checkout/session/cancel',
      data,
      config,
    )
  }

  create = async (data: Transaction.Create, config?: PostConfig): Promise<void> => {
    await this.post('/lease/user/payment/new', data, config)
  }
}

export const transactions = new TransactionsBackend()
