import { SignatureDraw } from '@rello/signature'
import { Token } from 'api/template'
import { UserSignature } from 'api/user-signature'
import { IconFieldSignature } from 'icons'
import { FC, useCallback, useState } from 'react'
import { Button, PureDialog, useField, useFieldControl } from 'ui'
import { cn } from 'utils'
import styles from './signature-field.module.scss'

type Props = {
  className?: string
  token: Token.Signature
  onUpdate: ReturnType<typeof useFieldControl<string | null>>[1]
  touched?: boolean
  id: string
  name: string
  disabled?: boolean
}

export const SignatureWetField: FC<Props> = ({ className, token, onUpdate, touched, ...props }) => {
  const state = useField(token.token_id)
  const [open, setOpen] = useState(false)
  const [signatureImage, setSignatureImage] = useState<string>()

  const onSuccess = useCallback(
    (data: { snapshot_id?: string }) => {
      if (!data.snapshot_id) throw new Error('Snapshot ID not found.')
      setOpen(false)
      onUpdate({
        valid: true,
        touched: true,
        value: data.snapshot_id,
        validationMessage: null,
      })
    },
    [onUpdate],
  )
  const checked = !!state?.value

  return (
    <>
      <Button
        theme="none"
        {...props}
        onClick={() => setOpen(true)}
        onBlur={() => onUpdate({ touched: true })}
        aria-required={token.required}
        className={cn(
          styles.button,
          className,
          state?.touched && state?.validationMessage && styles.invalid,
          checked && styles.checked,
        )}
        name={token.token_id}
      >
        {signatureImage ? (
          <img className={styles.img} src={signatureImage} alt="signature" />
        ) : (
          <>
            <IconFieldSignature />
            <span>Sign</span>
          </>
        )}
      </Button>
      {open && (
        <PureDialog className={styles.dialog} onClose={() => setOpen(false)}>
          <header>
            <h1 className={styles.title}>Wet Signature Required</h1>
          </header>
          <SignatureDraw
            onSuccess={onSuccess}
            type={UserSignature.Type.WET}
            onBeforeUpload={(blob) => setSignatureImage(URL.createObjectURL(blob))}
            submitLabel={UserSignature.MSG.ACTION.ACCEPT}
          />
        </PureDialog>
      )}
    </>
  )
}
