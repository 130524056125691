import { SignaturePersistentDialog } from '@rello/signature'
import { UserSignature } from 'api/user-signature'
import { createContext, FC, ReactNode, useCallback, useContext, useRef, useState } from 'react'

type Signature = { blob: Blob; snapshot_id: string }
type SignatureMap = Partial<Record<UserSignature.PersistentType, Signature>>

const SignatureContext = createContext<
  [
    SignatureMap,
    (type: UserSignature.PersistentType) => Promise<{ snapshot_id: string; blob: Blob }>,
  ]
>([
  {},
  () => {
    throw new Error('SignatureContext not provided')
  },
])

interface Props {
  children: ReactNode | ReactNode[]
  value?: SignatureMap
}
export const SignatureContextProvider: FC<Props> = ({ value, children }) => {
  const [state, setState] = useState<SignatureMap>(value ?? {})
  const [dialog, setDialog] = useState<UserSignature.PersistentType>()

  const promiseRef = useRef<PromiseWithResolvers<{ snapshot_id: string; blob: Blob }>>()

  const update = useCallback((type: UserSignature.PersistentType, value: Signature) => {
    setState((prev) => ({ ...prev, [type]: value }))
    promiseRef.current?.resolve(value)
    setDialog(undefined)
  }, [])

  const showDialog = useCallback(async (type: UserSignature.PersistentType) => {
    const { promise, reject, resolve } = Promise.withResolvers<{
      snapshot_id: string
      blob: Blob
    }>()
    promiseRef.current = { promise, reject, resolve }
    setDialog(type)
    return promise
  }, [])

  return (
    <>
      <SignatureContext.Provider value={[state, showDialog]}>{children}</SignatureContext.Provider>
      {dialog && (
        <SignaturePersistentDialog
          type={dialog}
          onClose={() => {
            setDialog(undefined)
            promiseRef.current?.reject(new Error('cancelled'))
          }}
          onSuccess={(data) => update(dialog, data)}
        />
      )}
    </>
  )
}

export const useSignatureContext = () => {
  return useContext(SignatureContext)
}
