import { Client, PostConfig } from 'client'
import { ListQuery } from 'utils/list'
import { Application } from './application'
import { User } from '../user/user'

export interface Bid {
  application_id: string
  auction_id: string
  bid: number
  bid_id: string
  canceled_at?: string
  canceled_by_id?: string
  created_at: string
  rent_now?: boolean
  user_id: string
  unit_id?: string
}

export namespace Bid {
  export type Id = Pick<Bid, 'bid_id'>

  export interface WithUser extends Bid {
    user: User.Brief
  }
  export interface WithOutdate extends WithUser {
    is_outdated: boolean
  }

  export type Sort = 'created_at' | 'bid'

  export type Query = ListQuery<
    Sort,
    {
      application_id?: string[]
      auction_id?: string[]
      /** @default true */
      include_canceled?: boolean
      /** @deprecated use ranked_above_without_rent_now */
      only_winner?: boolean
      rent_now?: boolean
      unit_id?: string[]
      user_id?: string[]
      global_user_id?: string[]
      ranked_above_without_rent_now?: number
      bid_price?: `${'>' | '<' | '>=' | '<='}${number}`
    }
  >

  export type Filter = Query['filter']

  export type Create = Application.Id & Pick<Bid, 'bid' | 'rent_now'>

  export const withOutdate = (bids: WithUser[]): WithOutdate[] => {
    const applications = new Set<string>()
    return bids.map((bid) => {
      const is_outdated = applications.has(bid.application_id)
      applications.add(bid.application_id)
      return { ...bid, is_outdated }
    })
  }

  export const isCanceled = (bid: Bid): boolean => !!bid.canceled_at
  export const canCreateLease = (bid: WithOutdate): boolean => !bid.canceled_at && !bid.is_outdated

  export const canCancel = (bid: WithOutdate): boolean =>
    !bid.canceled_at && !bid.is_outdated && !bid.rent_now
}

class BidBackend extends Client {
  /** @returns bid_id (async) */
  create = async (data: Bid.Create, config?: PostConfig): Promise<string> => {
    if (!data) throw new Error('Missing data')
    type Result = { bid_id: string; status: string }
    const { bid_id } = await this.post<Bid.Create, Result>(
      '/user/application/bid/new',
      data,
      config,
    )
    return bid_id
  }

  cancel = async ({ application_id }: Application.Id, config?: PostConfig): Promise<void> => {
    await this.post('/user/application/bid/cancel', { application_id }, config)
  }

  list = async (query?: Bid.Query, config?: PostConfig): Promise<Bid.WithUser[]> => {
    const { bids } = await this.post<Bid.Query, { status: string; bids: Bid.WithUser[] }>(
      '/auction/bid/get',
      query,
      config,
    )
    return bids
  }

  getLatest = async (filter?: Bid.Filter, config?: PostConfig): Promise<Bid | null> => {
    const [bid] = await this.list(
      {
        filter,
        pagination: { page_size: 1, page: 1 },
        order: [{ name: 'created_at', desc: true }],
      },
      config,
    )
    return bid ?? null
  }

  count = async ({ filter }: Bid.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Bid.Query, { status: string; count: number }>(
      '/auction/bid/count',
      { filter },
      config,
    )
    return count
  }
}

export const bid = new BidBackend()
