import { Client, GetConfig } from 'client'

export interface Invitation {
  invite_id: string
  created_at: string
  email: string
  hash: string
}

export const INVITATION_UNIT_KEY = 'invite_unit_id'
export const INVITATION_HASH_PARAM = 'invite_hash'
export const INVITATION_ID_PARAM = 'invite'

export class InvitationBackend extends Client {
  getMy = async (config?: GetConfig) => {
    try {
      const invitation = await this.get<Invitation>(`/invitation/get`, undefined, config)
      return invitation
    } catch (error) {
      return null
    }
  }
}

export const invitation = new InvitationBackend()
