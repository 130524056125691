import { Token } from 'api/template'
import { FC, useState } from 'react'
import { cn } from 'utils'
import { PageFieldCheckbox } from './page-field-checkbox'
import { PageFieldDate } from './page-field-date'
import { PageFieldNumber } from './page-field-number'
import { PageFieldRadios } from './page-field-radio'
import { PageFieldText } from './page-field-text'
import styles from './page-field.module.scss'
import { useTemplateScope } from './scope'
import { SignatureField } from './signature-field'
import { getPageItemStyle } from '../util'

interface Props {
  token: Token
  zoom: number
  hasUserData?: boolean
}
export const PageField: FC<Props> = ({ token, zoom, hasUserData }) => {
  const [conditions] = useState(Token.getConditions(token))
  const scope = useTemplateScope()
  const props = Token.evaluateConditions(conditions, scope)

  if (!props) {
    // error in formulas
    return null
  }
  if (props.hidden) return null

  return Token.isRadioToken(token) ? (
    <PageFieldRadios
      token={token}
      props={props}
      zoom={zoom}
      labelClassName={styles.pagefield}
      errorClassName={styles.error}
      hasUserData={hasUserData}
    />
  ) : (
    <div
      className={styles.wrapper}
      data-role={token.role.toLowerCase()}
      style={getPageItemStyle(token, zoom)}
    >
      {getSingleElement({ token, props, hasUserData })}
    </div>
  )
}

function getSingleElement({
  token,
  props,
  hasUserData,
}: {
  token: Token
  props?: Token.ConditionalProps
  hasUserData?: boolean
}) {
  switch (token.type) {
    case Token.Type.TEXT:
      return <PageFieldText token={token} props={props} hasUserData={hasUserData} />
    case Token.Type.NUMBER:
      return <PageFieldNumber token={token} props={props} hasUserData={hasUserData} />
    case Token.Type.DATE:
      return <PageFieldDate token={token} props={props} hasUserData={hasUserData} />
    case Token.Type.CHECKBOX:
      return <PageFieldCheckbox token={token} props={props} hasUserData={hasUserData} />
    case Token.Type.SIGNATURE:
      return (
        <SignatureField
          token={token as Token.Signature}
          className={cn(styles.pagefield, styles.signature)}
        />
      )
  }
}
