import { Client, GetConfig, PostConfig, convertToServerData } from 'client'
import { ListQuery, Order, createPaginatedList, parseOrder, parsePagination } from 'utils/list'
import { Address } from './address'
import { PropertyManagerPaymentAccount } from './property-manager-payment-account.admin'
import { AdminUser } from '../user/user.admin'

export interface PropertyManager {
  address: Address
  allow_nonqualified_applications?: boolean
  bank_accounts: PropertyManagerPaymentAccount[]
  created_at: string
  credit_check_required?: boolean
  ein: string
  email: string
  lease_signer_user_id?: string
  name: string
  phone_number: string
  property_manager_id: string
}

export namespace PropertyManager {
  export const singular = 'property manager' as const
  export const Singular = 'Property Manager' as const
  export const plural = 'property managers' as const
  export const Plural = 'Property Managers' as const

  export type IdField = 'property_manager_id'
  export type Id = Pick<PropertyManager, IdField>

  export type Create = Pick<
    PropertyManager,
    | 'address'
    | 'credit_check_required'
    | 'ein'
    | 'email'
    | 'lease_signer_user_id'
    | 'name'
    | 'phone_number'
  >
  export type Update = Partial<Create>

  export type Sort = 'created_at' | 'ein' | 'email' | 'name' | 'phone_number'
  export type Query = ListQuery<
    Sort,
    {
      property_manager_id?: string[]
      user_id?: string[]
      agent_id?: string[]
    }
  >
  export type Filter = Query['filter']

  export type UserRelation = Id &
    AdminUser.Id & {
      is_admin?: boolean
      owners?: string[]
    }

  export type User = AdminUser.Brief & { is_admin: boolean; owners?: string[] }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Query['filter'],
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Query['filter'] = {}
    const global = searchParams.get('global') as string
    if (global) filter.global = global
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export function getFilterFor(user: AdminUser): Filter {
    if (AdminUser.hasRoleOwner(user)) return { user_id: [user.user_id] }
    if (AdminUser.isAgentNotOwnerNotAdmin(user)) return { agent_id: [user.user_id] }
    return {}
  }

  export const byId = (property_manager_id: string) => (item: Id) =>
    item.property_manager_id === property_manager_id
  export const pickId = ({ property_manager_id }: Id) => property_manager_id
}

class PropertyManagerBackend extends Client {
  create = async (data: PropertyManager.Create, config?: PostConfig): Promise<PropertyManager> => {
    type Res = { property_manager: PropertyManager; status: string }
    const { property_manager } = await this.post<PropertyManager.Create, Res>(
      '/property-manager/new',
      data,
      config,
    )
    return property_manager
  }

  update = async (
    property_manager_id: string,
    updates: PropertyManager.Update,
    config?: PostConfig,
  ): Promise<PropertyManager> => {
    type Req = { updates: PropertyManager.Update; property_manager_id: string }
    type Res = { property_manager: PropertyManager; status: string }
    const { property_manager } = await this.post<Req, Res>(
      '/property-manager/update',
      {
        property_manager_id,
        updates: toServerData(updates),
      },
      config,
    )
    return property_manager
  }

  list = async (
    query: PropertyManager.Query = {},
    config?: PostConfig,
  ): Promise<PropertyManager[]> => {
    type Res = { property_managers: PropertyManager[]; status: string }
    const { property_managers } = await this.post<PropertyManager.Query, Res>(
      '/property-manager/get',
      query,
      config,
    )
    return property_managers
  }

  count = async (query: PropertyManager.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<PropertyManager.Query, { count: number; status: string }>(
      '/property-manager/count',
      query,
      config,
    )
    return count
  }

  paginatedList = createPaginatedList(this.list, this.count)

  byId = async (id: string, config?: PostConfig): Promise<PropertyManager> => {
    const property_managers = await this.list({ filter: { property_manager_id: [id] } }, config)
    if (!property_managers[0])
      throw new Error(`${PropertyManager.Singular} with id ${id} not found`)
    return property_managers[0]
  }

  addUser = async (data: PropertyManager.UserRelation, config?: PostConfig): Promise<void> => {
    await this.post<PropertyManager.UserRelation, { status: string }>(
      '/property-manager/user/add',
      data,
      config,
    )
  }
  removeUser = async (data: PropertyManager.UserRelation, config?: PostConfig): Promise<void> => {
    await this.post<PropertyManager.UserRelation, { status: string }>(
      '/property-manager/user/remove',
      data,
      config,
    )
  }
  listUserById = async (id: string, config?: GetConfig): Promise<PropertyManager.User[]> => {
    type Params = { id: string }
    type Res = {
      users: { is_admin: boolean; user: AdminUser.Brief; owners?: string[] }[]
      status: string
    }
    const { users } = await this.get<Res, Params>('/property-manager/user/get', { id }, config)
    return users.map(({ is_admin, user, owners }) => ({ ...user, owners, is_admin }))
  }
}

export const propertyManager = new PropertyManagerBackend()

function toServerData({
  address,
  ...updates
}: Partial<PropertyManager.Create>): Partial<PropertyManager.Create> {
  return {
    ...convertToServerData(updates, {
      string: ['ein', 'email', 'name', 'phone_number'],
    }),
    ...(address && {
      address: convertToServerData(address, {
        string: ['city', 'state', 'street', 'zip'],
      }),
    }),
  }
}
