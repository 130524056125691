import { Client, GetConfig, PostConfig } from 'client'
import { every, not, pickAll } from 'utils/compose'
import {
  formatDateTime,
  formatUTCDateTimeAsLocal,
  isDateFuture,
  isDatePast,
  parseDate,
  toServerDate,
} from 'utils/date'
import { ListQuery } from 'utils/list'
import { AutoPaymentSettings } from './auto-payment-settings'
import { LeaseChecklist, leaseChecklist } from './lease-checklist'
import { LedgerItem } from './ledger'
import { OwnerPaymentAccountType } from './owner-payment-account-type'
import { PaymentMethod } from './payment-method'
import { Unit } from './unit'
import { User } from '../user/user'

export interface Lease {
  application_id: string
  /*  sum of both balances for lease **ops** and **deposit** */
  balance?: number
  /** total balance for lease ops */
  balance_ops?: number
  /** total balance for lease deposit */
  balance_deposit?: number
  checklist_completed_at?: string
  checklist_locked_at?: string
  created_at: string
  deposit?: number
  end_at: string
  lease_guarantors?: Lease.Guarantor[]
  lease_id: string
  /** when a lease is renewed, a new lease is created. They share a common `lease_renewal_id` value */
  lease_renewal_id?: string
  lease_user_auto_payment_settings?: AutoPaymentSettings[]
  lease_users?: Lease.Tenant[]
  monthly_rent?: number
  /** next lease id (in terms of renewal) */
  next_lease_id?: string
  /** previous lease id (in terms of renewal) */
  prev_lease_id?: string
  start_at: string
  templates?: string[]
  unit_id?: string
  unit?: Unit
}

export const enum Severity {
  error = 'error',
  warning = 'warning',
}

export type Badge = {
  amount: number
  severnity?: Severity
}

export namespace Lease {
  export type IdField = 'lease_id'
  export type Id = Pick<Lease, IdField>
  export type Sort = 'created_at' | 'end_at' | 'start_at'
  export type Query = ListQuery<
    Sort,
    {
      application_id?: string[]
      guarantor_id?: string[]
      /** checklist_completed_at is not blank */
      is_completed?: boolean
      lease_id?: string[]
      owner_id?: string[]
      owner_user_id?: string[]
      property_id?: string[]
      unit_id?: string[]
      user_id?: string[]
      agent_id?: string[]
    }
  > & {
    filter?: {
      checklist_completed_at?: {
        from?: string
        to?: string
      }
      lease_renewal_id?: string[]
    }
  }
  export type Filter = Query['filter']

  export const MSG = {
    ERR: {
      NO_ID: 'Missing lease_id.',
      NOT_FOUND: 'Lease not found.',
      CONTRACT_NOT_FOUND: 'Lease cotract not found.',
      CONTRACT_NOT_PDF: 'Lease cotract must be PDF.',
      NO_UNIT: `${Unit.singular} not found.`,
      NO_UNIT_OWNER: `${Unit.Singular}'s owner not found.`,
    },
  }

  export interface Checkout {
    amount: number
    context: any
    lease_id: string
    payment_method: PaymentMethod
    title: string
    account_type?: OwnerPaymentAccountType
  }

  export interface FinixPaymentData {
    account_type?: OwnerPaymentAccountType
    amount: number
    fee: number
    finix_token: string
    fraud_session_id: string
    idempotency_id: string
    lease_id: string
    payment_method: string
    title: string
  }

  export type Sign = Id & { values: Record<string, string> }

  export type LeaseUser = { user_id: string; first_name: string; last_name: string; email: string }
  export type Tenant = {
    user: Omit<LeaseUser, 'user_id'>
    created_at: string
    lease_id: string
    user_id: string
  }
  export type Guarantor = {
    created_at: string
    lease_id: string
    guarantee_id: string
    guarantor_id: string
    email: string
    first_name: string
    last_name: string
  }

  export const enum Status {
    /** not yet started, renewal, checklist completed */
    Upcoming = 'upcoming',
    /** ended, checklist completed */
    Ended = 'ended',
    /** started yet not ended, checklist completed */
    Active = 'active',
    /** not started */
    Pending = 'pending',
    Locked = 'locked',
  }

  export const STATUS = {
    [Status.Active]: 'Active',
    [Status.Pending]: 'Pending',
    [Status.Ended]: 'Ended',
    [Status.Upcoming]: 'Upcoming',
    [Status.Locked]: 'Locked',
  }

  export interface Readiness {
    debug: string
    is_ready: boolean
    message: string
    reason: string
  }

  export type WithChecklist = Lease & {
    checklist: LeaseChecklist[]
  }

  export const toArray = (value?: null | Lease | Lease[]): Lease[] =>
    !value ? [] : Array.isArray(value) ? value : [value]

  export const isPast = (lease: Lease) => (lease.end_at ? !!isDatePast(lease.end_at) : false)
  export const isFuture = (lease: Lease) => isDateFuture(lease.start_at)
  export const isCurrent = (lease: Lease) => !isPast(lease) && !isFuture(lease)
  export const isChecklistCompleted = (lease: Lease) => !!lease.checklist_completed_at
  export const isChecklistLocked = (lease: Lease) => !!lease.checklist_locked_at
  export const hasNext = (lease: Lease) => !!lease.next_lease_id
  export const hasPrev = (lease: Lease) => !!lease.prev_lease_id

  /** not locked (may be active/pending/expired) */
  export const isNotLocked = not(isChecklistLocked)

  export const getStatus = (lease: Lease): Status => {
    if (isChecklistLocked(lease)) return Status.Locked
    if (isChecklistCompleted(lease)) {
      if (isPast(lease)) return Status.Ended
      if (isFuture(lease)) return Status.Upcoming
      return Status.Active
    } else {
      return Status.Pending
    }
  }

  /**
   * NOT LOCKED, NOT EXPIRED.
   * Used to determine if a user should see it as a home page
   */
  export const isOK = every(not(isChecklistLocked), not(isPast))

  export const getAutoPaymentSettingFor = (
    lease: Lease | Lease[],
    user: User.Id,
  ): AutoPaymentSettings | null => {
    if (!lease || !user) return null
    const leases = toArray(lease)
    for (const lease of leases) {
      const setting = lease?.lease_user_auto_payment_settings?.find(User.byId(user.user_id))
      if (setting) return setting
    }
    return null
  }

  export const getBalanceOfAccountType = (
    lease: Lease,
    account_type: OwnerPaymentAccountType = OwnerPaymentAccountType.OPERATIONAL,
  ) => {
    switch (account_type) {
      case OwnerPaymentAccountType.OPERATIONAL:
        return lease.balance_ops
      case OwnerPaymentAccountType.DEPOSIT:
        return lease.balance_deposit
      default:
        return lease.balance
    }
  }
  export const getFee = (lease: Lease) =>
    lease.unit ? Unit.getFee({ ...lease.unit, monthly_rent: lease.monthly_rent }) : 0

  export const getLift = (lease: Lease) =>
    lease.unit && lease.monthly_rent
      ? Math.max(lease.monthly_rent / Unit.getListPrice(lease.unit) - 1, 0)
      : 0

  export const getFullMontlyRent = (lease: Lease) => getFee(lease) + (lease.monthly_rent ?? 0)

  export const pickCosignerIds = (lease: Lease) => {
    return lease.lease_users ? pickAll('user_id', lease.lease_users) : []
  }
  export const pickGuarantorIds = (lease: Lease) => {
    return lease.lease_guarantors ? pickAll('guarantor_id', lease.lease_guarantors) : []
  }
  export const getCosigners = (lease: Lease): LeaseUser[] => {
    if (!lease.lease_users?.length) return []
    return lease.lease_users.map((item) => ({ ...item.user, user_id: item.user_id }))
  }
  export const getCosignersExcept = (lease: Lease, { user_id }: User.Id): LeaseUser[] => {
    return getCosigners(lease).filter(not(User.byId(user_id)))
  }
  export const getGuarantors = (lease: Lease): LeaseUser[] => {
    if (!lease.lease_guarantors?.length) return []
    return lease.lease_guarantors.map((item) => ({ ...item, user_id: item.guarantor_id }))
  }
  export const countCosignersGuarantors = (lease: Lease) =>
    (lease.lease_users?.length ?? 0) + (lease.lease_guarantors?.length ?? 0)

  export const isUserGuarantor = (lease: Lease, user: User.Id) =>
    !!lease.lease_guarantors?.some(({ guarantor_id }) => guarantor_id === user.user_id)

  export const findGuarantorById = (lease: Lease, user_id: string) =>
    lease.lease_guarantors?.find(({ guarantor_id }) => guarantor_id === user_id)

  export function getTenantsUserMap(
    tenants: Lease.Tenant[],
  ): Record<string, Lease.LeaseUser> | undefined {
    return tenants
      ? Object.fromEntries(tenants.map(({ user_id, user }) => [user_id, { ...user, user_id }]))
      : undefined
  }

  export const isValidPaymentMethod = (method: string): method is PaymentMethod =>
    [PaymentMethod.card, PaymentMethod.bank].includes(method as PaymentMethod)

  export const isMonthlyPaymentEnabled = (lease: Lease) => !!lease.unit?.enable_monthly_payments

  export const getContractFileName = (lease: Lease) => {
    const date = lease.start_at && parseDate(lease.start_at)
    const formattedDate = date
      ? formatUTCDateTimeAsLocal(date, { dateStyle: 'short' })
      : formatDateTime(new Date(), { dateStyle: 'short' })
    const name = lease.unit?.name ?? 'lease'
    return `${name} start ${formattedDate}.pdf`
  }

  export const isExternalSigning = (lease: Lease) => {
    const created = parseDate(lease.created_at)
    return created ? created.getTime() < Date.UTC(2024, 9 - 1, 26, 4) : false
  }
  export const Rello_Fee_Amount = 2.95
  export const calculateTotalAmount = (amount: number, RelloFee: number): number => {
    return calculateFeeAmount(amount, RelloFee) + amount
  }
  export const calculateFeeAmount = (amount: number, RelloFee: number): number => {
    return amount * 0.0275 + 0.3 + RelloFee
  }
}

export class LeaseBackend extends Client {
  list = async ({ filter, ...query }: Lease.Query = {}, config?: PostConfig): Promise<Lease[]> => {
    const { from, to } = filter?.checklist_completed_at ?? {}
    const checklist_completed_at =
      from || to
        ? {
            ...(from && { from: toServerDate(from) }),
            ...(to && { to: toServerDate(to) }),
          }
        : undefined

    const { leases } = await this.post<Lease.Query, { leases: Lease[]; status: string }>(
      '/lease/get',
      {
        ...query,
        filter: { ...filter, checklist_completed_at },
      },
      config,
    )
    return leases
  }

  listActive = async (query: Lease.Query = {}, config?: PostConfig): Promise<Lease[]> => {
    return this.list(query, config).then((leases) => leases.filter(Lease.isNotLocked))
  }

  listWithChecklist = async (
    query: Lease.Query = {},
    config?: PostConfig,
  ): Promise<Lease.WithChecklist[]> => {
    const leases = await this.list(query, config)
    const leasesWithChecklist: Lease.WithChecklist[] = await Promise.all(
      leases.map(async (lease) => ({
        ...lease,
        checklist: await leaseChecklist.listByLeaseId({ lid: lease.lease_id }, config),
      })),
    )
    return leasesWithChecklist
  }

  count = async (query: Lease.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Lease.Query, { count: number; status: string }>(
      '/lease/count',
      { filter: query.filter },
      config,
    )
    return count
  }

  byId = async (id: string, config?: PostConfig): Promise<Lease> => {
    const [lease] = await this.list({ filter: { lease_id: [id] } }, config)
    if (!lease) throw new Error(Lease.MSG.ERR.NOT_FOUND)
    return lease
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_signer_ready */
  readinessById = async (lid: string, config?: PostConfig): Promise<Lease.Readiness> => {
    const { status, ...readiness } = await this.get<
      Lease.Readiness & { status: string },
      { lid: string }
    >('/lease/signer/ready', { lid }, config)
    return readiness
  }

  listByUserId = async (user_id: string, config?: GetConfig): Promise<Lease[]> => {
    return await this.list(
      { filter: { user_id: [user_id] }, order: [{ name: 'start_at', desc: true }] },
      config,
    )
  }

  uploadPhoto = (data: FormData) => {
    return this.post('/user/photo/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 60000,
    })
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_contract_download2
   */
  downloadContractById = async (lease_id: string, config?: GetConfig): Promise<Blob> => {
    if (!lease_id) throw new Error(Lease.MSG.ERR.NO_ID)
    const blob = await this.get<Blob, { lid: string }>(
      '/lease/contract/download2',
      { lid: lease_id },
      {
        ...config,
        responseType: 'blob',
      },
    )
    if (blob.size === 0) throw new Error('Empty blob')
    return blob
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/ledger/get_ledger_invoice_get
   */
  getInvoice = async (
    q: { lid: string; atyp?: OwnerPaymentAccountType },
    config?: GetConfig,
  ): Promise<LedgerItem[]> => {
    type Params = {
      lid: string
      atyp: OwnerPaymentAccountType
    }
    const { ledger } = await this.get<{ ledger: LedgerItem[]; status: string }, Params>(
      '/ledger/invoice/get',
      { atyp: OwnerPaymentAccountType.OPERATIONAL, ...q },
      config,
    )
    return ledger
  }
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/payments/post_ledger_payments_checkout
   */
  checkout = async (q: Lease.Checkout, config?: GetConfig): Promise<string> => {
    type Req = {
      amount: number
      context: any
      lease_id: string
      payment_method: PaymentMethod
      title: string
      account_type: OwnerPaymentAccountType
    }
    const { url } = await this.post<Req, { status: string; transaction_id: string; url: string }>(
      '/ledger/payments/checkout',
      { account_type: OwnerPaymentAccountType.OPERATIONAL, ...q },
      config,
    )
    return url
  }
  checkoutFinix = async (data: Lease.FinixPaymentData, config?: PostConfig) => {
    const { transaction_id } = await this.post<
      Lease.FinixPaymentData,
      { status: string; transaction_id: string }
    >('/fnx/ledger/pay', data, config)
    return transaction_id
  }

  sign = async (data: Lease.Sign, config?: PostConfig) => {
    await this.post<Lease.Sign, { status: string }>('/lease/sign', data, config)
  }

  merchantId = async (
    query: { lid: string; acctyp?: OwnerPaymentAccountType },
    config?: GetConfig,
  ): Promise<{ merchant_id: string }> => {
    type Params = {
      lid: string
      atyp: OwnerPaymentAccountType
    }
    const response = await this.get<{ merchant_id: string }, Params>(
      '/fnx/owner/merchant',
      { atyp: OwnerPaymentAccountType.OPERATIONAL, ...query },
      config,
    )
    return response
  }
}

export const lease = new LeaseBackend()
