export function getFullName(
  user?: { first_name?: string; last_name?: string } | null,
): string | null {
  if (!user) return null
  return [user.first_name, user.last_name].filter(Boolean).join(' ') || null
}

export function getInvertedFullName(user?: { first_name?: string; last_name?: string } | null) {
  return user?.last_name || user?.first_name
    ? `${user.last_name ?? '-'}, ${user.first_name ?? '-'}`
    : null
}

export function getFullNameOrEmail(
  user?: { first_name?: string; last_name?: string; email: string } | null,
): string | null {
  return getFullName(user) || user?.email || null
}

export function getInitials(user?: { first_name?: string; last_name?: string }) {
  return (
    [user?.first_name?.charAt(0).toUpperCase(), user?.last_name?.charAt(0).toUpperCase()]
      .filter(Boolean)
      .join('') || undefined
  )
}
