import { UserSignature, userSignature as userSignatureApi } from 'api/user-signature'
import {
  useRef,
  useCallback,
  ComponentProps,
  useEffect,
  useState,
  FC,
  CSSProperties,
  useMemo,
} from 'react'
import { Form, TextField, SubmitButton, Select, useContainerZoom } from 'ui'
import { alertErrorMessage, alertSuccessMessage } from 'utils/toast'
import styles from './type.module.scss'
import { SIGNATURE_SIZE } from '../const'

interface Props {
  type: UserSignature.Type
  defaultValue?: string | null
  message?: string
  label?: string
  submitLabel?: string
  onSuccess?: (data: { snapshot_id: string; blob: Blob }) => void
}

export const SignatureType: FC<Props> = ({
  type,
  defaultValue,
  message = UserSignature.MSG.ACTION.UPLOAD_SUCCESS,
  label = type === UserSignature.Type.INITIALS ? 'Initials' : 'Full Legal Name',
  submitLabel = UserSignature.MSG.ACTION.UPLOAD,
  onSuccess,
}) => {
  const containerRef = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [fontConfig, setFontConfig] = useState<FontConfig>(CONFIGS[0])
  const [text, setText] = useState(defaultValue ?? undefined)

  const targetSize = SIGNATURE_SIZE[type]
  const zoom = useContainerZoom(containerRef, { pageWidth: targetSize.width })
  const padding = 20 * zoom

  // make sure font is installed
  useEffect(() => {
    if (fontConfig.loaded) return
    const { label, url } = fontConfig
    const font = new FontFace(label, `url(${url}) format('woff2')`)
    document.fonts.add(font)
    document.fonts.load(toFont(fontConfig)).then(() => {
      const config = CONFIGS.find((cfg) => cfg.url === url)
      if (!config) return
      config.loaded = true
      setFontConfig({ ...config })
    })
  }, [fontConfig])

  const imageSize = useMemo(() => {
    if (!text || !fontConfig?.loaded || !canvasRef.current) return { width: 0, height: 0, scale: 1 }
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    if (!ctx) return { width: 0, height: 0, scale: 1 }
    ctx.scale(1, 1)
    ctx.font = toFont(fontConfig, zoom)
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = 'black'
    const { width } = ctx.measureText(text)
    const widthWithPadding = width + padding * 2
    const heightWithPadding = fontConfig.size * zoom + padding * 2
    return { width: widthWithPadding, height: heightWithPadding }
  }, [fontConfig, padding, text, zoom])

  const handleChange: ComponentProps<typeof Form>['onChange'] = useCallback(
    (data: Partial<Data>) => {
      if (data.value) {
        const config = CONFIGS.find((cfg) => cfg.value === data.value) ?? CONFIGS[0]
        setFontConfig({ ...config })
      }
      setText(data.name ?? '')
    },
    [],
  )

  useEffect(() => {
    if (!text || !canvasRef.current || !fontConfig?.loaded) return
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Canvas 2D context not found')
    ctx.reset()
    ctx.scale(1, 1)
    const scale = imageSize.width > targetSize.width ? targetSize.width / imageSize.width : 1
    if (scale < 1) {
      ctx.scale(scale, scale)
    }
    ctx.font = toFont(fontConfig, zoom)
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = 'black'
    ctx.fillText(text, imageSize.width / scale / 2, imageSize.height / scale / 2)
  }, [imageSize, fontConfig, text, padding, zoom, targetSize.width])

  const upload = useCallback(async () => {
    try {
      const canvas = canvasRef.current
      if (!canvas) return
      if (!text) return
      const file = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => (blob ? resolve(blob) : reject()), 'image/png')
      })
      const { snapshot_id } = await userSignatureApi.upload({ file, type })
      alertSuccessMessage(message)
      onSuccess?.({ snapshot_id, blob: file })
    } catch (error) {
      alertErrorMessage(error as Error)
    }
  }, [message, onSuccess, text, type])

  return (
    <div className={styles.container} ref={containerRef}>
      <Form className={styles.form} onSubmit={upload} onChange={handleChange}>
        <fieldset className={styles.fieldset}>
          <TextField
            name="name"
            label={label}
            defaultValue={defaultValue}
            className={styles.name}
          />
          <Select
            name="value"
            label="Font Family"
            options={CONFIGS}
            defaultValue={CONFIGS[0].url}
            className={styles.value}
          />
        </fieldset>

        <div
          className={styles.bg}
          style={{ '--zoom': zoom } as CSSProperties}
          data-signature-type={type}
        >
          <canvas
            className={styles.canvas}
            ref={canvasRef}
            height={imageSize.height}
            width={imageSize.width}
          />
        </div>
        <footer>
          <SubmitButton>{submitLabel}</SubmitButton>
        </footer>
      </Form>
    </div>
  )
}
interface Data {
  name: string
  value: string
}

type FontConfig = { label: string; url: string; loaded: boolean; size: number; value: string }

const CONFIGS: FontConfig[] = [
  {
    label: 'Allison',
    size: 96,
    url: 'https://fonts.gstatic.com/s/allison/v11/X7nl4b88AP2nkbvZCCGa4ebjEgg.woff2',
  },
  {
    label: 'Dancing Script',
    size: 96,
    url: 'https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8hNX6plRP.woff2',
  },
  {
    label: 'Sacramento',
    size: 96,
    url: 'https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG4C0f_f5Iai0.woff2',
  },
  {
    label: 'Hurricane',
    size: 96,
    url: 'https://fonts.gstatic.com/s/hurricane/v7/pe0sMIuULZxTolZ5YldCBfe_Kdxicw.woff2',
  },
].map((cfg) => ({
  ...cfg,
  value: cfg.url,
  loaded: false,
}))

function toFont(cfg: FontConfig, zoom = 1) {
  return `${cfg.size * zoom}px "${cfg.label}"`
}
