import { Client, PostConfig } from 'client'
import { ListQuery } from 'utils/list'

export interface PlaidRequest {
  created_at: string
  data: any
  plaid_environment: string
  request: string
  request_id: string
  source: string
  user_id: string
  name: string
  email: string
}

export namespace PlaidRequest {
  export type Sort = 'request_id' | 'source' | 'created_at' | 'request' | 'name'
  export type Query = ListQuery<Sort, { source?: string; request?: string }>
}

export interface Address {
  data: {
    city: string
    region: string
    street: string
    country: string
    postal_code: string
  }
  primary: boolean
}

export class AdminPlaidBackend extends Client {
  getRequestslist = async (
    query: PlaidRequest.Query = {},
    config?: PostConfig,
  ): Promise<PlaidRequest[]> => {
    const { requests } = await this.post<
      PlaidRequest.Query,
      { requests: PlaidRequest[]; status: 'success' }
    >(`/plaid/raw/get`, query, config)
    return requests
  }
  getRequestById = async (id: string, config?: PostConfig): Promise<PlaidRequest> => {
    const [request] = await this.getRequestslist({ filter: { request_id: [id] } }, config)
    if (!request) throw new Error(`Request with id ${id} not found`)
    return request
  }

  count = async (query: PlaidRequest.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<PlaidRequest.Query, { count: number; status: 'success' }>(
      '/plaid/raw/count',
      query,
      config,
    )
    return count
  }
}

export const plaid = new AdminPlaidBackend()
