import { DeleteConfig, PostConfig, UPLOAD_TIMEOUT } from 'client'
import { not, some } from 'utils/compose'
import { ListPagination, Order, parseOrder, parsePagination } from 'utils/list'
import {
  UserDocument as _UserDocument,
  DocumentBackend as _UserDocumentBackend,
} from './user-document'
import { User } from '../user/user'

export interface UserDocument extends _UserDocument {
  content_combined?: object
  content_pages?: object
  generated_description?: string
  uploaded_by_id?: string
}

export namespace UserDocument {
  export type IdField = _UserDocument.IdField
  export type Id = _UserDocument.Id
  export type Sort = _UserDocument.Sort
  export type Query = _UserDocument.Query
  export type Filter = _UserDocument.Filter

  export enum Type {
    Asset = 'asset',
    Voucher = 'voucher',
    Chat = 'chat',
  }
  export const isType = _UserDocument.isType
  export const isProcessing = _UserDocument.isProcessing

  export const enum AiType {
    PAYSTUB = _UserDocument.AiType.PAYSTUB,
    BANK_STATEMENT = _UserDocument.AiType.BANK_STATEMENT,
    OTHER = _UserDocument.AiType.OTHER,
  }
  export const AI_TYPE = _UserDocument.AI_TYPE

  export const AiTypeOptions = [
    { value: AiType.PAYSTUB, label: AI_TYPE[AiType.PAYSTUB] },
    { value: AiType.BANK_STATEMENT, label: AI_TYPE[AiType.BANK_STATEMENT] },
    { value: AiType.OTHER, label: AI_TYPE[AiType.OTHER] },
  ]

  export const getAiType = _UserDocument.getAiType
  export const isPayStub = _UserDocument.isPayStub
  export const isBankStatement = _UserDocument.isBankStatement

  export type Update = Id &
    User.Id & {
      ai_type_override?: AiType
    }

  export const MSG = {
    LIST: 'Documents',
    LIST_EMPTY: 'No documents found',
    GROUP_PAYSTUB: 'Pay Stubs',
    GROUP_BANK: 'Bank Statement',
    GROUP_OTHER: 'Other',
    ACTION: {
      DELETE_SUBMIT: 'Delete',
      DELETE_SUCCESS: 'Document deleted.',
      DELETE: 'Delete Document',
      DOWNLOAD: 'Download Document',
      EDIT_SUCCESS: 'Document updated',
      EDIT: 'Edit Document',
      JSON_COMBINED: 'View Combined Content JSON',
      JSON_PAGES: 'View Content Pages JSON',
      UPLOAD_DONE: 'Document(s) has been uploaded',
      UPLOAD_SUBMIT: 'Upload',
      UPLOAD: 'Upload Documents',
      VIEW: 'View Document',
    },
    ERR: {
      NO_ID: 'Missing document_id',
      NOT_FOUND: 'Document not found.',
      NO_ACCESS: 'Permission denied.',
    },
  } as const

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Filter,
    _order?: Partial<ListPagination>,
    _pagination?: Partial<Query['pagination']>,
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Filter = {}
    const global = searchParams.get('global') as string
    if (global) filter.global = global
    const pagination = parsePagination(searchParams, _pagination)
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
      ..._order,
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export const categoriseByAiType = (docs: UserDocument[]) => {
    const stubs = [] as UserDocument[]
    const bank = [] as UserDocument[]
    const others = [] as UserDocument[]
    docs.forEach((doc) => {
      const category = isPayStub(doc) ? stubs : isBankStatement(doc) ? bank : others
      category.push(doc)
    })
    return [
      [MSG.GROUP_PAYSTUB, stubs] as const,
      [MSG.GROUP_BANK, bank] as const,
      [MSG.GROUP_OTHER, others] as const,
    ]
  }
}

export class UserDocumentBackend extends _UserDocumentBackend {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_user_document_update
   */
  update = async (
    { document_id, user_id, ...updates }: UserDocument.Update,
    config?: PostConfig,
  ): Promise<void> => {
    type Req = UserDocument.Id &
      User.Id & {
        updates: Pick<UserDocument, 'ai_type_override' & { ai_type_override: '' }>
      }
    await this.post<Req, { status: string }>(
      '/admin/user/document/update',
      {
        document_id,
        user_id,
        updates: { ...updates, ...(updates.ai_type_override === null && { ai_type_override: '' }) },
      },
      config,
    )
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/file/delete_admin_user_document_delete
   */
  removeByUser = async (
    { document_id, user_id }: UserDocument.Id & User.Id,
    config?: DeleteConfig,
  ): Promise<void> => {
    await this.delete('/admin/user/document/delete', {
      ...config,
      params: { docid: document_id, uid: user_id },
    })
  }

  remove = async (document_id: string, config?: DeleteConfig): Promise<void> => {
    throw new Error('Forbidden')
  }

  /**
   * @returns document_id
   * @see https://api-dev.rello.co/swagger/index.html#/file/post_admin_user_document_upload
   */
  upload = async (data: FormData, config?: PostConfig): Promise<string> => {
    const { document_id } = await this.post<FormData, { document_id: string; status: string }>(
      '/admin/user/document/upload',
      data,
      {
        timeout: UPLOAD_TIMEOUT,
        ...config,
        headers: {
          ...config?.headers,
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    return document_id
  }
  listByUserIdsForReport = async (
    user_id: string[],
    config?: PostConfig,
  ): Promise<UserDocument[]> => {
    const documents = await this.list(
      {
        filter: { user_id, type: [UserDocument.Type.Asset, UserDocument.Type.Voucher] },
      },
      config,
    )
    return [
      ...documents.filter(UserDocument.isPayStub),
      ...documents.filter(UserDocument.isBankStatement),
      ...documents.filter(not(some(UserDocument.isBankStatement, UserDocument.isPayStub))),
    ]
  }
}

export const document = new UserDocumentBackend()
