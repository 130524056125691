import { Client, PostConfig, convertToServerData } from 'client'
import { getTomorrowUTC, parseDate } from 'utils/date'
import { ListQuery } from 'utils/list'
import { ApplicationFeePayment } from './application-fee-payment'
import { Auction } from './auction'
import { Bid } from './bid'
import { Owner } from './owner'
import { QualificationScore } from './qualification-score'
import { Unit } from './unit'

export interface Application {
  /** agent comments */
  agent_comments?: string
  application_fee_payment?: ApplicationFeePayment[]
  application_id: string
  approved_at?: string
  bid: number
  canceled_at?: string
  canceled_by_id?: string
  /** applicant comments */
  comments?: string
  cosigner_id?: string
  created_at: string
  current_bid_id?: string
  current_bid?: Bid
  score?: number
  shared_accounts?: boolean
  submitted_at: string
  submitted_by_id: string
  term_end_at?: string
  term_start_at?: string
  unit: null | (Omit<Unit, 'auction'> & { auction_id?: string })
  user_id?: string
}

export namespace Application {
  export const singular = 'application'
  export const Singular = 'Application'
  export const plural = 'applications'
  export const Plural = 'Applications'

  export type IdField = 'application_id'
  export type Id = Pick<Application, IdField>
  export type Sort = IdField | 'created_at' | 'submitted_at' | 'approved_at'
  export type Query = ListQuery<
    Sort,
    {
      unit_id?: string[]
      user_id?: string[]
      cosigner_id?: string[]
      owner_id?: string[]
      /** matches user_id or one of cosigner_id */
      global_user_id?: string[]
      is_approved?: boolean
      is_canceled?: boolean
    }
  >
  export type Filter = Query['filter']

  export interface Data {
    bid?: number
    term_end_at?: string
    term_start_at?: string
    unit_id: string
    comments?: string | null
  }

  export const getTerms = (unit: Unit, application?: Application | null, auction?: Auction) => {
    const startVariants = [
      parseDate(application?.term_start_at),
      parseDate(unit.available_at),
      parseDate(auction?.lease_start_at),
      getTomorrowUTC(),
    ].filter(Boolean) as Date[]

    const startDate = new Date(Math.max(...startVariants.map((date) => date.getTime())))

    const endVariants = [
      parseDate(application?.term_end_at),
      parseDate(auction?.lease_end_at),
      Unit.getPreferredLeaseEndDate(unit, startDate),
    ].filter(Boolean) as Date[]

    const endDate = new Date(Math.min(...endVariants.map((date) => date.getTime())))

    return { start_at: startDate.toISOString(), end_at: endDate.toISOString() }
  }

  export const hasFee = (application?: Application | null) =>
    !application?.unit ? undefined : !!application.unit.application_fee_amount

  export const isFeePaid = (application?: Application | null) =>
    application?.application_fee_payment?.some(({ paid_at }) => !!paid_at)

  export const isAuctionBid = (application?: Application | null) =>
    !!(application?.current_bid_id || application?.current_bid)

  export const getBidRange = (applications: Application[]): [number, number] | [number] | [] => {
    if (!applications.length) return []
    const [min, max] = applications
      .filter((a) => (Application.hasFee(a) ? Application.isFeePaid(a) : true))
      .reduce(
        ([min, max], application) => [
          Math.min(application.bid, min),
          Math.max(application.bid, max),
        ],
        [Infinity, -Infinity],
      )
    return min === max ? [min] : [min, max]
  }

  export const shouldBypassQualification = ({
    scores,
    owner,
  }: {
    scores: QualificationScore
    owner: Owner
  }) => owner.allow_nonqualified_applications || scores.voucher
}

export class ApplicationBackend extends Client {
  submitMine = async (data: Application.Data, config?: PostConfig): Promise<string> => {
    const { reference } = await this.post<Application.Data, { reference: string; status: string }>(
      '/user/application/submit',
      convertToServerData(data, {
        number: ['bid'],
        date: ['term_start_at', 'term_end_at'],
      }),
      config,
    )
    return reference
  }

  updateMine = async (
    data: Application.Id & Application.Data,
    config?: PostConfig,
  ): Promise<void> => {
    await this.post(
      '/user/application/update',
      convertToServerData(data, {
        number: ['bid'],
        date: ['term_start_at', 'term_end_at'],
      }),
      config,
    )
  }

  getLatestApplication = async (
    query?: Application.Query,
    config?: PostConfig,
  ): Promise<Application | null> => {
    const [application] = await this.list(
      {
        ...query,
        filter: { is_canceled: false, ...query?.filter },
        pagination: { ...query?.pagination, page_size: 1, page: 1 },
        order: [{ name: 'created_at', desc: true }],
      },
      config,
    )
    return application ?? null
  }

  list = async (query: Application.Query = {}, config?: PostConfig): Promise<Application[]> => {
    const { applications } = await this.post<
      Application.Query,
      { applications: Application[]; status: string }
    >('/application/get', query ?? null, config)
    return applications
  }
}

export const application = new ApplicationBackend()
