import { UserSignature } from 'api/user-signature'
import { FC, ComponentProps } from 'react'
import { cn } from 'utils'
import styles from './signature-image.module.scss'

interface Props extends ComponentProps<'img'> {
  type: UserSignature.Type
}
export const SignatureImage: FC<Props> = ({ className, type, ...props }) => {
  return (
    <img
      {...props}
      className={cn(styles.img, className)}
      data-signature-type={type}
      alt={UserSignature.TYPE[type]}
    />
  )
}
