import { Client, PostConfig } from 'client'
import { ListQuery } from 'utils/list'
import { UserRole } from '../user/user'
import { AdminUser, adminUser } from '../user/user.admin'

export type Agent = AdminUser
// eslint-disable-next-line @typescript-eslint/no-redeclare
export namespace Agent {
  export type Sort =
    | 'user_id'
    | 'email'
    | 'last_activity_at'
    | 'first_name'
    | 'last_name'
    | 'created_at'
  export type Query = ListQuery<
    Sort,
    {
      user_id?: string[]
      unit_agent?: string[]
      owner?: string[]
      owner_user_id?: string[]
      owner_user_id_agents?: string[]
      role?: UserRole[]
    },
    AdminUser.Selector
  >
  export type Filter = Query['filter']

  export function getFilterFor(user: AdminUser): Filter {
    if (AdminUser.hasRoleOwner(user))
      return { owner_user_id_agents: [user.user_id], role: [UserRole.Agent] }
    if (AdminUser.hasRoleAdmin(user)) return { role: [UserRole.Agent] }
    return { user_id: [user.user_id] }
  }
}

class AgentBackend extends Client {
  list = async (
    { filter: { owner_user_id, ...filter } = {}, ...query }: Agent.Query = {},
    config?: PostConfig,
  ) => {
    return await adminUser.list(
      {
        ...query,
        filter: {
          ...filter,
          role: [UserRole.Agent],
        },
      },
      config,
    )
  }

  paginatedList = async function* (query: Agent.Query = {}, config?: PostConfig) {
    yield* adminUser.paginatedList(
      { ...query, filter: { ...query.filter, role: [UserRole.Agent] } },
      config,
    )
  }
}

export const agent = new AgentBackend()
