import { Client, GetConfig } from 'client'
import { every } from 'utils/compose'
import { Token } from './token.admin'
import { TemplateCustomToken } from '../admin'

export interface ApiToken {
  category: string
  data_type: Token.Type | ''
  description?: string
  label?: string
  name: string
  roles: Token.Role[]
}

export namespace ApiToken {
  export const mapByName = (tokens: ApiToken[]) =>
    Object.fromEntries(tokens.map((token) => [token.name, token]))

  export const mapToOptions = (apiTokenMap: Record<string, ApiToken>) =>
    Object.values(apiTokenMap).map((apiToken) => ({
      value: apiToken.name,
      label: apiToken.label ?? apiToken.name,
    }))

  export const byRole = (role: Token.Role) => (token: ApiToken) => token.roles.includes(role)

  export const getGroupByRole = (
    apiTokenMap: Record<string, ApiToken>,
    role: Token.Role = Token.Role.API,
  ) => {
    const categoryMap =
      Object.values(apiTokenMap)
        .filter(ApiToken.byRole(role))
        .reduce((categoryMap, apiToken) => {
          if (!categoryMap[apiToken.category]) {
            categoryMap[apiToken.category] = {
              name: apiToken.category,
              fields: [],
            } as TemplateCustomToken.Group
          }
          categoryMap[apiToken.category].fields.push({
            name: apiToken.name,
            type: Token.isType(apiToken.data_type) ? apiToken.data_type : Token.Type.TEXT,
            label: apiToken.label ?? apiToken.name,
            description: apiToken.description,
            config: {
              role,
              required: apiToken.name === Token.Type.SIGNATURE,
            },
          })
          return categoryMap
        }, {} as Record<string, TemplateCustomToken.Group>) ?? {}
    return Object.values(categoryMap)
  }

  export const canReference = (apiToken: ApiToken) => {
    // invalid type / name
    if (!Token.isType(apiToken.data_type) || !apiToken.name) return false
    // exclude it. Cause how?
    if (apiToken.data_type === Token.Type.SIGNATURE) return false
    // seems good
    return true
  }

  export const getScopeMap = (apiTokenMap: Record<string, ApiToken>, role = Token.Role.API) => {
    return Object.fromEntries(
      Object.values(apiTokenMap)
        .filter(every(ApiToken.byRole(role), ApiToken.canReference))
        .map((apiToken) => [apiToken.name, apiToken]),
    )
  }

  export const fromCustomToken = (customToken: TemplateCustomToken.Field): ApiToken | undefined =>
    customToken.name
      ? {
          category: TemplateCustomToken.roleToLabel(customToken.config.role),
          data_type: customToken.type,
          description: customToken.description,
          label: customToken.label,
          name: customToken.name,
          roles: [customToken.config.role],
        }
      : undefined
}

class ApiTokenBackend extends Client {
  list = async (config?: GetConfig) => {
    type Res = {
      tokens: ApiToken[]
      status: string
    }
    const { tokens } = await this.get<Res>('/lease/tokens/get', undefined, config)
    return tokens
  }
}

export const apiToken = new ApiTokenBackend()
